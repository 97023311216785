/* eslint-disable no-unused-vars */
import { ILoginValidation } from 'client/common/form-validation/login.validation'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    forgotPassword,
    postLogin,
    postResetPassword,
} from 'client/common/repository/user.repository'
import { useAppDispatch } from 'store'
import { setLoginData, userDataSelector } from './login.slice'
import { IForgotPassword } from 'client/common/form-validation/forgot-password.validation'
import { Toast } from '@components/toast/toast.component'
import { useSelector } from 'react-redux'
import { IUserAuth } from 'client/common/repository/interface/user-auth.interface'
import { getClient } from 'common/common.service'
import { IResetPassword } from 'client/common/form-validation/reset-password.validation'

export const useLogin = () => {
    const client: string = getClient()
    return {
        client,
    }
}

export const useLoginService = () => {
    // initial
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const user: IUserAuth = useSelector(userDataSelector)
    const client: string = getClient()
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    useEffect(() => {
        if (user.token) {
            navigate('/')
        }
    }, [])

    // add function (can more than one)
    const submitLogin = async (values: ILoginValidation) => {
        try {
            setLoading(true)
            const actionResult = await postLogin(values)

            setLoading(false)
            const response = actionResult
            if (!response.isSuccess) {
                setErrorMessage(response.message)
                return
            }

            const data = {
                ...response.data,
                OrganizationCode: values.OrganizationCode,
            }
            dispatch(setLoginData(data))

            navigate('/dashboard')
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // return function or variable
    return {
        submitLogin,
        loading,
        errorMessage,
        client,
        user,
    }
}

export const useForgotPassword = () => {
    // initial
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoginForm, setIsLoginForm] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [changeContent, setChangeContent] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        if (searchParams.toString()) {
            setChangeContent(true)
            setIsLoginForm(false)
        } else {
            setChangeContent(false)
            setIsLoginForm(true)
        }
    }, [location.search])

    // add function (can more than one)
    const submitForgotPassword = async (values: IForgotPassword) => {
        try {
            setLoading(true)
            await forgotPassword(values)

            Toast({
                header: 'Success',
                message: 'Reset Password link sent to your email',
                type: 'success',
            })
            setLoading(false)
        } catch (e) {
            const errorMessage =
                typeof e !== 'string'
                    ? 'Email not found or incorrect. Please enter a valid email.'
                    : e
            setLoading(false)
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    // add function (can more than one)
    const submitConfirmPassword = async (values: IResetPassword) => {
        try {
            setLoading(true)
            await postResetPassword(values)

            Toast({
                header: 'Success',
                message: `Please check your email for the reset password confirmation. If you haven’t receive the email, please try again or contact our support team for help via ${email}`,
                type: 'success',
            })
            navigate('/login')
            setLoading(false)
        } catch (e) {
            const errorMessage =
                typeof e !== 'string'
                    ? `Please try to reset your password again. If the error still occurs, please contact our support team for help via ${email}`
                    : e
            setLoading(false)
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    // return function or variable
    return {
        submitForgotPassword,
        submitConfirmPassword,
        setEmail,
        loading,
        changeContent,
        errorMessage,
        isLoginForm,
        location,
        setIsLoginForm,
        setLoading,
        setErrorMessage,
    }
}
