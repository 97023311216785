import { DateRange } from 'react-day-picker'

// interface for validation
export interface IShipmentFilter {
    eta: DateRange
    etd: DateRange
    transportMode: string[]
    consignor: string[]
    consignee: string[]
    destination: string[]
    origin: string[]
}

// form login initial data
export const shipmentFilterInitialValue: IShipmentFilter = {
    eta: {
        from: undefined,
        to: undefined,
    },
    etd: {
        from: undefined,
        to: undefined,
    },
    transportMode: [],
    consignor: [],
    consignee: [],
    destination: [],
    origin: [],
}
