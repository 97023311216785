var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import FormInput from '@components/form-input/form-input.component';
import Tooltip from '@components/tooltip/tooltip.component';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
var Accordion = function (_a) {
    var items = _a.items, header = _a.header, onSearchSubmit = _a.onSearchSubmit;
    var t = useTranslation().t;
    var _b = useState(null), openIndex = _b[0], setOpenIndex = _b[1];
    var _c = useState(''), search = _c[0], setSearch = _c[1];
    var heightContent = onSearchSubmit
        ? 'h-[calc(100vh-20rem)] mobile:h-auto '
        : 'h-full';
    var toggleAccordion = function (index) {
        setOpenIndex(openIndex === index ? null : index);
    };
    var formik = useFormik({
        initialValues: { searchTerm: '' },
        onSubmit: function (values) {
            onSearchSubmit && onSearchSubmit(values.searchTerm);
            setSearch(values.searchTerm);
        },
        validate: function (values) {
            var errors = {};
            if (values.searchTerm.length < 3 &&
                values.searchTerm.length !== 0) {
                errors.searchTerm = 'Search term must be at least 3 characters';
            }
            return errors;
        },
    });
    return (_jsxs("div", __assign({ className: "".concat(heightContent) }, { children: [onSearchSubmit && (_jsx(FormikProvider, __assign({ value: formik }, { children: _jsx("form", __assign({ className: "w-full flex justify-end mb-3", onSubmit: function (e) {
                        e.preventDefault();
                        formik.handleSubmit();
                    } }, { children: _jsx(Tooltip, __assign({ text: 'Search term must be at least 3 characters', isShow: formik.errors.searchTerm ? true : false }, { children: _jsx(FormInput, { autoComplete: "auto-off", hideError: true, placeholder: t('action.search', {
                                value: t('additional.items'),
                            }), parentDivClassName: "w-full  mobile:min-w-0 mobile:w-full !mb-0", icon: "ri-search-line", name: "searchTerm", onKeyDown: function (event) {
                                if (event.key === 'Backspace' &&
                                    formik.values.searchTerm.length === 1) {
                                    formik.values.searchTerm = '';
                                    formik.handleSubmit();
                                }
                            } }) })) })) }))), _jsxs("div", __assign({ className: "border rounded h-full flex flex-col" }, { children: [_jsx("div", __assign({ className: " font-bold themes-text-gray-v5 p-3 border-b h-12" }, { children: header })), _jsx("div", __assign({ className: "overflow-auto" }, { children: items.map(function (item, index) {
                            var iconAccordion = openIndex === index
                                ? 'ri-arrow-down-s-line'
                                : 'ri-arrow-up-s-line';
                            return (_jsxs("div", __assign({ className: "border-b" }, { children: [!search && (_jsxs("button", __assign({ className: "flex items-center w-full text-left p-3 gap-3 focus:outline-none h-12", onClick: function () { return toggleAccordion(index); } }, { children: [_jsx("i", { className: "".concat(iconAccordion, " text-size-XL") }), item.title] }))), openIndex === index && !search &&
                                        item.content.map(function (subitem) { return (_jsx("div", __assign({ className: "border-t py-3 px-12" }, { children: subitem.subContent }))); }), search &&
                                        item.content.map(function (subitem) { return (_jsx("div", __assign({ className: "border-t p-3" }, { children: subitem.subContent }))); })] }), index));
                        }) }))] }))] })));
};
export default Accordion;
