import {
    IDataOverviewTable1,
    ISmallTableData,
    IUseControlTower,
} from '../control-tower.interface'
import {
    tabItemFilterOverview,
    tableOverviewHeaders1,
    tableOverviewHeaders2,
} from '../control-tower.static'
import Tab from '@components/tab/tab.component'
import Pagination from '@components/pagination/pagination.component'
import SplitTable from '@components/split-table/split-table.component'

const Overview = ({ service }: { service: IUseControlTower }) => {
    return (
        <>
            <div
                className="border-r col-span-12 xl:col-span-4"
                style={{ height: 'inherit' }}
            >
                <div className="p-2 h-10 font-bold text-size-S">
                    Related Key Figures
                </div>
                <div className="border-t h-[500px] xl:h-[calc(100vh-17rem)]">
                    <SplitTable<IDataOverviewTable1>
                        columns={tableOverviewHeaders1}
                        data={service.dataOverviewTable1}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        loading={service.loadingOverview.relatedKeyFigures}
                    />
                </div>
            </div>
            <div
                className="col-span-4 border-r md:col-span-12 lg:col-span-6 xl:col-span-4"
                style={{ height: 'inherit' }}
            >
                <div className="flex justify-between items-center px-2 h-[40px]">
                    <Tab
                        items={tabItemFilterOverview}
                        tabFilter={service.tabFilterOverview}
                        onChange={(item) => {
                            service.setTabFilterOverview(item)
                        }}
                        containerClassName="!overflow-y-hidden !text-size-XS !p-0 !border-none"
                        containerItemClass="!min-h-[40px] h-[40px]"
                    />
                    <Pagination
                        containerClassName="!p-0 flex items-center h-full"
                        className="!h-[27px]"
                        btnClassName="!h-[27px] flex items-center justify-center cursor-pointer"
                        meta={service.dataOverviewTable2.meta}
                        previousHandling={(page) => {
                            service.handlingPageNumberOverviewTable2(page)
                        }}
                        nextHandling={(page) => {
                            service.handlingPageNumberOverviewTable2(page)
                        }}
                    />
                </div>

                <div className="border-t md:col-span-12 h-[500px] xl:h-[calc(100vh-17rem)]">
                    <SplitTable<ISmallTableData>
                        columns={tableOverviewHeaders2}
                        data={service.dataOverviewTable2.data}
                        thClass="text-size-XS"
                        tdClass="text-size-XS"
                        onRowClick={(data) => {
                            service.handleHistoryFilter(data.name)
                        }}
                        loading={service.loadingOverview.overviewTable2}
                    />
                </div>
            </div>
        </>
    )
}

export default Overview
