import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ShipmentDataDummy } from './shipments.dummy'
import {
    IShipmentOrder,
    IShipmentRelatedInvoice,
    IShipmentsSummaryInitialValueSlice,
} from './shipments.interface'
import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { IConfigureColumns } from '@components/configure-columns-modal/configure-column-modal.interface'
import {
    IShipmentPackLine,
    IRShipment,
    IRShipmentFilter,
} from 'client/ifb/repository/interface/shipment.interface'
import { initialMeta } from 'common/common.static'
import { formatDate, formatDecimalNumber } from '@services/common.service'
import { IGenerateReportHeaders } from 'client/common/components/generate-report-modal.component'

export const ShipmentStatusHeader: ITabItem[] = [
    {
        label: 'All Shipments',
        totalData: ShipmentDataDummy.length,
        labelTranslationCode: 'status.all_shipments',
        key: 'status',
        value: 'allShipments',
        className: 'brand',
    },
    {
        label: 'Arrived',
        totalData: ShipmentDataDummy.filter((x: any) => x.status === 'arrived')
            .length,
        labelTranslationCode: 'status.arrived',
        key: 'status',
        value: 'arrived',
        className: 'green',
    },
    {
        label: 'In Transit',
        labelTranslationCode: 'status.in_transit',
        totalData: ShipmentDataDummy.filter(
            (x: any) => x.status === 'inTransit',
        ).length,
        key: 'status',
        value: 'inTransit',
        className: 'yellow',
    },
    {
        label: 'Delayed',
        labelTranslationCode: 'status.delayed',
        totalData: ShipmentDataDummy.filter((x: any) => x.status === 'delayed')
            .length,
        key: 'status',
        value: 'delayed',
        className: 'red',
    },
    {
        label: 'No Status',
        labelTranslationCode: 'status.no_status',
        totalData: ShipmentDataDummy.filter((x: any) => x.status === 'noStatus')
            .length,
        key: 'status',
        value: 'noStatus',
        className: 'gray',
    },
]

export const ShipmentConfigureColumns: IConfigureColumns<IRShipment>[] = [
    {
        title: 'Flags',
        columns: [
            {
                statusColumn: 'selectedColumns',
                label: '',
                accessor: 'transportModeCode',
                tdClassName: '!pt-0 !pb-0',
                sort: false,
                className: '!h-auto',
                customBuild: (data) => {
                    let iconTransportMode: string
                    switch (data) {
                        case 'airplane':
                            iconTransportMode = 'ri-plane-line'
                            break
                        case 'SEA':
                            iconTransportMode = 'ri-ship-line'
                            break
                        case 'truck':
                            iconTransportMode = 'ri-truck-line'
                            break
                        default:
                            iconTransportMode = 'ri-plane-line'
                    }

                    return (
                        <i className={`${iconTransportMode} text-size-XL`}></i>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Shipment No.',
                // labelTranslation: 'shipments.shipment_id',
                accessor: 'shipmentID',
            },
            // {
            //     statusColumn: 'selectedColumns',
            //     label: 'Shipment No.',
            //     accessor: 'shipmentID',
            //     customBuild: (data, row) => {
            //         let statusClass: string
            //         switch (row?.textStatus) {
            //             case 'arrived':
            //                 statusClass = 'themes-bg-green-v3 themes-text-white'
            //                 break
            //             case 'inTransit':
            //                 statusClass =
            //                     'themes-bg-yellow-v3 themes-text-white'
            //                 break
            //             case 'delayed':
            //                 statusClass = 'themes-bg-red-v3 themes-text-white'
            //                 break
            //             case 'noStatus':
            //                 statusClass = 'themes-bg-gray-v3'
            //                 break
            //             default:
            //                 statusClass = 'themes-bg-brand-v4 themes-text-white'
            //         }
            //         return (
            //             <div
            //                 className={`${statusClass}  px-2 rounded flex justify-center`}
            //             >
            //                 {data}
            //             </div>
            //         )
            //     },
            // },
            {
                statusColumn: 'selectedColumns',
                label: 'Consignor',
                accessor: 'consignorName',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Consignee',
                accessor: 'textConsignee',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Origin',
                accessor: 'originPortCode',
            },
            {
                statusColumn: 'selectedColumns',
                label: 'ETD',
                accessor: 'datesETDShipment',
                customBuild: (data) => {
                    return <>{formatDate(data, 'DD-MMM-YYYY') || '-'}</>
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Destination',
                // labelTranslation: 'shipments.destination_port',
                accessor: 'destinationPort',
                customBuild: (data, rowData) => {
                    return (
                        <>
                            {!rowData?.destinationPortCode &&
                            !rowData?.destinationPortCode
                                ? '-'
                                : `${rowData?.destinationPortCode} - ${rowData?.destinationPortName}`}
                        </>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'ETA',
                accessor: 'datesETAShipment',
                customBuild: (data) => {
                    return <>{formatDate(data, 'DD-MMM-YYYY') || '-'}</>
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Packs',
                accessor: 'numericTotalNumberOfOuterPacks',
                customBuild: (_data, rowData) => {
                    return (
                        <div className="flex items-center">
                            {rowData?.numericTotalNumberOfOuterPacks}{' '}
                            {rowData?.textOuterPacksUnitCode}
                        </div>
                    )
                },
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Volume',
                // labelTranslation: 'shipments.volume_measure',
                accessor: 'volumeMeasure',
                customBuild: (_data, rowData) => (
                    <>
                        {formatDecimalNumber(rowData?.volumeMeasure)}{' '}
                        {rowData?.volumeUnitCode}
                    </>
                ),
            },
            {
                statusColumn: 'selectedColumns',
                label: 'Total Weight',
                // labelTranslation: 'shipments.weight_measure',
                accessor: 'weightMeasure',
                customBuild: (_data, rowData) => (
                    <>
                        {formatDecimalNumber(rowData?.weightMeasure)}{' '}
                        {rowData?.weightUnitCode}
                    </>
                ),
            },
        ],
    },
]

export const ShipmentSummaryHeader: ITableColumn[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
    },
    {
        label: 'Goods Description',
        accessor: 'goodsDescription',
    },
    {
        label: 'Shipper Ref',
        accessor: 'shipperReference',
    },
    {
        label: 'Origin Port',
        accessor: 'originPort',
    },
    {
        label: 'Destination Port',
        accessor: 'destinationPort',
    },
    {
        label: 'Packs',
        accessor: 'packs',
    },
    {
        label: 'Weight Measure',
        accessor: 'weightMeasure',
    },
    {
        label: 'Volume Measure',
        accessor: 'volumeMeasure',
    },
    {
        label: 'Master Bill',
        accessor: 'masterBill',
    },
    {
        label: 'Load Port (Arrival Consol)',
        accessor: 'loadPort',
    },
]

export const eDocsTypeOptions = [
    {
        label: 'Agents Instruction',
        value: 'agentsInstruction',
    },
    {
        label: 'Airway Bill/Ocean Bill of Lading',
        value: 'airwayBill',
    },
    {
        label: 'Arrival Notice',
        value: 'arrivalNotice',
    },
    {
        label: 'Arrival Notice and Charge Sheet',
        value: 'arrivalNoticeAndChargeSheet',
    },
    {
        label: 'Beneficiary Certificate',
        value: 'beneficiaryCertificate',
    },
    {
        label: 'Bill Of Entry',
        value: 'billOfEntry',
    },
    {
        label: 'Booking Cartage Advice',
        value: 'bookingCartageAdvice',
    },
    {
        label: 'Booking Confirmation',
        value: 'bookingConfirmation',
    },
    {
        label: 'Cartage Advice With Receipt',
        value: 'cartageAdviceWithReceipt',
    },
    {
        label: 'Certificate of Origin',
        value: 'certificateOfOrigin',
    },
    {
        label: 'Commercial Invoice',
        value: 'commercialInvoice',
    },
    {
        label: 'Container Release',
        value: 'containerRelease',
    },
    {
        label: 'Container Summary Sheet',
        value: 'containerSummarySheet',
    },
    {
        label: 'Cotton Certificate',
        value: 'cottonCertificate',
    },
    {
        label: 'Customs Clearance Documents',
        value: 'customsClearanceDocuments',
    },
    {
        label: 'Customs Status Advice',
        value: 'customsStatusAdvice',
    },
    {
        label: 'Delivery Labels',
        value: 'deliveryLabels',
    },
    {
        label: 'Documents Available Notice',
        value: 'documentsAvailableNotice',
    },
    {
        label: 'Draft Bill of Lading',
        value: 'draftBillOfLading',
    },
    {
        label: 'Entry Print/ Customs Declaration Documents',
        value: 'entryPrint',
    },
    {
        label: 'Export Cartage Advice',
        value: 'exportCartageAdvice',
    },
    {
        label: 'Export Cartage Advice with Receipt',
        value: 'exportCartageAdviceWithReceipt',
    },
    {
        label: 'Export Coload Master Manifest',
        value: 'exportColoadMasterManifest',
    },
    {
        label: 'Exporter Documents',
        value: 'exporterDocuments',
    },
    {
        label: 'Food Control Certificate',
        value: 'foodControlCertificate',
    },
    {
        label: 'Forwarders Cargo Receipt',
        value: 'forwardersCargoReceipt',
    },
    {
        label: 'Fumigation Certificate',
        value: 'fumigationCertificate',
    },
    {
        label: 'Gate Pass',
        value: 'gatePass',
    },
    {
        label: 'Health Clearance Certificate',
        value: 'healthClearanceCertificate',
    },
    {
        label: 'House Bill Of Lading - Signed',
        value: 'houseBillOfLading',
    },
    {
        label: 'House Waybill/ Bill of Lading',
        value: 'houseWaybill',
    },
    {
        label: 'Identification Check',
        value: 'identificationCheck',
    },
    {
        label: 'Image',
        value: 'image',
    },
    {
        label: 'Image - Damage',
        value: 'imageDamage',
    },
    {
        label: 'Import Cartage Advice',
        value: 'importCartageAdvice',
    },
    {
        label: 'Import Cartage Advice with Receipt',
        value: 'importCartageAdviceWithReceipt',
    },
    {
        label: 'Import Coload Master Manifest',
        value: 'importColoadMasterManifest',
    },
    {
        label: 'Insurance Certificate',
        value: 'insuranceCertificate',
    },
    {
        label: 'Internally Created Public Document',
        value: 'internallyCreatedPublicDocument',
    },
    {
        label: 'Letter of Credit',
        value: 'letterOfCredit',
    },
    {
        label: 'Load List',
        value: 'loadList',
    },
    {
        label: "Manufacturer's Declaration",
        value: 'manufacturersDeclaration',
    },
    {
        label: 'Master House',
        value: 'masterHouse',
    },
    {
        label: 'Miscellaneous CFS Document',
        value: 'miscellaneousCfsDocument',
    },
    {
        label: 'Miscellaneous Custom Document',
        value: 'miscellaneousCustomDocument',
    },
    {
        label: 'Miscellaneous Freight Document',
        value: 'miscellaneousFreightDocument',
    },
    {
        label: 'Miscellaneous Order Management Document',
        value: 'miscellaneousOrderManagementDocument',
    },
    {
        label: 'Miscellaneous Sales Manager Document',
        value: 'miscellaneousSalesManagerDocument',
    },
    {
        label: 'Miscellaneous Shipping Manager Document',
        value: 'miscellaneousShippingManagerDocument',
    },
    {
        label: 'Motor Vehicle Certificate',
        value: 'motorVehicleCertificate',
    },
    {
        label: 'Multiple Copies',
        value: 'multipleCopies',
    },
    {
        label: 'NAFTA Certificate',
        value: 'naftaCertificate',
    },
    {
        label: 'Original Bill Of Lading',
        value: 'originalBillOfLading',
    },
    {
        label: 'Outturn Report',
        value: 'outturnReport',
    },
    {
        label: 'Packing Declaration',
        value: 'packingDeclaration',
    },
    {
        label: 'Packing List',
        value: 'packingList',
    },
    {
        label: 'Permit',
        value: 'permit',
    },
    {
        label: 'Power of Attorney',
        value: 'powerOfAttorney',
    },
    {
        label: 'Power of Attorney Customs',
        value: 'powerOfAttorneyCustoms',
    },
    {
        label: 'Power of Attorney Forwarding',
        value: 'powerOfAttorneyForwarding',
    },
    {
        label: 'Profit Share Calculation Worksheet',
        value: 'profitShareCalculationWorksheet',
    },
    {
        label: 'Proof of Delivery',
        value: 'proofOfDelivery',
    },
    {
        label: 'Proof of Pickup',
        value: 'proofOfPickup',
    },
    {
        label: 'Quarantine Application/Coversheet',
        value: 'quarantineApplicationCoversheet',
    },
    {
        label: 'Quarantine Certificate',
        value: 'quarantineCertificate',
    },
    {
        label: 'Quarantine Packing Declaration',
        value: 'quarantinePackingDeclaration',
    },
    {
        label: 'Quarantine Print Preview',
        value: 'quarantinePrintPreview',
    },
    {
        label: 'Quarantine Remote Print',
        value: 'quarantineRemotePrint',
    },
    {
        label: 'Release/ Removal Authority',
        value: 'releaseRemovalAuthority',
    },
    {
        label: 'Scheduled Report',
        value: 'scheduledReport',
    },
    {
        label: 'Shippers Departure Notice',
        value: 'shippersDepartureNotice',
    },
    {
        label: 'Shippers Letter of Instruction',
        value: 'shippersLetterOfInstruction',
    },
    {
        label: 'Shipping Advice',
        value: 'shippingAdvice',
    },
    {
        label: 'Time Slot Confirmation',
        value: 'timeSlotConfirmation',
    },
    {
        label: 'Time Slot Request',
        value: 'timeSlotRequest',
    },
    {
        label: 'Transit Declaration of Commitment',
        value: 'transitDeclarationOfCommitment',
    },
    {
        label: 'Veterinary Certificate',
        value: 'veterinaryCertificate',
    },
    {
        label: 'Warehouse Bill of Lading',
        value: 'warehouseBillOfLading',
    },
    {
        label: 'Warehouse Cartage Advice',
        value: 'warehouseCartageAdvice',
    },
    {
        label: 'Warehouse Pick Order Summary',
        value: 'warehousePickOrderSummary',
    },
    {
        label: 'Withholding Certificate',
        value: 'withholdingCertificate',
    },
    {
        label: 'Others',
        value: 'others',
    },
]

export const shipmentPackLineHeaders: ISTColumn<IShipmentPackLine>[] = [
    {
        label: 'Pieces',
        accessor: 'packedQuantity',
    },
    {
        label: 'Pack Type',
        accessor: 'packTypeDesc',
    },
    {
        label: 'Length',
        accessor: 'length',
        customBuild: (data, x, y, z, row) => {
            return (
                <div className="flex items-center">
                    <span>{String(data)}</span>
                    <span className="ml-1">{row?.udCode}</span>
                </div>
            )
        },
    },
    {
        label: 'Height',
        accessor: 'height',
        customBuild: (data, x, y, z, row) => {
            return (
                <div className="flex items-center">
                    <span>{formatDecimalNumber(Number(data))}</span>
                    <span className="ml-1">{row?.udCode}</span>
                </div>
            )
        },
    },
    {
        label: 'Width',
        accessor: 'width',
        customBuild: (data, x, y, z, row) => {
            return (
                <div className="flex items-center">
                    <span>{formatDecimalNumber(Number(data))}</span>
                    <span className="ml-1">{row?.udCode}</span>
                </div>
            )
        },
    },
    {
        label: 'Total Weight',
        accessor: 'weight',
        customBuild: (data, x, y, z, row) => {
            return (
                <div className="flex items-center">
                    <span>{formatDecimalNumber(Number(data))}</span>
                    <span className="ml-1">{row?.uqWeightCode}</span>
                </div>
            )
        },
    },
    {
        label: 'Total Volume',
        accessor: 'volume',
        customBuild: (data, x, y, z, row) => {
            return (
                <div className="flex items-center">
                    <span>{formatDecimalNumber(Number(data))}</span>
                    <span className="ml-1">{row?.uqVolumeCode}</span>
                </div>
            )
        },
    },
    {
        label: 'Description',
        accessor: 'description',
    },
    {
        label: 'Marks & Numbers',
        accessor: 'marksAndNumbers',
    },
    {
        label: 'Line Price',
        accessor: 'linePrice',
        customBuild: (data) => {
            return <>{formatDecimalNumber(Number(data))}</>
        },
    },
    {
        label: 'Tariff Number',
        accessor: 'tariffNo',
        customBuild: (data) => {
            return <>{formatDecimalNumber(Number(data))}</>
        },
    },
]

export const shipmentOrderHeaders: ISTColumn<IShipmentOrder>[] = [
    {
        label: 'Order No.',
        labelTranslation: 'shipments.order_no',
        accessor: 'orderNo',
    },
    {
        label: 'Order Date',
        labelTranslation: 'shipments.order_date',
        accessor: 'orderDate',
    },
    {
        label: 'Booking Conf. Ref',
        labelTranslation: 'shipments.booking_conf_ref',
        accessor: 'bookingConfRef',
    },
    {
        label: 'Booking Conf. Date',
        labelTranslation: 'shipments.booking_conf_date',
        accessor: 'bookingConfDate',
    },
    {
        label: 'Order Status',
        labelTranslation: 'shipments.order_status',
        accessor: 'orderStatus',
    },
    {
        label: 'Ex Works Required By',
        labelTranslation: 'shipments.ex_works_required_by',
        accessor: 'exWorksRequiredBy',
    },
    {
        label: 'Required in Store',
        labelTranslation: 'shipments.required_in_store',
        accessor: 'requiredInStore',
    },
    {
        label: 'Transport Mode',
        labelTranslation: 'shipments.transport_mode',
        accessor: 'transportMode',
    },
    {
        label: 'Container Mode',
        labelTranslation: 'shipments.container_mode',
        accessor: 'containerMode',
    },
    {
        label: 'Goods Description',
        labelTranslation: 'shipments.goods_description',
        accessor: 'goodsDescription',
    },
]

export const shipmentRelatedInvoiceHeaders: ISTColumn<IShipmentRelatedInvoice>[] =
    [
        {
            label: 'Transaction Type',
            labelTranslation: 'shipments.transaction_type',
            accessor: 'transactionType',
        },
        {
            label: 'Terms',
            labelTranslation: 'shipments.terms',
            accessor: 'terms',
        },
        {
            label: 'Invoice Date',
            labelTranslation: 'shipments.invoice_date',
            accessor: 'invoiceDate',
        },
        {
            label: 'Due Date',
            labelTranslation: 'shipments.due_date',
            accessor: 'dueDate',
        },
        {
            label: 'Fully Paid Date',
            labelTranslation: 'shipments.fully_paid_date',
            accessor: 'fullyPaidDate',
        },
        {
            label: 'Canceled',
            labelTranslation: 'shipments.canceled',
            accessor: 'canceled',
        },
        {
            label: 'Ledger',
            labelTranslation: 'shipments.ledger',
            accessor: 'ledger',
        },
    ]

export const shipmentsSummaryInitialValueSlice: IShipmentsSummaryInitialValueSlice =
    {
        data: [],
        generateReportData: [],
        meta: initialMeta,
        filter: {
            status: '',
            inProgressStatus: '',
            pageNumber: 1,
            pageSize: 50,
        },
        statusTotal: {
            all: 0,
            departed: 0,
            boarded: 0,
            cleared: 0,
            others: 0,
            arrived: 0,
        },
        statusSelected: {
            value: 'all',
            key: 'status',
            label: 'All Shipment',
            totalData: 0,
        },
    }
export const shipmentFilterValues: IRShipmentFilter = {
    ETDStart: '',
    ETDEnd: '',
    ETAStart: '',
    ETAEnd: '',
    // TransportModeCode: '',
    Consignee: [],
    Consignor: [],
    Origin: [],
    Destination: [],
}

export const generateReportHeaders: IGenerateReportHeaders<IRShipment>[] = [
    { label: 'Transport Mode', accessor: 'transportModeCode', widthCol: 15 },
    { label: 'Shipment No.', accessor: 'shipmentID', widthCol: 17 },
    { label: 'Consignor', accessor: 'consignorName', widthCol: 25 },
    { label: 'Consignee', accessor: 'textConsignee', widthCol: 39 },
    { label: 'Origin', accessor: 'originPortCode', widthCol: 12 },
    {
        label: 'ETD',
        accessor: 'datesETDShipment',
        widthCol: 16,
        customValue: (rowData) =>
            formatDate(rowData.datesETDShipment, 'DD-MMM-YYYY') || '-',
    },
    {
        label: 'Destination',
        accessor: 'shipmentID',
        customValue: (rowData) =>
            !rowData?.destinationPortCode && !rowData?.destinationPortCode
                ? '-'
                : `${rowData?.destinationPortCode} - ${rowData?.destinationPortName}`,
        widthCol: 19,
    },
    {
        label: 'ETA',
        accessor: 'shipmentID',
        widthCol: 15,
        customValue: (rowData) =>
            formatDate(rowData.datesETAShipment, 'DD-MMM-YYYY') || '-',
    },
    {
        label: 'Packs',
        accessor: 'shipmentID',
        widthCol: 8,
        customValue: (rowData) =>
            `${rowData?.numericTotalNumberOfOuterPacks} ${rowData?.textOuterPacksUnitCode}`,
    },
    {
        label: 'Total Volume',
        accessor: 'shipmentID',
        widthCol: 12,
        customValue: (rowData) =>
            `${formatDecimalNumber(rowData?.volumeMeasure)} ${rowData?.volumeUnitCode}`,
    },
    {
        label: 'Total Weight',
        accessor: 'shipmentID',
        widthCol: 12,
        customValue: (rowData) =>
            `${formatDecimalNumber(rowData?.weightMeasure)} ${rowData?.weightUnitCode}`,
    },
    { label: "Shipper's Ref", accessor: 'shipperRef', widthCol: 12 },
    { label: 'Order Reference', accessor: 'textOrderReferences', widthCol: 15 },
]
