var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import './pills.style.css';
var Pills = function (_a) {
    var items = _a.items, onChange = _a.onChange, _b = _a.isRounded, isRounded = _b === void 0 ? true : _b, _c = _a.variant, variant = _c === void 0 ? 'brand-white' : _c, _d = _a.containerClass, containerClass = _d === void 0 ? '' : _d, _e = _a.itemClass, itemClass = _e === void 0 ? '' : _e, props = __rest(_a, ["items", "onChange", "isRounded", "variant", "containerClass", "itemClass"]);
    var _f = useState(0), activeIndex = _f[0], setActiveIndex = _f[1];
    var _g = useState((props === null || props === void 0 ? void 0 : props.useToggle)
        ? items.map(function (item, idx) { return (__assign(__assign({}, item), { isActive: idx === 0 ? true : false })); })
        : items), selectedItems = _g[0], setSelectedItems = _g[1];
    var roundedClass = isRounded ? 'rounded-full' : 'rounded';
    var disabledClass = props.disabled ? '' : 'cursor-pointer';
    var handleToggle = function (idx) {
        var updatedItems = selectedItems.map(function (item, index) {
            return index === idx ? __assign(__assign({}, item), { isActive: !item.isActive }) : item;
        });
        setSelectedItems(updatedItems);
        onChange(updatedItems[idx]);
    };
    return (_jsx("div", __assign({ className: "pills ".concat(disabledClass, " ").concat(containerClass), style: { scrollbarWidth: 'none' } }, { children: selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.map(function (x, idx) {
            var isActive = (props === null || props === void 0 ? void 0 : props.useToggle)
                ? (x === null || x === void 0 ? void 0 : x.isActive)
                    ? "".concat(variant, "-active")
                    : variant
                : activeIndex === idx
                    ? "".concat(variant, "-active")
                    : variant;
            var disabledActiveClass = props.disabled
                ? "".concat(variant, "-disabled")
                : isActive;
            return (_jsx("div", __assign({ className: "pills-item ".concat(roundedClass, " ").concat(disabledActiveClass, " ").concat(itemClass), onClick: function () {
                    if (props.disabled)
                        return;
                    if (props === null || props === void 0 ? void 0 : props.useToggle) {
                        handleToggle(idx);
                    }
                    else {
                        setActiveIndex(idx);
                        onChange(x);
                    }
                } }, { children: _jsx("div", __assign({ className: "truncate" }, { children: x === null || x === void 0 ? void 0 : x.label })) }), "pill-".concat(idx)));
        }) })));
};
export default Pills;
