import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    filterSelector,
    tabStatusFilterSelector,
    trackingsMetaSelector,
    trackingsDataSelector,
    trackingsStatusTotalSelector,
    setTrackingData,
    setSelectedStatus,
    setPageNumber,
    setFilter,
} from './trackings.slice'
import { ITrackingFilter } from 'client/ifb/repository/interface/tracking.interface'
import { getTrackingsData } from 'client/ifb/repository/tracking.repository'

const useTrackings = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // selector
    const trackingsData = useSelector(trackingsDataSelector)
    const totalStatus = useSelector(trackingsStatusTotalSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const filter = useSelector(filterSelector)
    const trackingsDataMeta = useSelector(trackingsMetaSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [widthSize, setWidthSize] = useState(window.innerWidth)

    // variable

    // overlay
    const filterOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()

    // variable
    const { pageNumber, pageSize, containerNumber, consolStatus } = filter

    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value
        let tmpFilter: ITrackingFilter = { ...filter, consolStatus: status }

        if (status === 'all') {
            tmpFilter.consolStatus = ''
        }

        try {
            setLoading(true)
            const actionResult = await getTrackingsData(tmpFilter)
            dispatch(setTrackingData(actionResult))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error('error', e)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = () => {
        const tabItems: ITabItem[] = [
            {
                label: 'ALL',
                totalData: totalStatus.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'SEA',
                labelTranslationCode: 'status.sea',
                totalData: totalStatus.sea,
                key: 'status',
                value: 'SEA',
                className: 'brand-v2',
            },
            {
                label: 'AIR',
                labelTranslationCode: 'status.air',
                totalData: totalStatus.air,
                key: 'status',
                value: 'AIR',
                className: 'brand-v2',
            },
            {
                label: 'ROAD',
                labelTranslationCode: 'status.road',
                totalData: totalStatus.roa,
                key: 'status',
                value: 'ROA',
                className: 'brand-v2',
            },
            {
                label: 'Others',
                totalData: 0,
                key: 'status',
                value: 'others',
                className: 'brand-v2',
            },
        ]

        setTabItems(tabItems)
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(
                setFilter({
                    ...filter,
                    containerNumber: values,
                    pageNumber: 1,
                }),
            )
        } else if (values.length <= 0)
            dispatch(
                setFilter({ ...filter, containerNumber: '', pageNumber: 1 }),
            )
    }

    const updateDimensions = () => {
        setWidthSize(window.innerWidth)
    }

    // useEffects
    useEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    })

    useEffect(() => {
        loadData()
    }, [pageNumber, pageSize, consolStatus, tabFilter, containerNumber])

    useEffect(() => {
        generateTabItems()
    }, [totalStatus])

    return {
        filterOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        trackingsData,
        loading,
        errorMessage,
        widthSize,
        trackingsDataMeta,
        handleSearch,
        setLoading,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        setPageData,
    }
}

export default useTrackings
