import {
    filterCOInitialValue,
    filterCOValidation,
    IFilterCO,
    IFilterDataCategoryCO,
    IFilterShipmentEventCO,
} from 'client/ifb/form-validation/control-tower.validation'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
    averageMonthlyPerformanceSelector,
    customerChartOrganisationDataSelector,
    customerTableDataSelector,
    dataOverviewTable1Selector,
    dataOverviewTable2Selector,
    financePieChartSelector,
    financeTableDataSelector,
    historyDataSelector,
    TradeLanesDataSelector,
    setAverageMonthlyPerformance,
    setDataOverviewTable1,
    setSmallTableData,
    setHistoryDevelopment,
    setPageNumberOverviewTable2Slice,
    setHistoryFilterName,
    historyFilterNameSelector,
    setFilterDropdownOptions,
    filterDropdownOptionsSelector,
    setPageNumberTradeLanesTableSlice,
    setTradeLanesCarrierData,
    setTradeLanesRegionData,
    setHistorySplitRecord,
    setMediumTableData,
    customerSalesDataSelector,
    setPageNumberMediumTable2Slice,
    setMediumTableMultipleBarsItemsData,
    setMediumTableCardItems,
    setLoadOptionsFilterDropdown,
} from './control-tower.slice'
import { useEffect, useState } from 'react'
import { ITabItem } from '@components/tab/tab.interface'
import {
    initialDataHistoryDevelopment,
    initialDataBarChartSplitRecord,
    tabItemFilterCustomers,
    tabItemFilterFinance,
    tabItemFilterOverview,
    tabItemFilterTradeLanes,
    initialTradeLanesInfoCardItems,
} from './control-tower.static'
import { faker } from '@faker-js/faker'
import {
    IButtonContentCO,
    IButtonContentItems,
    ICOFilterByMetrics,
    IControlTowerRawData,
    IFilterDropdownOptions,
    IHistoryDevelopmentData,
    ILoadingFinance,
    ILoadingHistory,
    ILoadingOverview,
    ILoadingTradeLanes,
    ILoadOptionsFilterDropdownProps,
    IMarkShipmentCustomersAndSales,
    IMediumTableData,
    IUseControlTower,
} from './control-tower.interface'
import moment from 'moment'
import {
    fetchControlTowerHistory,
    fetchOverviewTable,
    fetchOverviewTable2,
    fetchRelatedKeyFigures,
    fetchControlTowerData,
} from 'client/ifb/repository/control-tower.repository'
import { Toast } from '@components/toast/toast.component'
import { IPillItem } from '@components/pills/pills.component'
import { IResponseData } from 'common/common.interface'
import {
    IRSmallTable,
    IRHistoryDevelopment,
    IROverviewSplitRecord,
    IRTradeLanesCarrier,
    IRTradeLanesRegion,
    IRTradeLanesHistorySplit,
    IRSalesTable,
    IROverallSales,
    IRSalesHistoryDevelopment,
    IRSalesHistorySplitRecord,
    IRCustomerOrganisationTable,
    IRCustomerOrgDevelopment,
} from 'client/ifb/repository/interface/control-tower.interface'
import { formatDecimalNumber, numberWithCommas } from '@services/common.service'
import { ifbEndpoints } from 'client/ifb/endpoints/ifb.endpoints'
import { IBarchartStacktedDataItem } from '@components/rechart-component/rechart-interface'
import { InfoCardGroupItems } from '@components/InfoCardGroup/Info-card-group.interface'
import { customRounding, debounce, getEnv } from 'common/common.service'
import { IProgressMultipleBarsData } from '@components/progress-multiple-bars/progress-multiple-bars.interface'
import {
    fetchLoglinesMetaData,
    loadContainerModeDropdown,
    loadTransportModeDropdown,
} from 'client/ifb/repository/loglinesMetaData.repository'
import {
    IRLoglineMetaBranchData,
    IRLoglineMetaContainerModeData,
} from 'client/ifb/repository/interface/loglinesMetaData.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { userDataSelector } from 'client/common/pages/login/login.slice'

const useControlTower = (): IUseControlTower => {
    const dispatch = useDispatch()

    // selector common
    const user = useSelector(userDataSelector)
    const historyData = useSelector(historyDataSelector)
    const historyFilterName = useSelector(historyFilterNameSelector)
    const filterDropdownOptions = useSelector(filterDropdownOptionsSelector)
    const customerSalesData = useSelector(customerSalesDataSelector)

    // selector overview
    const dataOverviewTable1 = useSelector(dataOverviewTable1Selector)
    const dataOverviewTable2 = useSelector(dataOverviewTable2Selector)

    // selector trade lanes
    const tradeLanesData = useSelector(TradeLanesDataSelector)

    // selector trade lanes
    const customerChartOrganisationData = useSelector(
        customerChartOrganisationDataSelector,
    )
    const customerTableData = useSelector(customerTableDataSelector)

    // selector finance
    const financeTableData = useSelector(financeTableDataSelector)
    const financePieChart = useSelector(financePieChartSelector)
    const averageMonthlyPerformance = useSelector(
        averageMonthlyPerformanceSelector,
    )

    // state common
    const [buttonContent, setButtonContent] =
        useState<IButtonContentItems>('overview')
    const [loadingHistory, setLoadingHistory] = useState<ILoadingHistory>({
        development: true,
        splitRecord: true,
    })
    const [isInitialRender, setIsInitialRender] = useState<boolean>(true)
    const [rawData, setRawData] = useState<IControlTowerRawData>({
        dataOverviewTable2: [],
    })
    const [loadingMediumTable, setLoadingMediumTable] = useState<boolean>(true)
    const [rawMediumTable, setRawMediumTable] = useState<IMediumTableData[]>([])

    // state overview
    const [tabFilterOverview, setTabFilterOverview] = useState<ITabItem>(
        tabItemFilterOverview[0],
    )
    const [loadingOverview, setLoadingOverview] = useState<ILoadingOverview>({
        relatedKeyFigures: true,
        overviewTable2: true,
    })

    // state trade lanes
    const [tabFilterTradeLanes, setTabFilterTradeLanes] = useState<ITabItem>(
        tabItemFilterTradeLanes[0],
    )
    const [loadingTradeLanes, setLoadinTradeLanes] =
        useState<ILoadingTradeLanes>({
            region: true,
            carrier: true,
            table: true,
        })
    const [rawTableTradeLanes, setRawTableTradeLanes] = useState<
        IRSmallTable[]
    >([])

    // state customers
    const [tabFilterCustomers, setTabFilterCustomers] = useState<ITabItem>(
        tabItemFilterCustomers[0],
    )

    // state sales
    const [tabFilterSales, setTabFilterSales] = useState<ITabItem>(
        tabItemFilterCustomers[0],
    )

    // state finance
    const [tabFilterFinance, setTabFilterFinance] = useState<ITabItem>(
        tabItemFilterFinance[0],
    )
    const [loadingFinance, setLoadingFinance] = useState<ILoadingFinance>({
        averageMonthlyPerformance: true,
    })

    // formik
    const formikFilterCO = useFormik<IFilterCO>({
        validationSchema: filterCOValidation,
        initialValues: filterCOInitialValue,
        onSubmit: async () => {},
    })
    const env = getEnv()
    const devOnlyCOBtnClassName =
        env !== 'production' ? 'col-span-4' : 'col-span-6'

    // function & variable common
    const buttonContentCO: IButtonContentCO[] = [
        {
            onClick: () => setButtonContent('overview'),
            label: 'OVERVIEW',
            icon: 'ri-pages-line',
            className: 'col-span-6',
        },
        {
            onClick: () => setButtonContent('tradeLanes'),
            label: 'trade lanes',
            icon: 'ri-share-line',
            useUpperCase: true,
            className: 'col-span-6',
        },

        {
            onClick: () => setButtonContent('customers'),
            label: 'customers',
            icon: 'ri-user-heart-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName}`,
        },
        {
            onClick: () => setButtonContent('sales'),
            label: 'sales',
            icon: 'ri-money-cny-box-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName} !pl-0`,
        },
        {
            onClick: () => setButtonContent('finance'),
            label: 'finance',
            icon: 'ri-book-2-line',
            useUpperCase: true,
            className: `${devOnlyCOBtnClassName}`,
            devOnly: true,
        },
    ]
    const wrapFetchDataContent = async () => {
        if (Object.keys(formikFilterCO.errors).length !== 0) {
            Toast({
                header: 'Improper Filter Selection!',
                message: formikFilterCO.errors.EndDate || '',
                type: 'error',
            })
            return
        }
        // reset pagination medium table
        handlingPageNumberMediumTable(1)

        setLoadingHistory({
            development: true,
            splitRecord: true,
        })
        switch (buttonContent) {
            case 'overview':
                fechtOverviewData()
                break
            case 'tradeLanes':
                loadTradeLanesData()
                break
            case 'customers':
                loadCustomersData()
                break
            case 'sales':
                loadSalesData()
                break
            case 'finance':
                loadAverageMonthlyPerformanceData()
                fechtOverviewData()
                break
            default:
                fechtOverviewData()
        }
    }
    const handlePillsDataCategory = (
        data: IPillItem<IFilterDataCategoryCO>,
    ) => {
        const dataCategory: IFilterDataCategoryCO =
            formikFilterCO.values.DataCategory

        // Set semua properti ke false
        Object.keys(dataCategory).forEach((key) => {
            dataCategory[key as keyof IFilterDataCategoryCO] = false
        })

        if (Object.prototype.hasOwnProperty.call(dataCategory, data.value)) {
            dataCategory[data.value] = true
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            DataCategory: dataCategory,
        })
    }
    const handlePillsShipmentEvent = (
        data: IPillItem<IFilterShipmentEventCO>,
    ) => {
        const shipmentEvent = formikFilterCO.values.ShipmentEvent

        if (Object.prototype.hasOwnProperty.call(shipmentEvent, data.value)) {
            shipmentEvent[data.value] = data.isActive || false
        }

        formikFilterCO.setValues({
            ...formikFilterCO.values,
            ShipmentEvent: shipmentEvent,
        })
    }
    const wrapFetchHistoryData = async (name: string) => {
        setLoadingHistory({
            development: true,
            splitRecord: true,
        })

        if (!name) {
            loadEmpityHistorySplitRecord()
            return
        }

        switch (buttonContent) {
            case 'overview':
                loadOverviewHistory(name)
                break
            case 'tradeLanes':
                loadTradeLanesHistory(name)
                break
            case 'customers':
                loadCustomersHistory(name)
                break
            case 'sales':
                loadSalesHistory(name)
                break
            default:
                loadEmpityHistorySplitRecord()
        }
    }
    const loadEmpityHistorySplitRecord = async () => {
        // history development
        setHistoryDevelopmentData(null)

        // history split record
        const barChartSplitRecord: IBarchartStacktedDataItem[] = []
        dispatch(
            setHistorySplitRecord({
                chartData: barChartSplitRecord,
                infoCardGroupItems: initialTradeLanesInfoCardItems,
            }),
        )
        setLoadingHistory({
            splitRecord: false,
            development: false,
        })
    }
    const handleHistoryFilter = (companyName: string) => {
        dispatch(setHistoryFilterName(companyName))
    }
    const getActiveFieldFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: ICOFilterByMetrics
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'shipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'revenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'gp',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'volume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }
    const loadFilterDropdownOption = async () => {
        try {
            let filterOptions: IFilterDropdownOptions = {
                transportMode: [],
                containerMode: [],
                company: [{ label: 'All Company', value: 'all' }],
            }

            const company = fetchLoglinesMetaData<IRLoglineMetaBranchData[]>(
                ifbEndpoints.loglines_meta_data_Branch,
            )
                .then((res) => {
                    filterOptions.company = res?.data?.map((item, index) => ({
                        label: index === 0 ? 'All Company' : item.company,
                        value: index === 0 ? 'all' : item.company,
                    }))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })

            await Promise.allSettled([company])

            filterOptions.transportMode = loadTransportModeDropdown('all')
            filterOptions.containerMode = loadContainerModeDropdown(
                formikFilterCO.values.TransportMode,
            )

            dispatch(setFilterDropdownOptions(filterOptions))
        } catch (error) {
            console.error(error)
        }
    }
    const loadTransportContainerModeDropdown = (
        typechangeValue: 'containerMode' | 'transportMode',
    ) => {
        if (typechangeValue === 'transportMode') {
            const containerModeOptions = loadContainerModeDropdown(
                formikFilterCO?.values?.TransportMode,
            )
            formikFilterCO.setFieldValue(
                'ContainerMode',
                containerModeOptions[0]?.value,
            )
            dispatch(
                setFilterDropdownOptions({
                    ...filterDropdownOptions,
                    containerMode: containerModeOptions,
                }),
            )
        } else {
            const transportModeOptions = loadTransportModeDropdown(
                formikFilterCO?.values?.ContainerMode,
            )
            formikFilterCO.setFieldValue(
                'TransportMode',
                transportModeOptions[0]?.value,
            )

            dispatch(
                setFilterDropdownOptions({
                    ...filterDropdownOptions,
                    transportMode: transportModeOptions,
                }),
            )
        }
    }
    const setHistoryDevelopmentData = (
        data: IHistoryDevelopmentData | null,
    ) => {
        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        let index = 1

        const fieldName = getActiveFieldFilterDataCategory()
        try {
            while (start.isSameOrBefore(end, 'month')) {
                const month = start.format('MM')
                const year = start.format('YYYY')
                let currentValue = undefined
                let previousValue = undefined

                if (data !== null) {
                    const findDataByMonthYear = data?.graphData?.find(
                        (item) => item?.month === month && item?.year === year,
                    )
                    currentValue =
                        findDataByMonthYear && findDataByMonthYear?.current
                            ? findDataByMonthYear?.current[
                                  fieldName?.key as keyof typeof findDataByMonthYear.current
                              ]
                            : undefined
                    previousValue =
                        findDataByMonthYear && findDataByMonthYear?.previous
                            ? findDataByMonthYear?.previous[
                                  fieldName?.key as keyof typeof findDataByMonthYear.previous
                              ]
                            : undefined
                }

                rangeMonth.push(start.format('MMM'))
                line1.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(currentValue) || 0,
                    keyId: 'line1',
                })
                line2.push({
                    x: index,
                    secName: start.format('YYYY'),
                    y: Number(previousValue) || 0,
                    keyId: 'line2',
                })

                start.add(1, 'month')
                index++
            }

            const valueInfoCard = data?.averageInfo
                ? data?.averageInfo[
                      fieldName.infoCardSplitRecordKey as keyof typeof data.averageInfo
                  ]
                : 0

            const infoCardItems = [
                {
                    value: handleFormatValueNumber(valueInfoCard),
                    label: `Average ${fieldName.label}`,
                },
            ]

            dispatch(
                setHistoryDevelopment({
                    line1,
                    line2,
                    tickItems: rangeMonth,
                    infoCardItems,
                }),
            )
        } catch (error) {
            console.error(error)
        }
    }
    const loadMediumTableUseRawData = () => {
        dispatch(
            setMediumTableData({
                data: rawMediumTable,
                useRawData: true,
            }),
        )
    }
    const handlingPageNumberMediumTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberMediumTable2Slice(pageNumber))
    }
    const loadOptionsFilterDropdown = debounce(
        async ({ containerMode, company }: ILoadOptionsFilterDropdownProps) => {
            try {
                let options: IDropdownItem[] = []
                let loadType

                switch (true) {
                    case !!containerMode: {
                        const fetchData: IResponseData<
                            IRLoglineMetaContainerModeData[]
                        > = await fetchLoglinesMetaData(
                            ifbEndpoints.loglines_meta_data_ContainerMode,
                            {
                                container: containerMode,
                            },
                        )
                        options = fetchData?.data?.map((item) => ({
                            label: `${item.code} - ${item.description}`,
                            value: item.code,
                        }))
                        loadType = 'containerMode'
                        break
                    }
                    case !!company: {
                        const fetchData: IResponseData<
                            IRLoglineMetaBranchData[]
                        > = await fetchLoglinesMetaData(
                            ifbEndpoints.loglines_meta_data_Branch,
                            {
                                branch: company,
                            },
                        )
                        options = fetchData?.data?.map((item) => ({
                            label: item.company,
                            value: item.company,
                        }))
                        loadType = 'company'
                        break
                    }
                    default:
                        break
                }
                if (options.length && loadType) {
                    dispatch(
                        setLoadOptionsFilterDropdown({
                            options: options,
                            loadType,
                        }),
                    )
                }
                return options
            } catch (error) {
                console.error(error)
            }
        },
        500,
    )
    const handleFormatValueNumber = (value: number | string) => {
        return customRounding({
            value,
            useDecimalPoint: formikFilterCO.values.DataCategory.Shipments
                ? false
                : true,
        })
    }

    // function overview
    const fechtOverviewData = async () => {
        setLoadingOverview({
            relatedKeyFigures: true,
            overviewTable2: true,
        })
        try {
            const relatedKeyFigures = fetchRelatedKeyFigures(
                formikFilterCO.values,
            )
                .then((res) => {
                    if (res.isSuccess) {
                        dispatch(setDataOverviewTable1(res.data))
                    }
                })
                .catch((error) => {
                    console.error(
                        'Error fetching data related key figures:',
                        error,
                    )
                })
                .finally(() => {
                    setLoadingOverview((prevLoading) => ({
                        ...prevLoading,
                        relatedKeyFigures: false,
                    }))
                })

            const tableOverview2 = loadOverviewTable2()
            await Promise.allSettled([relatedKeyFigures, tableOverview2])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadOverviewTable2 = async () => {
        try {
            const loadData = await fetchControlTowerData<IRSmallTable[]>(
                formikFilterCO.values,
                ifbEndpoints.control_tower_Overview + tabFilterOverview.value,
                { countryCode: user.userDefaultData?.country.code || '' },
            )
            const fieldName = getActiveFieldFilterDataCategory()

            dispatch(
                setSmallTableData({
                    data: loadData.data,
                    filter: fieldName.key,
                    content: buttonContent,
                }),
            )
            setRawData((prevState) => ({
                ...prevState,
                dataOverviewTable2: loadData.data,
            }))

            const history = wrapFetchHistoryData(loadData?.data[0]?.name || '')

            await Promise.allSettled([loadData, history])
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoadingOverview((prevLoading) => ({
                ...prevLoading,
                overviewTable2: false,
            }))
        }
    }
    const loadOverviewTable2UseRawData = () => {
        dispatch(
            setSmallTableData({
                data: rawData.dataOverviewTable2,
                filter: getActiveFieldFilterDataCategory().key,
                useRawData: true,
                content: buttonContent,
            }),
        )
        wrapFetchHistoryData(historyFilterName)
    }
    const handlingPageNumberOverviewTable2 = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberOverviewTable2Slice(pageNumber))
    }
    const loadOverviewHistory = async (name: string) => {
        let developmentParams = {
            endpoint: '',
            customField: {},
        }
        let splitRecordParams = {
            endpoint: '',
            customField: {},
        }

        try {
            switch (tabFilterOverview.value) {
                case 'Customer':
                    developmentParams = {
                        customField: { companyName: name },
                        endpoint:
                            ifbEndpoints.control_tower_CustomerHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: { custName: name },
                        endpoint:
                            ifbEndpoints.control_tower_CustomerHistorySplitRecord,
                    }
                    break
                case 'Branch':
                    developmentParams = {
                        customField: { branchName: name },
                        endpoint:
                            ifbEndpoints.control_tower_BranchHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: { branchName: name },
                        endpoint:
                            ifbEndpoints.control_tower_BranchHistorySplitRecord,
                    }
                    break
                case 'Agent':
                    developmentParams = {
                        customField: { agentName: name },
                        endpoint:
                            ifbEndpoints.control_tower_AgentHistoryDevelopmnent,
                    }
                    splitRecordParams = {
                        customField: { agentName: name },
                        endpoint:
                            ifbEndpoints.control_tower_AgentHistorySplitRecord,
                    }
                    break
            }

            const historyDevelopment =
                fetchControlTowerData<IRHistoryDevelopment>(
                    formikFilterCO.values,
                    developmentParams.endpoint,
                    {
                        ...developmentParams.customField,
                        countryCode: user.userDefaultData?.country.code || '',
                    },
                )
                    .then(({ data }) => {
                        const flattenedData = initialDataHistoryDevelopment

                        if (data?.current?.items) {
                            flattenedData.graphData = data?.current?.items.map(
                                (item) => {
                                    const [year, month] = item.label.split('-')
                                    const findPreviousDataByLabel =
                                        data?.previous?.items?.find(
                                            (previous) =>
                                                previous.label === item.label,
                                        )
                                    let formatMonth = ''
                                    if (month) {
                                        formatMonth =
                                            parseFloat(month) < 10
                                                ? `0${month}`
                                                : month
                                    }
                                    return {
                                        year: year,
                                        month: formatMonth,
                                        current: {
                                            shipment: item.shipment || 0,
                                            revenue: item.revenue || 0,
                                            volume: item.volume || 0,
                                            gp: item.gp || 0,
                                        },
                                        previous: {
                                            shipment:
                                                findPreviousDataByLabel?.shipment ||
                                                0,
                                            revenue:
                                                findPreviousDataByLabel?.revenue ||
                                                0,
                                            volume:
                                                findPreviousDataByLabel?.volume ||
                                                0,
                                            gp:
                                                findPreviousDataByLabel?.gp ||
                                                0,
                                        },
                                    }
                                },
                            )
                            flattenedData.averageInfo = {
                                avgCurrentRevenue: data?.avgRevenue || 0,
                                avgCurrentGp: data?.avgGp || 0,
                                avgCurrentShipment: data?.avgShipment || 0,
                                avgCurrentVolume: data?.avgVolume || 0,
                            }
                        }

                        setHistoryDevelopmentData(flattenedData)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerData<IROverviewSplitRecord>(
                    formikFilterCO.values,
                    splitRecordParams.endpoint,
                    {
                        ...splitRecordParams.customField,
                        countryCode: user.userDefaultData?.country.code || '',
                    },
                )
                    .then(({ data }) => {
                        const fieldName = getActiveFieldFilterDataCategory()
                        const initialData =
                            historyData.splitRecord.chartData.data

                        const fieldMap = {
                            'This Period': 'Current',
                            'Last Period': 'Previous',
                        }

                        const flattenedData: IBarchartStacktedDataItem[] = !data
                            ?.datas.length
                            ? []
                            : initialData?.map((item) => {
                                  const getFieldMapData =
                                      fieldMap[
                                          item.name as keyof typeof fieldMap
                                      ]
                                  const findDataByPeriod = data?.datas?.find(
                                      (fetchItem) =>
                                          fetchItem?.period?.toLocaleLowerCase() ===
                                          getFieldMapData?.toLocaleLowerCase(),
                                  )
                                  const nominationData =
                                      findDataByPeriod?.items?.find(
                                          (subItem) =>
                                              subItem?.category?.toLocaleLowerCase() ===
                                              'nomination',
                                      )
                                  const localControlData =
                                      findDataByPeriod?.items?.find(
                                          (subItem) =>
                                              subItem?.category?.toLocaleLowerCase() ===
                                              'local control',
                                      )
                                  return {
                                      ...item,
                                      f1: nominationData
                                          ? Number(
                                                nominationData[
                                                    fieldName.key as keyof typeof nominationData
                                                ],
                                            )
                                          : 0,
                                      f2: localControlData
                                          ? Number(
                                                localControlData[
                                                    fieldName.key as keyof typeof localControlData
                                                ],
                                            )
                                          : 0,
                                  }
                              })
                        dispatch(
                            setHistorySplitRecord({
                                chartData: flattenedData,
                                infoCardGroupItems:
                                    initialTradeLanesInfoCardItems.map(
                                        (item) => ({
                                            ...item,
                                            value: handleFormatValueNumber(
                                                Number(item.value),
                                            ),
                                        }),
                                    ),
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
        }
    }

    // function trade lanes
    const loadTradeLanesData = async () => {
        setLoadinTradeLanes({
            carrier: true,
            table: true,
            region: true,
        })
        try {
            const tradeLanesRegion = fetchControlTowerData<IRTradeLanesRegion>(
                formikFilterCO.values,
                ifbEndpoints.control_tower_TrandeLanesRegion,
            )
                .then((res) => {
                    dispatch(
                        setTradeLanesRegionData({
                            data: res.data,
                            filter: formikFilterCO.values.DataCategory,
                        }),
                    )
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
                .finally(() => {
                    setLoadinTradeLanes((prevLoading) => ({
                        ...prevLoading,
                        region: false,
                    }))
                })

            const tradeLanesCarrier =
                fetchControlTowerData<IRTradeLanesCarrier>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_TrandeLanesCarrier,
                )
                    .then((res) => {
                        dispatch(
                            setTradeLanesCarrierData({
                                data: res.data,
                                filter: formikFilterCO.values.DataCategory,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadinTradeLanes((prevLoading) => ({
                            ...prevLoading,
                            carrier: false,
                        }))
                    })

            const tabletradeLanes = loadTradeLanesTable()

            await Promise.allSettled([
                tradeLanesRegion,
                tradeLanesCarrier,
                tabletradeLanes,
            ])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadTradeLanesHistory = async (name: string) => {
        let developmentParams: {
            endpoint: string
            field: {}
        } | null = {
            endpoint: '',
            field: {},
        }
        let splitRecordParams = {
            endpoint: '',
            field: {},
        }
        let fieldNameMap = {
            averageInfo: '',
            graphData: '',
        }

        try {
            switch (tabFilterTradeLanes.value) {
                case 'Countries':
                    if (name) {
                        developmentParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TrandeLanesCountriesDevelopment,
                            field: { countryName: name },
                        }
                        splitRecordParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TradeLanesCountrySplitRecord,
                            field: { countryName: name },
                        }
                        fieldNameMap = {
                            averageInfo: 'country',
                            graphData: 'data',
                        }
                    }

                    break
                case 'Ports':
                    if (name) {
                        developmentParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TrandeLanesPortDevelopment,
                            field: { portName: name },
                        }
                        splitRecordParams = {
                            endpoint:
                                ifbEndpoints.control_tower_TradeLanesPortSplitRecord,
                            field: { portName: name },
                        }
                        fieldNameMap = {
                            averageInfo: 'port',
                            graphData: 'data',
                        }
                    }

                    break
                case 'Lanes':
                    developmentParams = {
                        endpoint:
                            ifbEndpoints.control_tower_TradeLaneLanesDevelopment,
                        field: { laneName: name },
                    }
                    splitRecordParams = {
                        endpoint:
                            ifbEndpoints.control_tower_TradeLaneLanesSplitRecord,
                        field: { regionName: name },
                    }
                    fieldNameMap = {
                        averageInfo: 'itemId',
                        graphData: 'data',
                    }
                    break
                default:
                    return
            }

            const historyDevelopment =
                developmentParams !== null
                    ? fetchControlTowerData<any>(
                          formikFilterCO.values,
                          developmentParams.endpoint,
                          {
                              ...developmentParams.field,
                              countryCode:
                                  user?.userDefaultData?.country?.code || '',
                          },
                      )
                          .then((res) => {
                              const flattenedChartData = {
                                  averageInfo: res?.data.itemId,
                                  graphData: res?.data?.data,
                              }
                              setHistoryDevelopmentData(flattenedChartData)
                          })
                          .catch((error) => {
                              console.error('Error fetching data:', error)
                          })
                          .finally(() => {
                              setLoadingHistory((prevState) => ({
                                  ...prevState,
                                  development: false,
                              }))
                          })
                    : null

            const historySplitRecord =
                loadTradeLanesHistorySplitRecord(splitRecordParams)

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
        }
    }
    const loadTradeLanesTable = async () => {
        try {
            let endpoint: string = ''
            switch (tabFilterTradeLanes.value) {
                case 'Countries':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesCountry
                    break
                case 'Ports':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesPort
                    break
                case 'Lanes':
                    endpoint = ifbEndpoints.control_tower_TrandeLanesLane
            }

            const fetchData = await fetchOverviewTable(
                formikFilterCO.values,
                endpoint,
            )
            const fieldName = getActiveFieldFilterDataCategory()

            dispatch(
                setSmallTableData({
                    data: fetchData?.data,
                    filter: fieldName.key,
                    content: buttonContent,
                }),
            )

            setRawTableTradeLanes(fetchData.data)

            const historyData = wrapFetchHistoryData(
                fetchData?.data[0]?.name || '',
            )

            await Promise.allSettled([fetchData, historyData])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadinTradeLanes((prevState) => ({
                ...prevState,
                table: false,
            }))
        }
    }
    const loadTradeLanesTableUseRawData = () => {
        dispatch(
            setSmallTableData({
                data: rawTableTradeLanes,
                filter: getActiveFieldFilterDataCategory().key,
                useRawData: true,
                content: buttonContent,
            }),
        )
        wrapFetchHistoryData(historyFilterName)
    }
    const handlingPageNumberTradeLanesTable = (pageNumber: number) => {
        if (pageNumber <= 0) return
        dispatch(setPageNumberTradeLanesTableSlice(pageNumber))
    }
    const loadTradeLanesHistorySplitRecord = async (params: {
        endpoint: string
        field: {}
    }) => {
        try {
            const fetchData =
                await fetchControlTowerData<IRTradeLanesHistorySplit>(
                    formikFilterCO.values,
                    params.endpoint,
                    {
                        ...params.field,
                        countryCode: user?.userDefaultData?.country?.code || '',
                    },
                )

            const fieldName = getActiveFielTradeLanesdFilterDataCategory()
            type IRTradeLanesHistorySplitKeys = keyof Pick<
                IRTradeLanesHistorySplit,
                'currentPeriod' | 'lastPeriod'
            >

            const fieldMap: Record<string, IRTradeLanesHistorySplitKeys> = {
                'This Period': 'currentPeriod',
                'Last Period': 'lastPeriod',
            }
            const summaryData = {
                nomination: { currentPeriod: 0, lastPeriod: 0 },
                localControl: { currentPeriod: 0, lastPeriod: 0 },
            }

            const flattenedChartData: IBarchartStacktedDataItem[] =
                !fetchData?.data
                    ? []
                    : initialDataBarChartSplitRecord?.map((item) => {
                          const getFieldMapData = fieldMap[item.name]
                          const findDataByPeriod =
                              fetchData?.data[getFieldMapData]
                          const nominationData =
                              findDataByPeriod.nomination[
                                  fieldName.key as keyof typeof findDataByPeriod.nomination
                              ] || 0
                          const localControlData =
                              findDataByPeriod.localAgent[
                                  fieldName.key as keyof typeof findDataByPeriod.nomination
                              ] || 0

                          summaryData.nomination[getFieldMapData] =
                              nominationData
                          summaryData.localControl[getFieldMapData] =
                              localControlData

                          return {
                              ...item,
                              f1: nominationData,
                              f2: localControlData,
                          }
                      })

            //   comment for go live 15 jan 2025
            // flattened Summary Data
            // const subValueNomination =
            //     summaryData.nomination.currentPeriod -
            //     summaryData.nomination.lastPeriod
            // const subValueLocalControl =
            //     summaryData.localControl.currentPeriod -
            //     summaryData.localControl.lastPeriod

            const nominationValue = handleFormatValueNumber(
                summaryData.nomination.currentPeriod +
                    summaryData.nomination.lastPeriod,
            )
            const localControlValue = handleFormatValueNumber(
                summaryData.localControl.currentPeriod +
                    summaryData.localControl.lastPeriod,
            )
            const flattenedSummaryData: InfoCardGroupItems[] = [
                {
                    value: nominationValue.toString(),
                    // subValue: `${subValueNomination > 0 ? '+' : ''}${subValueNomination}`, comment for go live 15 jan 2025
                    label: 'Nomination',
                },
                {
                    value: localControlValue.toString(),
                    // subValue: `${subValueLocalControl > 0 ? '+' : ''}${subValueLocalControl}`, comment for go live 15 jan 2025
                    label: 'Local Control',
                },
            ]

            dispatch(
                setHistorySplitRecord({
                    chartData: flattenedChartData,
                    infoCardGroupItems: flattenedSummaryData,
                }),
            )
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory((prevState) => ({
                ...prevState,
                splitRecord: false,
            }))
        }
    }
    const getActiveFielTradeLanesdFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: string
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'totalShipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'totalRevenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'totalGP',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'totalVolume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function customers
    const loadCustomersData = async () => {
        setLoadingMediumTable(true)
        try {
            const fieldName = getActiveFieldFilterDataCategory()

            const fetchData =
                await fetchControlTowerData<IRCustomerOrganisationTable>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_CustomerOrganisation,
                )
            const { shipment, charge, listOrganization } = fetchData.data

            const handleMapFieldCardItemsValue = (data?: string | number) =>
                data ? numberWithCommas(data.toString()) : '0'

            const flattenedCardItems: InfoCardGroupItems[] = [
                {
                    value: handleMapFieldCardItemsValue(
                        shipment.current.totalShipment || '0',
                    ),
                    label: 'Overall Shipments',
                },
                {
                    value: `${user.userDefaultData.currency.code || ''} ${formatDecimalNumber(parseFloat(charge.changes.totalRev.toFixed(2)))}`,
                    label: 'Overall Revenue',
                },
                {
                    value: `${user.userDefaultData.currency.code || ''} ${formatDecimalNumber(Number(charge.changes.totalGp.toFixed(2)))}`,
                    label: 'Overall Gross Profit',
                },
            ]

            dispatch(setMediumTableCardItems(flattenedCardItems))

            const flattenedTableData: IMediumTableData[] =
                listOrganization?.map((item, idx) => {
                    return {
                        index: idx,
                        name: item?.name || '',
                        thisPeriod: handleFormatValueNumber(
                            item.current[fieldName.key],
                        ),
                        lastPeriod: handleFormatValueNumber(
                            item.previous[fieldName.key],
                        ),
                        development: {
                            value: handleFormatValueNumber(
                                item?.changes[fieldName.key]?.value || 0,
                            ),
                            percentage: customRounding({
                                value:
                                    item?.changes[fieldName.key]?.percentage ||
                                    0,
                                useCheckDecimal: true,
                            }),
                        },
                        rev: `${
                            item.revenuePercentage
                                ? customRounding({
                                      value: item.revenuePercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gp: `${
                            item.gpPercentage
                                ? customRounding({
                                      value: item.gpPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        vol: `${
                            item.volumePercentage
                                ? customRounding({
                                      value: item.volumePercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gpPerShipment: `${user?.userDefaultData?.currency?.code || ''} ${
                            item.avgPerShipment
                                ? customRounding({
                                      value: item.volumePercentage,
                                      useDecimalPoint: true,
                                  })
                                : '0.00'
                        }`,
                    }
                })

            dispatch(
                setMediumTableData({
                    data: flattenedTableData,
                }),
            )
            setRawMediumTable(flattenedTableData)

            const historyData = wrapFetchHistoryData(
                listOrganization[0]?.name || '',
            )
            await Promise.allSettled([fetchData, historyData])
        } catch (error) {
            console.error(error)
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
        } finally {
            setLoadingMediumTable(false)
        }
    }
    const loadCustomersHistory = async (name: string) => {
        try {
            const historyDevelopment =
                fetchControlTowerData<IRCustomerOrgDevelopment>(
                    formikFilterCO.values,
                    ifbEndpoints.control_tower_CustomerOrganisationHistoryDevelopment,
                    {
                        orgName: name,
                        countryCode: user?.userDefaultData?.country.code,
                    },
                )
                    .then(({ data }) => {
                        const flattenedAvarageData = {
                            avgCurrentRevenue: data.avgCurrentRevenue,
                            avgCurrentGp: data.avgCurrentGp,
                            avgCurrentShipment: data.avgCurrentShipment,
                            avgCurrentVolume: data.avgCurrentVolume,
                        }

                        const flattenedGraphData =
                            data?.listPeriodHistItem?.map((item) => {
                                return {
                                    year: item.year,
                                    month: item.month,
                                    current: {
                                        shipment: item?.current?.shipment || 0,
                                        revenue: item?.current?.revenue || 0,
                                        volume: item?.current?.volume || 0,
                                        gp: item?.current?.gp || 0,
                                    },
                                    previous: {
                                        shipment: item?.previous?.shipment || 0,
                                        revenue: item?.previous?.revenue || 0,
                                        volume: item?.previous?.volume || 0,
                                        gp: item?.previous?.gp || 0,
                                    },
                                }
                            })

                        const flattenedChartData = {
                            averageInfo: flattenedAvarageData,
                            graphData: flattenedGraphData,
                        }

                        setHistoryDevelopmentData(flattenedChartData)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
        }
    }

    // funtion sales
    const loadSalesData = async () => {
        try {
            const overallSales = fetchControlTowerData<IROverallSales>(
                formikFilterCO.values,
                getEnpointSalesByTabFilter()?.endpoint?.overallSales || '',
                {
                    currencyCode: user.userDefaultData?.currency.code || '',
                    countryCode: user.userDefaultData?.country.code || '',
                },
            )
                .then(({ data }) => {
                    const totalPercentage = data?.queryResults.reduce(
                        (total, item) => total + item.percentage,
                        0,
                    )
                    const flattenedMultipleBarsData: IProgressMultipleBarsData<IMarkShipmentCustomersAndSales> =
                        {
                            total: totalPercentage,
                            items: {
                                value: {
                                    blue:
                                        data?.queryResults[0]?.percentage || 0,
                                    aqua:
                                        data?.queryResults[1]?.percentage || 0,
                                    purple:
                                        data?.queryResults[2]?.percentage || 0,
                                    orange:
                                        data?.queryResults[3]?.percentage || 0,
                                    yellow:
                                        data?.queryResults[4]?.percentage || 0,
                                    red: data?.queryResults[5]?.percentage || 0,
                                    gray:
                                        data?.queryResults[6]?.percentage || 0,
                                },
                            },
                        }
                    dispatch(
                        setMediumTableMultipleBarsItemsData(
                            flattenedMultipleBarsData,
                        ),
                    )

                    const handleMapFieldCardItemsValue = (
                        data?: string | number,
                    ) => (data ? numberWithCommas(data.toString()) : '0')

                    const flattenedCardItems: InfoCardGroupItems[] = [
                        {
                            value: handleMapFieldCardItemsValue(
                                data.overallShipment,
                            ),
                            // subValue: `${Number(handleMapFieldCardItemsValue(data.shipmentDiff)) > 0 ? '+' : ''}${handleMapFieldCardItemsValue(data.shipmentDiff)}`, comment for go live 15 jan 2025
                            label: 'Overall Shipments',
                        },
                        {
                            value: `${user.userDefaultData?.currency.code || ''} ${formatDecimalNumber(data.overallRevenue)}`,
                            // subValue: `${Number(handleMapFieldCardItemsValue(data.revenueDiff)) > 0 ? '+' : ''}${handleMapFieldCardItemsValue(data.revenueDiff)}`, comment for go live 15 jan 2025
                            label: 'Overall Revenue',
                        },
                        {
                            value: `${user.userDefaultData?.currency.code || ''} ${formatDecimalNumber(data.overallGP)}`,
                            // subValue: `${Number(handleMapFieldCardItemsValue(data.gpDiff)) > 0 ? '+' : ''}${handleMapFieldCardItemsValue(data.gpDiff)}`, comment for go live 15 jan 2025
                            label: 'Overall Gross Profit',
                        },
                    ]

                    dispatch(setMediumTableCardItems(flattenedCardItems))
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })

            const tableSales = loadSalesTable()

            await Promise.allSettled([overallSales, tableSales])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
    const loadSalesTable = async () => {
        setLoadingMediumTable(true)
        try {
            const endpoint =
                getEnpointSalesByTabFilter()?.endpoint?.tableSales || ''
            const fieldName = getActiveFieldFilterDataCategory()

            const fetchData = await fetchControlTowerData<IRSalesTable[]>(
                formikFilterCO.values,
                endpoint,
                {
                    currencyCode: user.userDefaultData?.currency.code || '',
                    countryCode: user.userDefaultData?.country.code || '',
                },
            )

            const flattenedData: IMediumTableData[] = fetchData?.data?.map(
                (item, idx) => {
                    return {
                        index: idx,
                        name: item?.name || '',
                        thisPeriod: handleFormatValueNumber(
                            item.current[fieldName.key],
                        ),
                        lastPeriod: handleFormatValueNumber(
                            item.previous[fieldName.key],
                        ),
                        development: {
                            value: handleFormatValueNumber(
                                item.changes[fieldName.key].value,
                            ),
                            percentage: customRounding({
                                value: item.changes[fieldName.key].percentage,
                                useCheckDecimal: true,
                            }),
                        },
                        rev: `${
                            item.revPercentage
                                ? customRounding({
                                      value: item.revPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gp: `${
                            item.gpPercentage
                                ? customRounding({
                                      value: item.gpPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        vol: `${
                            item.volPercentage
                                ? customRounding({
                                      value: item.volPercentage,
                                      useCheckDecimal: true,
                                  })
                                : '0'
                        }%`,
                        gpPerShipment: `${user.userDefaultData.currency.code} ${
                            item.avgGpPerShipment
                                ? customRounding({
                                      value: item.volPercentage,
                                      useDecimalPoint: true,
                                  })
                                : '0.00'
                        }`,
                    }
                },
            )

            dispatch(
                setMediumTableData({
                    data: flattenedData,
                }),
            )

            setRawMediumTable(flattenedData)
            const historyData = wrapFetchHistoryData(
                fetchData?.data[0]?.name || '',
            )
            await Promise.allSettled([fetchData, historyData])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingMediumTable(false)
        }
    }
    const getEnpointSalesByTabFilter = (name?: string) => {
        let endpoint = {
            tableSales: '',
            overallSales: '',
            salesHistoryDevelopment: '',
            salesHistorySplitRecord: '',
        }
        let paramsCustomField = {}
        switch (tabFilterSales.value) {
            case 'Organisations':
                endpoint = {
                    tableSales: ifbEndpoints.control_tower_SalesOrganisation,
                    overallSales:
                        ifbEndpoints.control_tower_SalesOverallOrganisation,
                    salesHistoryDevelopment:
                        ifbEndpoints.control_tower_SalesOrganisationDevelopment,
                    salesHistorySplitRecord:
                        ifbEndpoints.control_tower_SalesOrganisationSplit,
                }
                paramsCustomField = { salesOrgName: name || '' }
                break
            case 'Contact':
                endpoint = {
                    tableSales: ifbEndpoints.control_tower_SalesContact,
                    overallSales:
                        ifbEndpoints.control_tower_OverallSalesContact,
                    salesHistoryDevelopment:
                        ifbEndpoints.control_tower_SalesContactHistoryDevelopment,
                    salesHistorySplitRecord:
                        ifbEndpoints.control_tower_SalesContactHistorySplitRecord,
                }
                paramsCustomField = { salesStaffName: name || '' }
                break
        }
        return {
            endpoint,
            paramsCustomField: {
                ...paramsCustomField,
                currencyCode: user.userDefaultData?.currency.code || '',
                countryCode: user.userDefaultData?.country.code || '',
            },
        }
    }
    const loadSalesHistory = async (name: string) => {
        const x = getEnpointSalesByTabFilter(name)

        try {
            const historyDevelopment =
                fetchControlTowerHistory<IRSalesHistoryDevelopment>({
                    filter: formikFilterCO.values,
                    endpoint: x?.endpoint?.salesHistoryDevelopment || '',
                    field: x?.paramsCustomField || {},
                })
                    .then(({ data }) => {
                        const flattenedChartData = {
                            averageInfo: data?.itemId,
                            graphData: data?.data,
                        }
                        setHistoryDevelopmentData(flattenedChartData)
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            development: false,
                        }))
                    })

            const historySplitRecord =
                fetchControlTowerHistory<IRSalesHistorySplitRecord>({
                    filter: formikFilterCO.values,
                    endpoint: x?.endpoint?.salesHistorySplitRecord || '',
                    field: x?.paramsCustomField || {},
                })
                    .then(({ data }) => {
                        const fieldName =
                            getActiveFielSalesdFilterDataCategory()
                        type IRTradeLanesHistorySplitKeys = keyof Pick<
                            IRTradeLanesHistorySplit,
                            'currentPeriod' | 'lastPeriod'
                        >

                        const fieldMap: Record<
                            string,
                            IRTradeLanesHistorySplitKeys
                        > = {
                            'This Period': 'currentPeriod',
                            'Last Period': 'lastPeriod',
                        }
                        const summaryData = {
                            nomination: { currentPeriod: 0, lastPeriod: 0 },
                            localControl: { currentPeriod: 0, lastPeriod: 0 },
                        }

                        // flattened Summary Data
                        const flattenedChartData: IBarchartStacktedDataItem[] =
                            initialDataBarChartSplitRecord?.map((item) => {
                                const getFieldMapData = fieldMap[item.name]
                                const findDataByPeriod = data?.[getFieldMapData]
                                const nominationData =
                                    findDataByPeriod.nomination[
                                        fieldName.key as keyof typeof findDataByPeriod.nomination
                                    ] || 0
                                const localControlData =
                                    findDataByPeriod.localAgent[
                                        fieldName.key as keyof typeof findDataByPeriod.localAgent
                                    ] || 0

                                summaryData.nomination[getFieldMapData] =
                                    nominationData
                                summaryData.localControl[getFieldMapData] =
                                    localControlData

                                return {
                                    ...item,
                                    f1: nominationData,
                                    f2: localControlData,
                                }
                            })

                        // comment for go live 15 jan 2025
                        // flattened Summary Data
                        // const subValueNomination =
                        //     data?.difference?.nomination[
                        //         fieldName.key as keyof typeof data.difference.nomination
                        //     ] || 0
                        // const subValueLocalControl =
                        //     data?.difference?.localAgent[
                        //         fieldName.key as keyof typeof data.difference.nomination
                        //     ] || 0
                        const flattenedSummaryData: InfoCardGroupItems[] = [
                            {
                                value: handleFormatValueNumber(
                                    data?.summary?.nomination[
                                        fieldName.key as keyof typeof data.difference.nomination
                                    ],
                                ),
                                // subValue: `${subValueNomination > 0 ? '+' : ''}${subValueNomination}`, comment for go live 15 jan 2025
                                label: 'Nomination',
                            },
                            {
                                value: handleFormatValueNumber(
                                    data?.summary?.localAgent[
                                        fieldName.key as keyof typeof data.difference.nomination
                                    ],
                                ),
                                // subValue: `${subValueLocalControl > 0 ? '+' : ''}${subValueLocalControl}`, comment for go live 15 jan 2025
                                label: 'Local Control',
                            },
                        ]

                        dispatch(
                            setHistorySplitRecord({
                                chartData: flattenedChartData,
                                infoCardGroupItems: flattenedSummaryData,
                            }),
                        )
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
                    .finally(() => {
                        setLoadingHistory((prevState) => ({
                            ...prevState,
                            splitRecord: false,
                        }))
                    })

            await Promise.allSettled([historyDevelopment, historySplitRecord])
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingHistory({
                splitRecord: false,
                development: false,
            })
        }
    }
    const getActiveFielSalesdFilterDataCategory = () => {
        const activeFilter = Object.entries(
            formikFilterCO.values.DataCategory,
        ).find(([key, value]) => value === true)

        if (!activeFilter) {
            throw new Error('No active filter found')
        }

        const [key] = activeFilter

        // Map key filter ke nama field di items
        const fieldMapping: Record<
            string,
            {
                key: string
                label: string
                infoCardSplitRecordKey: string
            }
        > = {
            Shipments: {
                key: 'totalShipment',
                label: 'Shipments',
                infoCardSplitRecordKey: 'avgCurrentShipment',
            },
            Revenue: {
                key: 'totalRevenue',
                label: 'Revenue',
                infoCardSplitRecordKey: 'avgCurrentRevenue',
            },
            GrossProfit: {
                key: 'totalGP',
                label: 'Gross Profit',
                infoCardSplitRecordKey: 'avgCurrentGp',
            },
            Volume: {
                key: 'totalVolume',
                label: 'Volume',
                infoCardSplitRecordKey: 'avgCurrentVolume',
            },
        }

        const fieldName = fieldMapping[key]

        if (!fieldName) {
            throw new Error(`Field mapping not found for key: ${key}`)
        }

        return fieldName
    }

    // function finance
    const loadAverageMonthlyPerformanceData = async () => {
        setLoadingFinance({
            averageMonthlyPerformance: true,
        })

        const start = moment(formikFilterCO.values.StartDate, 'MM/YYYY')
        const end = moment(formikFilterCO.values.EndDate, 'MM/YYYY')

        const rangeMonth: string[] = []
        const line1 = []
        const line2 = []
        const line3 = []
        let index = 1

        while (start.isSameOrBefore(end, 'month')) {
            rangeMonth.push(start.format('MMM'))
            line1.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line1',
            })
            line2.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line2',
            })
            line3.push({
                x: index,
                secName: start.format('YYYY'),
                y: index === 1 ? 3 : faker.number.int({ min: 0, max: 10 }),
                keyId: 'line3',
            })

            start.add(1, 'month')
            index++
        }

        dispatch(
            setAverageMonthlyPerformance({
                line1,
                line2,
                line3,
                tickItems: rangeMonth,
            }),
        )
        setTimeout(() => {
            setLoadingFinance({
                averageMonthlyPerformance: false,
            })
        }, 1000)
    }

    // useEffects
    useEffect(() => {
        formikFilterCO.submitForm()
        loadFilterDropdownOption()
    }, [])
    useEffect(() => {
        const handler = setTimeout(() => {
            setIsInitialRender(false)
            wrapFetchDataContent()
        }, 1000)
        return () => clearTimeout(handler)
    }, [formikFilterCO.values, formikFilterCO.errors])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchDataContent()
        }
    }, [buttonContent])
    useEffect(() => {
        if (!isInitialRender) {
            wrapFetchHistoryData(historyFilterName)
        }
    }, [historyFilterName])
    useEffect(() => {
        if (!isInitialRender) {
            loadMediumTableUseRawData()
        }
    }, [customerSalesData.table.filterPageNumber])
    useEffect(() => {
        if (!isInitialRender) {
            loadTransportContainerModeDropdown('transportMode')
        }
    }, [formikFilterCO.values.TransportMode])
    useEffect(() => {
        if (!isInitialRender) {
            loadTransportContainerModeDropdown('containerMode')
        }
    }, [formikFilterCO.values.ContainerMode])

    // useEffect overview
    useEffect(() => {
        if (!isInitialRender) {
            setLoadingOverview((prevState) => ({
                ...prevState,
                overviewTable2: true,
            }))
            setLoadingHistory({
                development: true,
                splitRecord: true,
            })
            loadOverviewTable2()
        }
    }, [tabFilterOverview])
    useEffect(() => {
        if (!isInitialRender) {
            loadOverviewTable2UseRawData()
        }
    }, [dataOverviewTable2.filter.pageNumber])

    // useEffect trade lanes
    useEffect(() => {
        if (!isInitialRender) {
            setLoadinTradeLanes((prevState) => ({
                ...prevState,
                table: true,
            }))
            setLoadingHistory({
                development: true,
                splitRecord: true,
            })
            loadTradeLanesTable()
        }
    }, [tabFilterTradeLanes])
    useEffect(() => {
        if (!isInitialRender) {
            loadTradeLanesTableUseRawData()
        }
    }, [tradeLanesData.table.filter.pageNumber])

    // useEffect sales
    useEffect(() => {
        if (!isInitialRender) {
            loadSalesData()
        }
    }, [tabFilterSales])

    return {
        // common
        formikFilterCO,
        buttonContentCO,
        buttonContent,
        historyData,
        loadingHistory,
        handlePillsDataCategory,
        handlePillsShipmentEvent,
        handleHistoryFilter,
        filterDropdownOptions,
        loadingMediumTable,
        customerSalesData,
        handlingPageNumberMediumTable,
        loadOptionsFilterDropdown,
        getActiveFieldFilterDataCategory,
        handleFormatValueNumber,

        // overview
        dataOverviewTable1,
        dataOverviewTable2,
        tabFilterOverview,
        setTabFilterOverview,
        loadingOverview,
        handlingPageNumberOverviewTable2,

        // trade lanes
        tradeLanesData,
        tabFilterTradeLanes,
        setTabFilterTradeLanes,
        handlingPageNumberTradeLanesTable,
        loadingTradeLanes,

        // customers
        tabFilterCustomers,
        setTabFilterCustomers,
        customerChartOrganisationData,
        customerTableData,

        // sales
        tabFilterSales,
        setTabFilterSales,

        // finance
        loadingFinance,
        financeTableData,
        tabFilterFinance,
        setTabFilterFinance,
        financePieChart,
        averageMonthlyPerformance,
    }
}

export default useControlTower
