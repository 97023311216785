import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import { IUseCreateBooking } from '../create-booking.interface'
import { useTranslation } from 'react-i18next'
import FormMeasurementInput from '@components/form-measurement-input/form-measurement-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import OriginForm from '../../components/origin-form.component'
import DestinationForm from '../../components/destination-form.component'
import { ICreateBookingValidation } from 'client/ifb/form-validation/create-booking.validation'

const Details = ({ CBService }: { CBService: IUseCreateBooking }) => {
    const { t } = useTranslation()
    return (
        <div className="overflow-auto h-inherit">
            <div className={`grid grid-cols-4 gap-3 p-3`}>
                {/* Shipment Details */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Shipment Details</div>
                    {CBService.modeTipe !== 'cou' && (
                        <FormInput
                            label={'Container Mode'}
                            name={`containerMode`}
                            // placeholder={t('action.enter', {
                            //     value: 'Container Mode',
                            // })}
                            placeholder="Enter here"
                            disabled
                            parentDivClassName="!mb-0 "
                            useUppercaseLabel
                        />
                    )}

                    <FormDropdown
                        isSearchable
                        label={'Transport Mode'}
                        name={`transportModeCode`}
                        // placeholder={t('action.select', {
                        //     value: 'Transport Mode',
                        // })}
                        placeholder="Select here"
                        parentDivClassName="!mb-0"
                        options={CBService.sortDataDropdown()}
                        isClearable
                        useUppercaseLabel
                        defaultValue={
                            CBService.sortDataDropdown()?.length === 1
                                ? CBService.sortDataDropdown()
                                : undefined
                        }
                        disabled={CBService.sortDataDropdown()?.length === 1}
                    />
                    {/* hide for new feature */}
                    {/* <FormInput
                        label={t('bookings.shippers_ref')}
                        name={`loglinesBookingCreateBookingDetails.shippersRef`}
                                placeholder="Enter here"
                        placeholder={t('action.enter', {
                            value: 'Shipper's Ref',
                        })}
                        parentDivClassName="!mb-0"
                    /> */}
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.service_level')}
                        name={`loglinesBookingCreateBookingDetails.serviceLevelCode`}
                        // placeholder={t('action.select', {
                        //     value: t('bookings.service_level'),
                        // })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.serviceLevel}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.serviceLevelDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                        useUppercaseLabel
                    />
                    {CBService.modeTipe !== 'fcl' && (
                        <>
                            <FormInput
                                label="Outer Packs"
                                name={`loglinesBookingCreateBookingDetails.packs`}
                                placeholder="Enter here"
                                // placeholder={t('action.enter', {
                                //     value: t('bookings.packs'),
                                // })}
                                type="number"
                                parentDivClassName="!mb-0"
                                useUppercaseLabel
                                maxLength={36}
                                useArrow={false}
                            />
                            <FormDropdown
                                isSearchable
                                label={t('bookings.outer_pack_unit')}
                                name={`loglinesBookingCreateBookingDetails.outerPacksUnitCode`}
                                // placeholder={t('action.select', {
                                //     value: t('bookings.outer_pack_unit'),
                                // })}
                                placeholder="Select here"
                                parentDivClassName="!mb-0"
                                options={CBService.dropdownData.packsUnit}
                                isClearable
                                useUppercaseLabel
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .weightMeasure as IDropdownItem[]
                                }
                                placeholder="Enter here"
                                name={'specialField.weightMeasure'}
                                label={t('bookings.weight_measure')}
                                parentDivClassName=""
                                // placeholder={t('action.enter', {
                                //     value: t('bookings.weight_measure'),
                                // })}
                                useUppercaseLabel
                                maxLength={36}
                                useDecimalFormat
                            />
                            <FormMeasurementInput
                                options={
                                    CBService.dropdownData
                                        .volumeMeasure as IDropdownItem[]
                                }
                                placeholder="Enter here"
                                name={'specialField.volumeMeasure'}
                                label={t('bookings.volume_measure')}
                                parentDivClassName=""
                                // placeholder={t('action.enter', {
                                //     value: t('bookings.volume_measure'),
                                // })}
                                useUppercaseLabel
                                maxLength={36}
                                useDecimalFormat
                            />
                        </>
                    )}
                </div>

                {/* orgin & destination */}
                <div className="flex flex-col gap-3 w-full">
                    <OriginForm
                        formik={CBService.formikCreateBooking}
                        loadOptions={CBService.loadOptions}
                    />
                    <DestinationForm
                        formik={CBService.formikCreateBooking}
                        loadOptions={CBService.loadOptions}
                    />
                </div>

                {/* charges */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Charges</div>
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.incoterm')}
                        name={`loglinesBookingCreateBookingDetails.incoTermCode`}
                        // placeholder={t('action.select', {
                        //     value: t('bookings.incoterm'),
                        // })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.incOterm}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.incoTermDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                        useUppercaseLabel
                    />
                    <FormInput
                        label={t('bookings.goods_value')}
                        name={`loglinesBookingCreateBookingDetails.goodsValue`}
                        placeholder="Enter here"
                        // placeholder={t('action.enter', {
                        //     value: t('bookings.goods_value'),
                        // })}
                        parentDivClassName="!mb-0"
                        useDecimalFormat
                        useArrow={false}
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.goods_value_currency')}
                        name={`loglinesBookingCreateBookingDetails.goodsValueCurrencyCode`}
                        // placeholder={t('action.select', {
                        //     value: t('bookings.goods_value_currency'),
                        // })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.currency}
                        defaultValue={CBService.dropdownData.currency}
                        isAsync={true}
                        loadOptions={(e: string) =>
                            CBService.loadOptions({ currencyItem: e })
                        }
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.goodsValueCurrencyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                        useUppercaseLabel
                    />
                    <FormInput
                        label={t('bookings.additional_terms')}
                        name={`loglinesBookingCreateBookingDetails.additionalTerms`}
                        placeholder="Enter here"
                        // placeholder={t('action.enter', {
                        //     value: t('bookings.additional_terms'),
                        // })}
                        parentDivClassName="!mb-0 "
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormInput
                        label={t('bookings.insurance_value')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValue`}
                        placeholder="Enter here"
                        // placeholder={t('action.enter', {
                        //     value: t('bookings.insurance_value'),
                        // })}
                        parentDivClassName="!mb-0"
                        useDecimalFormat
                        useArrow={false}
                        useUppercaseLabel
                        maxLength={36}
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={t('bookings.insurance_currency')}
                        name={`loglinesBookingCreateBookingDetails.insuranceValueCurrencyCode`}
                        // placeholder={t('action.select', {
                        //     value: t('bookings.insurance_value_currency'),
                        // })}
                        parentDivClassName="!mb-0"
                        isAsync={true}
                        loadOptions={(e: string) =>
                            CBService.loadOptions({ insuranceCurrencyItem: e })
                        }
                        options={CBService.dropdownData.insuranceCurrency}
                        defaultValue={CBService.dropdownData.insuranceCurrency}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'loglinesBookingCreateBookingDetails.insuranceValueCurrencyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                        useUppercaseLabel
                    />
                    <FormDropdown
                        placeholder="Select here"
                        isSearchable
                        label={'Charges Apply'}
                        name={`chargesApplyCode`}
                        // placeholder={t('action.select', {
                        //     value: 'Charges Apply',
                        // })}
                        parentDivClassName="!mb-0"
                        options={CBService.dropdownData.chargesApply}
                        additionalOnClick={(data) => {
                            CBService.additionalClickSetDesc<ICreateBookingValidation>(
                                CBService.formikCreateBooking,
                                'chargesApplyDescription',
                                data as IDropdownItem,
                            )
                        }}
                        isClearable
                        useUppercaseLabel
                    />
                </div>

                {/* notes */}
                <div className="flex flex-col gap-3">
                    <div className="themes-text-gray-v9">Notes</div>
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.goodsDescription'
                        }
                        label={t('bookings.goods_description')}
                        parentDivClassName="h-[140px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.goods_description'),
                        })}
                        useUppercaseLabel
                        maxLength={1002} // 1002 max lenght === 1000 chracter
                    />
                    <FormTextarea
                        name={
                            'loglinesBookingCreateBookingDetails.marksAndNumbers'
                        }
                        label={t('bookings.marks_and_numbers')}
                        parentDivClassName="h-[220px]"
                        placeholder={t('action.enter', {
                            value: t('bookings.marks_and_numbers'),
                        })}
                        useUppercaseLabel
                        maxLength={1002} // 1002 max lenght === 1000 chracter
                    />
                </div>
            </div>
        </div>
    )
}

export default Details
