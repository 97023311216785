/* eslint-disable no-unused-vars */
import { useModal } from '@components//modal/modal.service'
import useOverlay from '@components/overlay/overlay.service'
import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { bookingConfigureColumns } from './bookings.static'
import {
    IBookings,
    IBookingsFilter,
    IBookinsStatusTotal,
    IFilterGenerateReport,
} from './bookings.interface'
import { ITableColumn } from '@components/table/table.interface'
import ConfigureColumnsModal from '@components/configure-columns-modal/configure-columns-modal.component'
import { getBookingsData } from 'client/ifb/repository/booking.repository'
import { useDispatch, useSelector } from 'react-redux'
import {
    bookingsDataSelector,
    bookingsFilterSelector,
    bookingsMetaSelector,
    bookingsStatusTotalSelector,
    bookingsTabStatusFilterSelector,
    setBookinsData,
    setFilter,
    setPageNumber,
    setSelectedStatus,
} from './bookings.slice'
import { isEmptyArray, useFormik } from 'formik'
import { Toast } from '@components/toast/toast.component'
import { initialFilter } from 'common/common.static'
import {
    bookingFilterInitialValue,
    IFormikBookingFilter,
} from 'client/ifb/form-validation/booking.validation'
import { getEnv } from 'common/common.service'

const useBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // overlay
    const filterOverlayService = useOverlay()
    const newBookingOverlayService = useOverlay()

    // modal
    const generateReportModalService = useModal()
    const ConfigureColumnsModalService = useModal()

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [tabItems, setTabItems] = useState<ITabItem[]>()
    const [headers, setHeaders] = useState<ITableColumn<IBookings>[]>([])
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [loadingGenerateReport, setLoadingGenerateReport] =
        useState<boolean>(false)
    const [filterGenerateReport, setFilterGenerateReport] =
        useState<IFilterGenerateReport>({
            filter: {},
            isGenerateReport: false,
        })

    // selectors
    const bookingsData = useSelector(bookingsDataSelector)
    const totalStatus = useSelector(bookingsStatusTotalSelector)
    const tabFilter = useSelector(bookingsTabStatusFilterSelector)
    const filter = useSelector(bookingsFilterSelector)
    const bookingsDataMeta = useSelector(bookingsMetaSelector)

    // variable
    const { pageNumber, pageSize, status, search } = filter
    const env = getEnv()

    // formiks
    const formikFilter = useFormik<IFormikBookingFilter>({
        initialValues: bookingFilterInitialValue,
        onSubmit: (values) => {
            const tmpFilter: IBookingsFilter = {
                ...filter,
                consignee: values.consignee,
                origin: values.origin,
                destination: values.destination,
                // consignor: values.consignor,
            }
            if (filterGenerateReport.isGenerateReport) {
                setFilterGenerateReport({
                    isGenerateReport: false,
                    filter: tmpFilter,
                })
                updateFormFilter()
            } else {
                dispatch(setFilter(tmpFilter))
            }
        },
    })

    useEffect(() => {
        dispatch(setFilter(initialFilter))
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        generateTabItems(totalStatus)
    }, [totalStatus])

    // get data
    useEffect(() => {
        if (isInitialized) loadData()
    }, [pageNumber, pageSize, status, tabFilter, search, isInitialized])

    // Get Data Function
    const loadData = async () => {
        const status = tabFilter.value

        let tmpFilter: IBookingsFilter = filter
        if (tabFilter?.childStatus) {
            const inProgressStat = tabFilter?.childStatus[0].value
            tmpFilter = { ...filter, status, inProgressStatus: inProgressStat }
        } else {
            tmpFilter = { ...filter, status }
        }
        try {
            setLoading(true)
            const actionResult = await getBookingsData(tmpFilter)
            dispatch(setBookinsData(actionResult))
            setLoading(false)
            if (isEmptyArray(actionResult.data)) {
                Toast({
                    header: 'Search Not Found!',
                    message:
                        "The keyword you've entered does not display any result.",
                    type: 'error',
                })
            }
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    // generate tab Items
    const generateTabItems = (status: IBookinsStatusTotal) => {
        const tabItems: ITabItem[] = [
            {
                label: 'All Bookings',
                labelTranslationCode: 'status.all_bookings',
                totalData: status.all,
                key: 'status',
                value: 'all',
                className: 'brand-v2',
            },
            {
                label: 'Draft',
                totalData: status.draft,
                key: 'status',
                value: 'draft',
                className: 'brand-v2',
            },
            {
                label: 'Submitted',
                totalData: status.submitted,
                key: 'status',
                value: 'submitted',
                className: 'brand-v2',
            },
            {
                label: 'Others',
                totalData: status.others,
                key: 'status',
                value: 'others',
                className: 'brand-v2',
            },
        ]
        setTabItems(tabItems)
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const handleSearch = (values: string) => {
        if (values.length >= 3) {
            dispatch(setFilter({ ...filter, search: values, pageNumber: 1 }))
        } else if (values.length <= 0)
            dispatch(setFilter({ ...filter, search: '', pageNumber: 1 }))
    }

    const setTabFilter = async (data: ITabItem) => {
        dispatch(setSelectedStatus(data))
        dispatch(setPageNumber(1))
    }

    const loadGenerateReportData = async (): Promise<IBookings[]> => {
        let generateData: IBookings[] = []
        setLoadingGenerateReport(true)
        try {
            const actionResult = await getBookingsData({
                ...filterGenerateReport,
                status: 'all',
                search: '',
                pageNumber: 1,
                pageSize: bookingsDataMeta?.total_Items || 50,
            })
            generateData = actionResult.data
        } catch (e) {
            console.error(e)
        }
        setLoadingGenerateReport(false)
        return generateData
    }

    const updateFormFilter = () => {
        const tmpFilter: IFormikBookingFilter = {
            consignee: filter.consignee || [],
            destination: filter.destination || [],
            origin: filter.origin || [],
            consignor: [],
        }
        formikFilter.setValues(tmpFilter)
    }

    // segment general
    const allMNodal = (
        <>
            <ConfigureColumnsModal<IBookings>
                modalService={ConfigureColumnsModalService}
                columns={bookingConfigureColumns}
                setHeaders={setHeaders}
            />
        </>
    )

    return {
        filterOverlayService,
        newBookingOverlayService,
        generateReportModalService,
        tabItems,
        tabFilter,
        loading,
        errorMessage,
        bookingsDataMeta,
        setPageData,
        setLoading,
        handleSearch,
        setErrorMessage,
        navigate,
        setTabItems,
        setTabFilter,
        bookingsData,
        allMNodal,
        ConfigureColumnsModalService,
        headers,
        formikFilter,
        updateFormFilter,
        loadingGenerateReport,
        loadGenerateReportData,
        env,
    }
}

export default useBookings
