import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IBookingEDocs,
    IBookingEvent,
    IBookingMilestone,
    IBookingNote,
    IBookingPackLines,
    IBookingRelatedOrder,
    IBookingsPackLinesTable,
} from './booking-details.interface'
import {
    ICreateBookingContainersValidation,
    ICreateBookingPackLinesValidationForm,
} from 'client/ifb/form-validation/booking-details.validation'
import {
    IBookingDetailsContainer,
    IBookingsAddress,
} from '../bookings.interface'

// segment Details
export const tabDetailHeaders: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Related Parties',
        key: 'status',
        value: 'relatedParties',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    {
        label: 'Container',
        key: 'status',
        value: 'container',
        className: 'brand',
    },
    // {
    //     label: 'eDocs',
    //     labelTranslationCode: 'tab_details.edocs',
    //     key: 'status',
    //     value: 'eDocs',
    //     className: 'brand',
    // },
    // {
    //     label: 'Messages',
    //     labelTranslationCode: 'tab_details.messages',
    //     key: 'status',
    //     value: 'messages',
    //     className: 'brand',
    // },
    // {
    //     label: 'Milestones',
    //     labelTranslationCode: 'tab_details.milestones',
    //     key: 'status',
    //     value: 'milestones',
    //     className: 'brand',
    // },
    // {
    //     label: 'Events',
    //     labelTranslationCode: 'tab_details.events',
    //     key: 'status',
    //     value: 'events',
    //     className: 'brand',
    // },
]

export const packLineHeaders: ISTColumn<IBookingPackLines>[] = [
    {
        label: 'Packs',
        labelTranslation: 'bookings.packs',
        accessor: 'packs',
    },
    {
        label: 'Packs Type',
        // labelTranslation: 'bookings.packs',
        accessor: 'packageType',
    },
    {
        label: 'Weight Measure',
        labelTranslation: 'bookings.weight_measure',
        accessor: 'weightMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.weightMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Goods Description',
        labelTranslation: 'bookings.goods_description',
        accessor: 'goodsDescription',
    },
    {
        label: 'Length Measure',
        labelTranslation: 'bookings.length_measure',
        accessor: 'lengthMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.lengthMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Width Measure',
        labelTranslation: 'bookings.width_measure',
        accessor: 'widthMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.widthMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Height Measure',
        labelTranslation: 'bookings.height_measure',
        accessor: 'heightMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.heightMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Volume Measure',
        labelTranslation: 'bookings.volume_measure',
        accessor: 'volumeMeasure',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData) {
                const { qty, unit } = rowData.volumeMeasure
                return (
                    <>
                        {qty} {unit}
                    </>
                )
            } else {
                return <></>
            }
        },
    },
    {
        label: 'Goods Description',
        // labelTranslation: 'bookings.packs',
        accessor: 'goodsDescription',
    },
]

export const packLineHeadersForm: ISTColumn<ICreateBookingPackLinesValidationForm>[] =
    [
        {
            label: 'Pieces',
            // labelTranslation: 'bookings.packs',
            accessor: 'packs',
        },
        {
            label: 'Packs Type',
            // labelTranslation: 'bookings.packs',
            accessor: 'packageTypeDescription',
        },
        {
            label: 'Weight Measure',
            labelTranslation: 'bookings.weight_measure',
            accessor: 'specialField',
            customBuild: (data, x, y, z, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.weightMeasure
                    return (
                        <>
                            {qty} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Goods Description',
            labelTranslation: 'bookings.goods_description',
            accessor: 'goodsDescription',
        },
        {
            label: 'Length Measure',
            labelTranslation: 'bookings.length_measure',
            accessor: 'specialField',
            customBuild: (data, x, y, z, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.lengthMeasure
                    return (
                        <>
                            {qty} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Width Measure',
            labelTranslation: 'bookings.width_measure',
            accessor: 'specialField',
            customBuild: (data, x, y, z, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.widthMeasure
                    return (
                        <>
                            {qty} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Height Measure',
            labelTranslation: 'bookings.height_measure',
            accessor: 'specialField',
            customBuild: (data, x, y, z, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.heightMeasure
                    return (
                        <>
                            {qty} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Volume Measure',
            labelTranslation: 'bookings.volume_measure',
            accessor: 'specialField',
            customBuild: (data, x, y, z, rowData) => {
                if (rowData?.specialField) {
                    const { qty, unit } = rowData.specialField.volumeMeasure
                    return (
                        <>
                            {qty} {unit}
                        </>
                    )
                } else {
                    return <></>
                }
            },
        },
        {
            label: 'Goods Description',
            // labelTranslation: 'bookings.packs',
            accessor: 'goodsDescription',
        },
    ]

export const packLineHeadersDetails: ISTColumn<IBookingsPackLinesTable>[] = [
    {
        label: 'Pieces',
        // labelTranslation: 'bookings.packs',
        accessor: 'packs',
    },
    {
        label: 'Pack Type',
        // labelTranslation: 'bookings.packs',
        accessor: 'packageTypeDescription',
    },
    {
        label: 'Length',
        // labelTranslation: 'bookings.length_measure',
        accessor: 'lengthMeasure',
    },
    {
        label: 'Height',
        // labelTranslation: 'bookings.height_measure',
        accessor: 'heightMeasure',
    },
    {
        label: 'Width',
        // labelTranslation: 'bookings.width_measure',
        accessor: 'widthMeasure',
    },
    {
        label: 'Total Weight',
        // labelTranslation: 'bookings.weight_measure',
        accessor: 'weightMeasureKG',
    },
    {
        label: 'Total Volume',
        // labelTranslation: 'bookings.volume_measure',
        accessor: 'volumeMeasureM3',
    },
    {
        label: 'Description',
        // labelTranslation: 'bookings.goods_description',
        accessor: 'goodsDescription',
    },
    {
        label: 'Marks and Numbers',
        accessor: 'marksAndNumbers',
    },
    {
        label: 'Line Price',
        accessor: 'numericLinePrice',
    },
    {
        label: 'Tariff Number',
        accessor: 'tariffNumber',
    },
]

export const containersHeaders: ISTColumn<ICreateBookingContainersValidation>[] =
    [
        {
            label: 'Type',
            // labelTranslation: 'bookings.packs',
            accessor: 'containerType',
            customBuild: (data, rowData) => (
                <div>{rowData?.containerType.code}</div>
            ),
        },
        {
            label: 'Count',
            // labelTranslation: 'bookings.packs',
            accessor: 'containerCount',
        },

        // {
        //     label: 'Container Number',
        //     // labelTranslation: 'bookings.packs',
        //     accessor: 'containerNumber',
        // },
    ]

export const bookingEDocsHeaders: ISTColumn<IBookingEDocs>[] = [
    {
        label: 'File Name',
        labelTranslation: 'bookings.fileName',
        accessor: 'fileName',
    },
    {
        label: 'Description',
        labelTranslation: 'bookings.description',
        accessor: 'description',
    },
    {
        label: 'Added',
        labelTranslation: 'bookings.added',
        accessor: 'added',
    },
]

export const bookingNoteHeaders: ISTColumn<IBookingNote>[] = [
    {
        accessor: 'noteText',
        label: 'Note Text',
        labelTranslation: 'bookings.note_text',
    },
    {
        accessor: 'description',
        label: 'Description',
        labelTranslation: 'bookings.description',
    },
]

export const bookingMilestoneHeaders: ISTColumn<IBookingMilestone>[] = [
    {
        label: 'Description',
        accessor: 'description',
        labelTranslation: 'bookings.description',
    },
    {
        label: 'Milestone Event',
        accessor: 'milestoneEvent',
        labelTranslation: 'bookings.milestone_event',
    },
    {
        label: 'Estimated',
        accessor: 'estimated',
        labelTranslation: 'bookings.estimated',
    },
    {
        label: 'Actual Start',
        accessor: 'actualStart',
        labelTranslation: 'bookings.actual_start',
    },
]

export const bookingEventHeaders: ISTColumn<IBookingEvent>[] = [
    {
        label: 'Event Time',
        accessor: 'eventTime',
        labelTranslation: 'bookings.event_time',
    },
    {
        label: 'Event',
        accessor: 'event',
    },
    {
        label: 'Reference',
        accessor: 'reference',
        labelTranslation: 'bookings.reference',
    },
]

export const bookingRelatedOrderHeaders: ISTColumn<IBookingRelatedOrder>[] = [
    {
        label: 'Order No.',
        accessor: 'orderNo',
        labelTranslation: 'bookings.order_no',
    },
    {
        label: 'Order Date',
        accessor: 'orderDate',
        labelTranslation: 'bookings.order_date',
    },
    {
        label: 'Booking Confirmation Ref',
        labelTranslation: 'bookings.booking_confirmation_ref',
        accessor: 'bookingConfirmationRef',
    },
    {
        label: 'Booking Confirmation Date',
        labelTranslation: 'bookings.booking_confirmation_date',
        accessor: 'bookingConfirmationDate',
    },
    {
        label: 'Order Status',
        labelTranslation: 'bookings.order_status',
        accessor: 'orderStatus',
    },
    {
        label: 'Ex Works Required By',
        labelTranslation: 'bookings.ex_works_required_by',
        accessor: 'exWorksRequiredBy',
    },
    {
        label: 'Required in Store',
        labelTranslation: 'bookings.required_in_store',
        accessor: 'requiredInStore',
    },
    {
        label: 'Transport Mode',
        labelTranslation: 'bookings.transport_mode',
        accessor: 'transportMode',
    },
    {
        label: 'Container Mode',
        labelTranslation: 'bookings.container_mode',
        accessor: 'containerMode',
    },
    {
        label: 'Goods Description',
        labelTranslation: 'bookings.goods_description',
        accessor: 'goodsDescription',
    },
]

export const containerHeaders: ISTColumn<IBookingDetailsContainer>[] = [
    {
        accessor: 'containerNumber',
        label: 'Container No.',
    },
    {
        accessor: 'containerTypeCode',
        label: 'Type',
    },
    {
        accessor: 'containerCount',
        label: 'Count',
    },
]

export const propertyRelatedPartiesComponent = [
    {
        title: 'Consignor Pickup Address',
        entity: 'consignorPickupAddress',
    },
    {
        title: 'Consignor Documentary Address',
        entity: 'consignorDocumentaryAddress',
    },
    {
        title: 'Consignee Delivery Address',
        entity: 'consigneePickupAddress',
    },
    {
        title: 'Consignee Documentary Address',
        entity: 'consigneeDocumentaryAddress',
    },
] as const

export const relatedPartiesComponentHeaders = [
    {
        label: 'Company Name',
        accessor: 'company',
    },
    {
        label: 'Address 1',
        accessor: 'address1',
    },
    {
        label: 'Address 2',
        accessor: 'address2',
    },
    {
        label: 'City',
        accessor: 'city',
    },
    {
        label: 'State',
        accessor: 'state',
        customBuild: (rowData: IBookingsAddress) => (
            <>{rowData?.state?.value || '-'}</>
        ),
    },
    {
        label: 'Postcode',
        accessor: 'postCode',
    },
    {
        label: 'Country',
        accessor: 'country',
        customBuild: (rowData: IBookingsAddress) => {
            const country =
                !rowData?.countryCode && !rowData?.countryCode
                    ? '-'
                    : `${rowData?.countryCode}, ${rowData.countryName}`
            return <>{country}</>
        },
    },
    {
        label: 'Contact Name',
        accessor: 'contactName',
    },
    {
        label: 'Phone Number',
        accessor: 'contactPhoneNumber',
    },
    {
        label: 'Mobile Number',
        accessor: 'contactMobileNumber',
    },
    {
        label: 'Email Address',
        accessor: 'contactEmailAddress',
    },
] as const
