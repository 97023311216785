import { IPieChartData } from '@components/rechart-component/pie/doughnut-chart.component'

const ColorPieChart = <T = {},>({
    items,
    content = 'tradeLanes',
    keyAdditionalData,
}: {
    items: IPieChartData<T>[]
    content?: string
    isShipmentFilterDataCategory?: boolean
    keyAdditionalData?: keyof T
}) => {
    const totalValue = items.reduce((acc, itemData) => acc + itemData.value, 0)

    return (
        <div className="col-span-6 lg:col-span-7 grid lg:grid-cols-2 gap-3 xl:gap-2 2xl:gap-2">
            {items.map((itemData, index) => {
                const { value, name, fill, textColor } = itemData
                const percentage = ((value / totalValue) * 100).toFixed(2)
                const formattedValue = value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })

                if (content === 'tradeLanes') {
                    const keyValueData =
                        itemData?.additionalValue &&
                        keyAdditionalData &&
                        itemData?.additionalValue[keyAdditionalData]
                    return (
                        <div
                            key={index}
                            className="flex flex-col text-size-XS leading-none"
                        >
                            {name && (
                                <div className="themes-text-gray-v9 pt-1 truncate ">
                                    {name}
                                </div>
                            )}
                            <div
                                style={{ background: `${fill}` }}
                                className={` h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex  py-3 `}
                            >
                                <div
                                    style={{ color: `${textColor}` }}
                                    className=" font-bold leading-[18px] truncate"
                                >
                                    {(keyValueData as string) || ''} ({' '}
                                    {percentage !== 'NaN' ? percentage : 0}%)
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div
                            key={index}
                            className="flex flex-col text-size-XS leading-none"
                        >
                            {name && (
                                <div className="themes-text-gray-v9 pt-1 truncate">
                                    {name}
                                </div>
                            )}
                            <div className="themes-text-gray-v9 text-size-XS font-bold">{`US$ ${formattedValue}`}</div>
                            <div
                                style={{ background: `${fill}` }}
                                className={` h-4 px-1 bg-red-100 rounded justify-center items-center gap-1 inline-flex  py-3`}
                            >
                                <div
                                    style={{ color: `${textColor}` }}
                                    className=" font-bold leading-[18px] truncate"
                                >
                                    {percentage !== 'NaN' ? percentage : 0}%
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default ColorPieChart
