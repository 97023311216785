import { FormikProvider } from 'formik'
import useControlTower from './control-tower.service'
import CardsDashboard from '@components/card-dashboard/card-dashboard.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormMonthYearPicker from '@components/form-month-year-picker/form-month-year-picker.component'
import ListButton from './components/list-button.component'
import Overview from './contents/overview.component'
import TradeLanes from './contents/trade-lanes.component'
import Customers from './contents/customers.component'
import Sales from './contents/sales.component'
import Finance from './contents/finance.component'
import ItemListInfo from '@components/item-list-info/item-list-info.component'
import {
    dataCategoryPillItems,
    filterDateType,
    overviewHistoryDevelpmentInfo,
    overviewHistorySplitRecord,
    shipmentEventPillItems,
} from './control-tower.static'
import InfoCardGroup from '@components/InfoCardGroup/Info-card-group.component'
import {
    BarChartStacked,
    JointLineScatterChart,
} from '@components/rechart-component'
import Pills from '@components/pills/pills.component'
import { filterContentByEnv } from 'common/common.service'

const ControlTower = () => {
    const useService = useControlTower()
    const getContent = () => {
        switch (useService.buttonContent) {
            case 'overview':
                return <Overview service={useService} />
            case 'tradeLanes':
                return <TradeLanes service={useService} />
            case 'customers':
                return <Customers service={useService} />
            case 'sales':
                return <Sales service={useService} />
            case 'finance':
                return <Finance service={useService} />
        }
    }

    return (
        <div className="flex flex-col gap-3 overflow-auto xl:overflow-hidden">
            <CardsDashboard className="!p-0 grid grid-cols-12">
                <FormikProvider value={useService.formikFilterCO}>
                    <ListButton
                        containerClassNames="grid grid-cols-12 gap-2 border-r p-2 col-span-6 xl:col-span-3"
                        listButton={useService.buttonContentCO.filter((btnCO) =>
                            filterContentByEnv(btnCO.devOnly),
                        )}
                    />
                    <div className="grid grid-cols-4 gap-2 border-r p-2 pl-1 col-span-6 xl:col-span-3">
                        <FormDropdown
                            isSearchable
                            label={'DATE TYPE'}
                            name={`ShipmentDateBy`}
                            placeholder="Select Here"
                            options={filterDateType}
                            parentDivClassName="col-span-4 !mb-0"
                        />
                        <FormMonthYearPicker
                            name="StartDate"
                            label="START MONTH"
                            placeholder="MM/YYYY"
                            parentDivClassName="col-span-2"
                            hideError
                        />
                        <FormMonthYearPicker
                            name="EndDate"
                            label="END MONTH"
                            placeholder="MM/YYYY"
                            parentDivClassName="col-span-2"
                            hideError
                        />
                    </div>
                    <div className="flex flex-col gap-2 border-r p-2 xl:pl-1 col-span-6 xl:col-span-3">
                        <Pills
                            items={dataCategoryPillItems}
                            onChange={(data) => {
                                useService.handlePillsDataCategory(data)
                            }}
                            // property class
                            isRounded={false}
                            containerClass="!w-full"
                            itemClass="!w-full flex justify-center !items-center"
                            variant="brand-v2"
                            disabled={
                                useService.buttonContent === 'finance'
                                    ? true
                                    : false
                            }
                        />
                        <div className="border-b"></div>
                        <Pills
                            items={shipmentEventPillItems}
                            onChange={(data) => {
                                useService.handlePillsShipmentEvent(data)
                            }}
                            // property class
                            isRounded={false}
                            containerClass="!w-full"
                            itemClass="!w-full flex justify-center !items-center"
                            variant="brand-v2"
                            useToggle
                        />
                    </div>
                    <div className="grid grid-cols-4 gap-2 p-2 pl-1 col-span-6 xl:col-span-3">
                        <FormDropdown
                            isSearchable
                            label={'COMPANY'}
                            name={`Company`}
                            placeholder="Select Here"
                            options={useService.filterDropdownOptions.company}
                            defaultValue={
                                useService.filterDropdownOptions.company
                            }
                            parentDivClassName="col-span-2 !mb-0"
                            isAsync={true}
                            loadOptions={(e: string) =>
                                useService.loadOptionsFilterDropdown({
                                    company: e,
                                })
                            }
                            isClearable
                        />
                        <FormDropdown
                            isSearchable
                            label={'TRANSPORT MODE'}
                            name={`TransportMode`}
                            placeholder="Select Here"
                            options={
                                useService.filterDropdownOptions.transportMode
                            }
                            parentDivClassName="col-span-2 !mb-0"
                            isClearable
                        />
                        <FormDropdown
                            isSearchable
                            label={'CONTAINER MODE'}
                            name={`ContainerMode`}
                            placeholder="Select Here"
                            options={
                                useService.filterDropdownOptions.containerMode
                            }
                            parentDivClassName="col-span-4 !mb-0"
                            isClearable
                        />
                    </div>
                </FormikProvider>
            </CardsDashboard>
            <div className="grid grid-cols-12 themes-bg-white rounded border h-full xl:h-[calc(100vh-14.5rem)]">
                {getContent()}

                <div className="col-span-12  w-full md:h-[600px] lg:h-full lg:col-span-6 xl:col-span-4">
                    <div className="p-2 h-1/2 w-full flex flex-col border-b">
                        <div className="flex justify-between">
                            <div className="font-bold text-size-S">
                                History Development
                            </div>
                            <ItemListInfo
                                itemListInfo={overviewHistoryDevelpmentInfo}
                            />
                        </div>
                        <InfoCardGroup
                            items={
                                useService.historyData.development.infoCardItems
                            }
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <JointLineScatterChart
                            data={useService.historyData.development.chartData}
                            tickItems={
                                useService.historyData.development.tickItems
                            }
                            isLoading={useService.loadingHistory.development}
                        />
                    </div>
                    <div className="p-2 h-1/2 w-full flex flex-col">
                        <div className="flex justify-between">
                            <div className="font-bold text-size-S">
                                History Split Record
                            </div>
                            <ItemListInfo
                                itemListInfo={overviewHistorySplitRecord}
                            />
                        </div>
                        <InfoCardGroup
                            items={
                                useService.historyData.splitRecord.infoCardItems
                            }
                            titleClass="!text-size-XS"
                            descClass="!text-[9px]"
                        />
                        <div className="flex-grow">
                            <BarChartStacked
                                data={
                                    useService.historyData.splitRecord.chartData
                                }
                                isLoading={
                                    useService.loadingHistory.splitRecord
                                }
                                renderTooltipValue={(data) => {
                                    let loadContent = ''
                                    const dataValue =
                                        useService.handleFormatValueNumber(
                                            data?.value || 0,
                                        )
                                    switch (data?.name) {
                                        case 'f1':
                                            loadContent = `Nomination: ${dataValue}`
                                            break
                                        case 'f2':
                                            loadContent = `Local Control: ${dataValue}`
                                            break
                                    }
                                    return loadContent
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ControlTower
