import {
    IBarChartStackedData,
    IBarchartStacktedDataItem,
    ILineChartItem,
} from '@components/rechart-component/rechart-interface'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    IFetchAverageTransitTime,
    IFetchAverageTransitTimeByMode,
    IFetchMilestoneGroupDayRange,
    IFetchPortCharges,
    IFetchShipmentsTransitTimeByPorts,
    IFetchTotalCostByModes,
    IFetchTotalCostByPorts,
    IFetchTransitTimeRouteGroup,
    IShipmentStatusData,
    shipmentPerformanceByPorts,
} from 'client/ifb/repository/interface/performance.interface'
import { RootState } from 'store'
import {
    IAdditionalValuePerfomance,
    IAvarageDaysDelayed,
    IAverageTransitTime,
    ILoadAvarageDaysDelayed,
    IMilestonePerformance,
    IMilestonePerformanceItemAdditionalValue,
    IMilestoneTransitTime,
    IMilestoneTransitTimeItemAdditionalValue,
    IShipmentPerformanceByPorts,
    IShipmentPerformanceStatus,
    IShipmentsTransitTimeByPorts,
    ITablePerformance,
} from './performances.interface'
import {
    numberToFixedInt,
    numberToCurrency,
    numberWithCommas,
} from 'common/common.service'
import {
    progressMultipleBarKeys,
    progressMultipleBarKeyTransitTime,
    transportModeMap,
} from './performances.static'

interface IPerformancesState {
    // segment performance
    shipmentPerformanceStatus: IShipmentPerformanceStatus
    shipmentPerformanceByPorts: IShipmentPerformanceByPorts
    avarageDaysDelayed: IAvarageDaysDelayed
    milestoneGroupDayRangeTable: ITablePerformance<
        IMilestonePerformance,
        IMilestonePerformanceItemAdditionalValue
    >

    // segment transit time
    averageTransitTime: IAverageTransitTime
    averageTransitTimeByMode: ILineChartItem[]
    shipmentsTransitTimeByPorts: IShipmentsTransitTimeByPorts
    tableTransitTime: ITablePerformance<
        IMilestoneTransitTime,
        IMilestoneTransitTimeItemAdditionalValue
    >

    // segment cost
    totalCostByModes: IShipmentPerformanceStatus<IAdditionalValuePerfomance>
    monthlyTotalCost: IBarChartStackedData
    portCharges: IShipmentPerformanceStatus<IAdditionalValuePerfomance>
    totalCostByPorts: IShipmentPerformanceByPorts
}

export const initialState: IPerformancesState = {
    // segment performance
    shipmentPerformanceStatus: {
        chartData: [
            {
                name: 'On Time',
                value: 0,
                fill: '#69BAA4',
                textColor: '#20384E',
            },
            {
                name: '1-2 days delay',
                value: 0,
                fill: '#FCDAE2',
                textColor: '#20384E',
            },
            {
                name: '3-7 days delay',
                value: 0,
                fill: '#F691A9',
                textColor: '#FFFFFF',
            },
            {
                name: '7-14 days delay',
                value: 0,
                fill: '#C9677F',
                textColor: '#FFFFFF',
            },
            {
                name: '15-31 days delay',
                value: 0,
                fill: '#A14158',
                textColor: '#FFFFFF',
            },
            {
                name: '> 1 month delay',
                value: 0,
                fill: '#670E23',
                textColor: '#FFFFFF',
            },
        ],
        infoCardGroupItems: [
            { value: '0', label: 'Total Shipments' },
            {
                value: '0',
                label: 'Total Shipments Delayed',
            },
        ],
    },
    shipmentPerformanceByPorts: {
        chartData: {
            key: [
                {
                    id: 'f1',
                    color: '#C9677F',
                },
                {
                    id: 'f2',
                    color: '#69BAA4',
                },
            ],
            data: [],
        },
        meta: {
            current_page: 1,
            last_page: 0,
            per_page: 12,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    avarageDaysDelayed: {
        chartData: {
            key: [
                {
                    id: 'line1',
                    color: '#f04770',
                    label: 'Selected Period',
                },
                {
                    id: 'line2',
                    color: '#8D99A5',
                    label: 'Previous Period',
                },
            ],
            line1: [],
            line2: [],
        },
        tickItems: [],
        infoCardGroupItems: [{ value: '', label: 'Average days delayed' }],
    },
    milestoneGroupDayRangeTable: {
        tableData: { data: [], keyItems: progressMultipleBarKeys },
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 50,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },

    // segment transit time
    averageTransitTime: {
        chartData: [
            {
                name: 'Planned Transit Time',
                number: 0,
                fill: '#D9DDE1',
            },
            {
                name: 'Actual Transit Time',
                number: 0,
                fill: '#8D99A5',
            },
        ],
        infoCardGroupItems: [
            {
                value: '0 Days',
                label: 'Average Planned Transit Time',
            },
            {
                value: '0 Days',
                label: 'Average Actual Transit Time',
            },
        ],
    },
    shipmentsTransitTimeByPorts: {
        chartData: {
            barColor1: '#D9DDE1',
            barColor2: '#8D99A5',
            item: [],
        },
        meta: {
            current_page: 1,
            last_page: 0,
            per_page: 12,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
    averageTransitTimeByMode: [
        {
            name: 'SEA',
            number: 0,
            fill: '#5280C6',
        },
        {
            name: 'AIR',
            number: 0,
            fill: '#76C6CC',
        },
        {
            name: 'ROAD',
            number: 0,
            fill: '#9D75AD',
        },
        {
            name: 'RAIL',
            number: 0,
            fill: '#E3CD9A',
        },
        {
            name: 'COURIER',
            number: 0,
            fill: '#C98167',
        },
    ],
    tableTransitTime: {
        tableData: {
            data: [],
            keyItems: progressMultipleBarKeyTransitTime,
        },
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 50,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },

    // segment cost
    totalCostByModes: {
        chartData: [
            {
                name: 'SEA',
                value: 0,
                fill: '#5280C6',
                textColor: '#FFFFFF',
            },
            {
                name: 'AIR',
                value: 0,
                fill: '#76C6CC',
                textColor: '#FFFFFF',
            },
            {
                name: 'ROAD',
                value: 0,
                fill: '#9D75AD',
                textColor: '#FFFFFF',
            },
            {
                name: 'RAIL',
                value: 0,
                fill: '#E3CD9A',
                textColor: '#FFFFFF',
            },
            {
                name: 'COURIER',
                value: 0,
                fill: '#C98167',
                textColor: '#FFFFFF',
            },
        ],
        infoCardGroupItems: [
            {
                value: '0',
                label: 'Total Shipments',
            },
            {
                value: 'PLN 0.00',
                label: 'Total Cost Overall',
            },
        ],
    },
    monthlyTotalCost: {
        key: [
            {
                id: 'f1',
                color: '#5280C6',
            },
            {
                id: 'f2',
                color: '#76C6CC',
            },
            {
                id: 'f3',
                color: '#9D75AD',
            },
            {
                id: 'f4',
                color: '#E3CD9A',
            },
            {
                id: 'f5',
                color: '#C98167',
            },
        ],
        data: [],
    },
    portCharges: {
        chartData: [
            {
                name: 'Loading Costs',
                value: 0,
                fill: '#D9DDE1',
                textColor: '#20384E',
                key: 'loading cost',
            },
            {
                name: 'Main Freight Costs',
                value: 0,
                fill: '#8D99A5',
                textColor: '#20384E',
                key: 'main freight',
            },
            {
                name: 'Discharge Costs',
                value: 0,
                fill: '#465A6C',
                textColor: '#FFFFFF',
                key: 'discharge cost',
            },
        ],
        infoCardGroupItems: [
            {
                value: '',
                label: 'Total Cost Overall',
            },
        ],
    },
    totalCostByPorts: {
        chartData: {
            key: [
                {
                    id: 'f1',
                    color: '#D9DDE1',
                },
                {
                    id: 'f2',
                    color: '#8D99A5',
                },
                {
                    id: 'f3',
                    color: '#465A6C',
                },
            ],
            data: [],
        },
        meta: {
            current_page: 0,
            last_page: 0,
            per_page: 12,
            total_page: 0,
            from: 0,
            to: 0,
            index_end: 0,
            index_start: 0,
        },
    },
}

const performancesSlice = createSlice({
    name: 'performancesIFB',
    initialState,
    reducers: {
        setLoadShipmentStatus: (
            state,
            action: PayloadAction<IShipmentStatusData>,
        ) => {
            const payload = action.payload
            const { chartData, infoCardGroupItems } =
                state.shipmentPerformanceStatus

            // mapping data
            chartData[0].value = payload.onTime.value
            chartData[1].value = payload.oneTwoDays.value
            chartData[2].value = payload.threeSevenDays.value
            chartData[3].value = payload.sevenFourteenDays.value
            chartData[4].value = payload.fourteenThirtyOneDays.value
            chartData[5].value = payload.morethanAMonth.value

            infoCardGroupItems[0].value = numberWithCommas(
                payload.totalShipment.toString(),
            )
            infoCardGroupItems[1].value = numberWithCommas(
                payload.totalDelayed.toString(),
            )
        },
        setShipmentPerformanceByPorts: (
            state,
            action: PayloadAction<shipmentPerformanceByPorts>,
        ) => {
            const { delayed, onTime } = action.payload
            const { meta, chartData } = state.shipmentPerformanceByPorts

            // Filter out items with empty port
            const filterDataWhenPortIsEmpity = onTime.filter(
                (item) => item.port,
            )

            // meta
            const total_Items = filterDataWhenPortIsEmpity.length
            const current_page = total_Items
                ? meta.current_page
                    ? meta.current_page
                    : 1
                : 0
            const index_start = (current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            state.shipmentPerformanceByPorts.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }

            // sort data
            const sortData = filterDataWhenPortIsEmpity
                .map((onTimeItem) => {
                    const delayedItem = delayed.find(
                        (item) => item.port === onTimeItem.port,
                    )

                    return {
                        name: onTimeItem.port,
                        f1: onTimeItem.count,
                        f2: delayedItem ? delayedItem.count : 0,
                        amt: 10,
                    }
                }) // set data
                .slice(index_start - 1, index_end) // display data based on pagination

            state.shipmentPerformanceByPorts.chartData = {
                ...chartData,
                data: sortData,
            }
        },
        setPageShipmentPerformanceByPorts: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.shipmentPerformanceByPorts.meta.current_page = action.payload
        },
        setAvarageDaysDelayed: (
            state,
            action: PayloadAction<ILoadAvarageDaysDelayed>,
        ) => {
            const { line1, line2, tickItems, totalAvarageDaysDelayed } =
                action.payload
            state.avarageDaysDelayed.chartData.line1 = line1
            state.avarageDaysDelayed.chartData.line2 = line2
            state.avarageDaysDelayed.tickItems = tickItems
            state.avarageDaysDelayed.infoCardGroupItems[0].value = `${totalAvarageDaysDelayed} Days`
        },
        setMilestoneGroupDayRangeTable: (
            state,
            action: PayloadAction<{
                data: IFetchMilestoneGroupDayRange[]
                filterTranportMode: string
                filterPageNumber: number
            }>,
        ) => {
            const { data, filterTranportMode, filterPageNumber } =
                action.payload
            const { meta } = state.milestoneGroupDayRangeTable

            const handlingNullValue = (value?: number) => (value ? value : 0)

            // sort data by transportMode
            const sortDataByTransportMode = data.filter(
                (item) => item.transportMode === filterTranportMode,
            )

            // meta
            const checkCurrentMeta = meta.current_page ? meta.current_page : 1
            const checkFilterPageNumber = filterPageNumber
                ? filterPageNumber
                : checkCurrentMeta
            const total_Items = sortDataByTransportMode.length
            const current_page = sortDataByTransportMode.length
                ? checkFilterPageNumber
                : 0
            const index_start = (current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            const updatedData = sortDataByTransportMode
                .map((item) => {
                    return {
                        transportMode: item.transportMode,
                        containerMode: item.containerMode,
                        portLoad: item.portOfOrigin,
                        portDischarge: item.portOfDestination,
                        shipmentCount: item.totalShipment,
                        totalDays: {
                            min: handlingNullValue(item.totalDays.min),
                            max: handlingNullValue(item.totalDays.max),
                        },
                        progressMultipleBarData: {
                            total: item.totalDays.max,
                            items: {
                                value: {
                                    loading: handlingNullValue(
                                        item.loadingDuration.max,
                                    ),
                                    transitTime: handlingNullValue(
                                        item.transitDuration.max,
                                    ),
                                    discharge: handlingNullValue(
                                        item.dischargeDuration.max,
                                    ),
                                },
                                additionalValue: {
                                    loading: {
                                        min: handlingNullValue(
                                            item.loadingDuration.min,
                                        ),
                                        max: handlingNullValue(
                                            item.loadingDuration.max,
                                        ),
                                    },
                                    transitTime: {
                                        min: handlingNullValue(
                                            item.transitDuration.min,
                                        ),
                                        max: handlingNullValue(
                                            item.transitDuration.max,
                                        ),
                                    },
                                    discharge: {
                                        min: handlingNullValue(
                                            item.dischargeDuration.min,
                                        ),
                                        max: handlingNullValue(
                                            item.dischargeDuration.max,
                                        ),
                                    },
                                },
                            },
                        },
                    }
                })
                .slice(index_start - 1, index_end)

            state.milestoneGroupDayRangeTable.tableData.data = updatedData
            state.milestoneGroupDayRangeTable.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }
        },
        setPageMilestoneGroupDayRangeTable: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.milestoneGroupDayRangeTable.meta.current_page = action.payload
        },
        resetMilestoneGroupDayRangeTableMetaData: (state) => {
            const initialMeta = {
                current_page: 0,
                last_page: 0,
                per_page: 50,
                total_page: 0,
                from: 0,
                to: 0,
                index_end: 0,
                index_start: 0,
            }

            state.milestoneGroupDayRangeTable.meta = initialMeta
        },

        // segment transit time
        setAverageTransitTime: (
            state,
            action: PayloadAction<IFetchAverageTransitTime>,
        ) => {
            const { totalPlaned, totalActual, avgPlanned, avgActual } =
                action.payload
            state.averageTransitTime.chartData[0].number = avgPlanned
                ? Math.round(avgPlanned)
                : 0
            state.averageTransitTime.chartData[1].number = avgActual
                ? Math.round(avgActual)
                : 0
            state.averageTransitTime.infoCardGroupItems[0].value = `${numberWithCommas(totalPlaned.toString())} Days`
            state.averageTransitTime.infoCardGroupItems[1].value = `${numberWithCommas(totalActual.toString())} Days`
        },
        setShipmentsTransitTimeByPorts: (
            state,
            action: PayloadAction<IFetchShipmentsTransitTimeByPorts[]>,
        ) => {
            const payloadData = action.payload
            const meta = state.shipmentsTransitTimeByPorts.meta

            // Filter out items with empty port
            const filterDataWhenPortIsEmpity = payloadData.filter(
                (item) => item.ports,
            )

            // meta
            const total_Items = filterDataWhenPortIsEmpity.length
            const current_page = total_Items
                ? meta.current_page
                    ? meta.current_page
                    : 1
                : 0
            const index_start = (current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            state.shipmentsTransitTimeByPorts.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }

            // sort data
            const sortData = filterDataWhenPortIsEmpity
                .map((item) => ({
                    name: item.ports,
                    uv: item.plannedTransitTime,
                    pv: item.actualTransitTime,
                    amt: 500,
                })) // set data
                .sort((a, b) => a.name.localeCompare(b.name)) // sort based alphabet
                .slice(index_start - 1, index_end) // display data based on pagination

            state.shipmentsTransitTimeByPorts.chartData.item = sortData
        },
        setPageShipmentsTransitTimeByPorts: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.shipmentsTransitTimeByPorts.meta.current_page = action.payload
        },
        setAverageTransitTimeByMode: (
            state,
            action: PayloadAction<IFetchAverageTransitTimeByMode[]>,
        ) => {
            const payloadData = action.payload
            const updatedData = state.averageTransitTimeByMode.map((item) => {
                const matchingTransport = payloadData.find(
                    (data) =>
                        transportModeMap[
                            data.mode as keyof typeof transportModeMap
                        ] === item.name,
                )

                return matchingTransport
                    ? {
                          ...item,
                          number: matchingTransport.shipmentCount,
                      }
                    : item
            })
            state.averageTransitTimeByMode = updatedData
        },
        setTableTransitTime: (
            state,
            action: PayloadAction<{
                data: IFetchTransitTimeRouteGroup[]
                filterTranportMode: string
                pageNumber: number
            }>,
        ) => {
            const { data, filterTranportMode, pageNumber } = action.payload
            const meta = state.tableTransitTime.meta

            const handlingNullValue = (value?: number) => (value ? value : 0)

            // sort data by transportMode
            const sortDataByTransportMode = data.filter(
                (item) => item.transportMode === filterTranportMode,
            )

            // meta
            const checkCurrentMeta = meta.current_page ? meta.current_page : 1
            const checkFilterPageNumber = pageNumber
                ? pageNumber
                : checkCurrentMeta
            const total_Items = sortDataByTransportMode.length
            const current_page = sortDataByTransportMode.length
                ? checkFilterPageNumber
                : 0
            const index_start = (current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            const last_page = Math.ceil(total_Items / meta.per_page)

            const updatedData = sortDataByTransportMode
                .map((item) => {
                    return {
                        transportMode: item.transportMode,
                        portLoad: item.portOfLoading,
                        portDischarge: item.portOfDischarge,
                        totalDays: {
                            min: handlingNullValue(item.transitMin),
                            max: handlingNullValue(item.transitMax),
                        },
                        shipmentCount: item.shipmentCount,
                        progressMultipleBarData: {
                            total: handlingNullValue(item.transitMax),
                            items: {
                                value: {
                                    actualTransitTime: handlingNullValue(
                                        item.transitMin,
                                    ),
                                },
                                additionalValue: {
                                    actualTransitTime: {
                                        min: handlingNullValue(item.transitMin),
                                        max: handlingNullValue(item.transitMax),
                                    },
                                },
                            },
                        },
                    }
                })
                .slice(index_start - 1, index_end)

            state.tableTransitTime.tableData.data = updatedData
            state.tableTransitTime.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }
        },
        setPageTableTransitTime: (state, action: PayloadAction<number>) => {
            state.tableTransitTime.meta.current_page = action.payload
        },
        resetTableTransitTimeMetaData: (state) => {
            const initialMeta = {
                current_page: 0,
                last_page: 0,
                per_page: 50,
                total_page: 0,
                from: 0,
                to: 0,
                index_end: 0,
                index_start: 0,
            }

            state.tableTransitTime.meta = initialMeta
        },

        // segment cost
        setMonthlyTotalCost: (
            state,
            action: PayloadAction<IBarchartStacktedDataItem[]>,
        ) => {
            const payloadData = action.payload
            state.monthlyTotalCost.data = payloadData
        },
        setTotalCostByModes: (
            state,
            action: PayloadAction<IFetchTotalCostByModes>,
        ) => {
            const { totalShipment, transports, overalAmount } = action.payload

            const updatedChartData = state.totalCostByModes.chartData.map(
                (chartItem) => {
                    const matchingTransport = transports.find(
                        (transport) =>
                            transportModeMap[
                                transport.mode as keyof typeof transportModeMap
                            ] === chartItem.name,
                    )

                    return matchingTransport
                        ? {
                              ...chartItem,
                              value: matchingTransport.amount,
                              additionalValue: {
                                  value: numberToCurrency(
                                      matchingTransport.amount,
                                  ),
                                  currency: 'PLN',
                              },
                          }
                        : {
                              ...chartItem,
                              additionalValue: {
                                  value: '0.00',
                                  currency: 'PLN',
                              },
                          }
                },
            )
            state.totalCostByModes.chartData = updatedChartData
            state.totalCostByModes.infoCardGroupItems[0].value =
                numberWithCommas(totalShipment.toString())
            state.totalCostByModes.infoCardGroupItems[1].value = `PLN ${numberToCurrency(overalAmount)}`
        },
        setPortCharges: (state, action: PayloadAction<IFetchPortCharges>) => {
            const { costs, overalAmount } = action.payload
            const updatedChartData: any = []

            state.portCharges.chartData.forEach((item) => {
                const findData = costs.find(
                    (cost) =>
                        cost.chargesGroup.toLocaleLowerCase() === item.key,
                )

                updatedChartData.push({
                    ...item,
                    value: numberToFixedInt(findData?.amount),
                    additionalValue: {
                        value: numberToCurrency(findData?.amount),
                        currency: 'PLN',
                    },
                })
            })

            state.portCharges.chartData = updatedChartData
            state.portCharges.infoCardGroupItems[0].value = `PLN ${numberToCurrency(
                overalAmount,
            )}`
        },
        setTotalCostByPorts: (
            state,
            action: PayloadAction<IFetchTotalCostByPorts[]>,
        ) => {
            const payloadData = action.payload
            const { meta, chartData } = state.totalCostByPorts

            const updatedData: IBarchartStacktedDataItem[] = []

            // sort data
            payloadData.forEach((item) => {
                item.costs.forEach((item2) => {
                    const existingPortData = updatedData.find(
                        (item3) => item3.name === item2.port,
                    )

                    if (existingPortData) {
                        if (item.chargesGroup === 'Loading Cost') {
                            existingPortData.f1 = item2.amount
                        }
                        if (item.chargesGroup === 'Main Freight') {
                            existingPortData.f2 = item2.amount
                        }
                        if (item.chargesGroup === 'Discharge Cost') {
                            existingPortData.f3 = item2.amount
                        }
                    } else {
                        updatedData.push({
                            name: item2.port,
                            ...(item.chargesGroup === 'Loading Cost' && {
                                f1: item2.amount,
                            }),
                            ...(item.chargesGroup === 'Main Freight' && {
                                f2: item2.amount,
                            }),
                            ...(item.chargesGroup === 'Discharge Cost' && {
                                f3: item2.amount,
                            }),
                            amt: 10,
                        })
                    }
                })
            })

            // meta
            const total_Items = updatedData.length
            const current_page = total_Items
                ? meta.current_page
                    ? meta.current_page
                    : 1
                : 0
            const last_page = Math.ceil(total_Items / meta.per_page)
            const index_start = (current_page - 1) * meta.per_page + 1
            const index_end = Math.min(
                index_start + meta.per_page - 1,
                total_Items,
            )
            state.totalCostByPorts.meta = {
                ...meta,
                last_page,
                index_start,
                index_end,
                total_Items,
                current_page,
            }

            state.totalCostByPorts.chartData = {
                ...chartData,
                data: updatedData
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .slice(index_start - 1, index_end),
            }
        },
        setPageTotalCostByPorts: (state, action: PayloadAction<number>) => {
            state.totalCostByPorts.meta.current_page = action.payload
        },
        setAllMetaCurrentPage: (state) => {
            state.shipmentPerformanceByPorts.meta.current_page = 1
            state.shipmentsTransitTimeByPorts.meta.current_page = 1
            state.totalCostByPorts.meta.current_page = 1
        },
    },
})

export const {
    setLoadShipmentStatus,
    setShipmentPerformanceByPorts,
    setPageShipmentPerformanceByPorts,
    setAvarageDaysDelayed,
    setAverageTransitTime,
    setMonthlyTotalCost,
    setShipmentsTransitTimeByPorts,
    setPageShipmentsTransitTimeByPorts,
    setAverageTransitTimeByMode,
    setTotalCostByModes,
    setPortCharges,
    setTotalCostByPorts,
    setPageTotalCostByPorts,
    setAllMetaCurrentPage,
    setMilestoneGroupDayRangeTable,
    setTableTransitTime,
    setPageMilestoneGroupDayRangeTable,
    resetMilestoneGroupDayRangeTableMetaData,
    setPageTableTransitTime,
    resetTableTransitTimeMetaData,
} = performancesSlice.actions

// segment performance
export const shipmentPerformanceStatusSelector = (state: RootState) =>
    state.performancesIFB.shipmentPerformanceStatus
export const avarageDaysDelayedSelector = (state: RootState) =>
    state.performancesIFB.avarageDaysDelayed
export const shipmentPerformanceByPortsSelector = (state: RootState) =>
    state.performancesIFB.shipmentPerformanceByPorts
export const milestoneGroupDayRangeTableSelector = (state: RootState) =>
    state.performancesIFB.milestoneGroupDayRangeTable

// segment transit time
export const averageTransitTimeSelector = (state: RootState) =>
    state.performancesIFB.averageTransitTime
export const shipmentsTransitTimeByPortsSelector = (state: RootState) =>
    state.performancesIFB.shipmentsTransitTimeByPorts
export const averageTransitTimeByModeSelector = (state: RootState) =>
    state.performancesIFB.averageTransitTimeByMode
export const tableTransitTimeSelector = (state: RootState) =>
    state.performancesIFB.tableTransitTime

// segment cost
export const totalCostByModesSelector = (state: RootState) =>
    state.performancesIFB.totalCostByModes
export const monthlyTotalCostSelector = (state: RootState) =>
    state.performancesIFB.monthlyTotalCost
export const portChargesSelector = (state: RootState) =>
    state.performancesIFB.portCharges
export const totalCostByPortsSelector = (state: RootState) =>
    state.performancesIFB.totalCostByPorts

export default performancesSlice.reducer
