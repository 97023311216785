import { useEffect, useState } from 'react'
import Alert from '@components/alert/alert.component'
import Button from '@components/button/button.component'
import FormInput from '@components/form-input/form-input.component'
import LinkText from '@components/link-text/link-text.component'
import { Form, Formik } from 'formik'
import { useLoginService } from '../login.service'
import { removeSessionMessage } from '../login.slice'
import { useDispatch } from 'react-redux'
import {
    useLoginValidation,
    IIFBLoginValidation,
} from 'client/common/form-validation/ifb-login.validation'
import { Toast } from '@components/toast/toast.component'
import { useTranslation } from 'react-i18next'
import { filterContentByEnv } from 'common/common.service'
const IFBLoginForm = ({
    forgotPasswordToggle,
    setIsLoginForm,
}: {
    forgotPasswordToggle: () => void
    setIsLoginForm: () => void
}) => {
    const { IFBLoginValidation, IFBLoginInitialValue } = useLoginValidation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { submitLogin, errorMessage, loading, client, user } =
        useLoginService()

    // session expired message
    const [sessionMessage] = useState(user.sessionExpiredMessage)
    const error = errorMessage ? errorMessage : sessionMessage
    useEffect(() => {
        dispatch(removeSessionMessage())
    }, [sessionMessage])

    return (
        <>
            <div className="text-[20px] text-[#20384E] font-bold mb-3">
                {t('login.login')}
            </div>
            {/* Form */}
            <Formik<IIFBLoginValidation>
                validationSchema={IFBLoginValidation}
                initialValues={IFBLoginInitialValue}
                onSubmit={(values, { setSubmitting }) => {
                    submitLogin({
                        ...values,
                        email: values.email,
                        OrganizationCode: 'OBO',
                    })
                    setSubmitting(false)
                }}
            >
                <Form>
                    <FormInput
                        name="email"
                        label={t('login.email_address')}
                        placeholder={t('login.email_address_placeholder')}
                        className="w-full"
                        maxLength={225}
                        useUppercaseLabel
                        data-testid="EMAIL_INPUT"
                    />
                    <FormInput
                        name="password"
                        label={t('login.password')}
                        type={'password'}
                        placeholder={t('login.password_placeholder')}
                        className="w-full"
                        client="IFB"
                        maxLength={225}
                        useUppercaseLabel
                        data-testid="PASSWORD_INPUT"
                        dataTestIdTogglePassoword="LOGIN_EYE_BUTTON"
                    />
                    <Alert
                        className={`mt-[40px] 
                        ${error ? '' : 'hidden'}`}
                        label={error}
                        type="danger"
                    />
                    <Button
                        type="submit"
                        label={t('login.login_button')}
                        className="w-full !justify-start mb-2"
                        variant="brand"
                        onClick={() => {}}
                        isLoading={loading}
                        useUpperCase={true}
                        data-testid="LOGIN_BUTTON"
                    />
                    <LinkText
                        label={t('login.forgot_password').toUpperCase()}
                        onClick={() => {
                            forgotPasswordToggle()
                        }}
                        classname="themes-text-brand-v5"
                        data-testid="FORGOT_PASSWORD_BUTTON"
                    />
                </Form>
            </Formik>
            {client === 'ifb' && filterContentByEnv(true) ? (
                <>
                    <div className="text-[20px] text-[#20384E] font-bold mb-3 my-10">
                        {t('login.track_shipment')}
                    </div>
                    <Formik
                        validationSchema={IFBLoginValidation}
                        initialValues={IFBLoginInitialValue}
                        onSubmit={() => {}}
                    >
                        <Form>
                            <FormInput
                                name="TrackingNumber"
                                label={t('login.tracking_number')}
                                placeholder={t(
                                    'login.tracking_number_placeholder',
                                )}
                                className="w-full"
                                required={true}
                                useUppercaseLabel
                                data-testid="TRACKING_NUMBER_INPUT"
                            />
                            <Button
                                useUpperCase={true}
                                type="submit"
                                label={t('login.track_button')}
                                className="w-full !justify-start"
                                variant="brand"
                                onClick={() => {
                                    setIsLoginForm()
                                    Toast({
                                        header: 'Information',
                                        message:
                                            'This feature is under development',
                                        type: 'info',
                                    })
                                }}
                                data-testid="SEARCH_TRACKING_NUMBER"
                            />
                        </Form>
                    </Formik>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
export default IFBLoginForm
