import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserAuth } from 'client/common/repository/interface/user-auth.interface'
import { IUserDefaultData } from 'client/ifb/pages/manage-users/manage-users.interface'
import { RootState, store } from 'store'
export const initialState: IUserAuth = {
    id: '',
    portalLogin: '',
    role: '',
    token: '',
    expires_in: '',
    organizationCode: '',
    email: '',
    userDefaultData: {
        branch: '',
        city: '',
        state: '',
        postcode: '',
        country: {
            code: '',
            name: '',
        },
        currency: {
            code: '',
            description: '',
        },
    },
}
const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginData(state, action: PayloadAction<IUserAuth>) {
            return {
                ...state,
                ...{
                    ...action.payload,
                    sessionExpiredMessage: undefined,
                },
            }
        },
        setLoginDetailData(state, action: PayloadAction<IUserDefaultData>) {
            state.userDefaultData = action.payload
        },
        logoutAction() {
            return initialState
        },
        removeSessionAction() {
            return {
                ...initialState,
                sessionExpiredMessage: 'Session expired, please login again',
            }
        },
        removeSessionMessage(state) {
            return {
                ...state,
                sessionExpiredMessage: undefined,
            }
        },
    },
})

export const removeSession = () => {
    store.dispatch(removeSessionAction())
}

export const userDataSelector = (state: RootState) => state.login || {}
export const {
    setLoginData,
    logoutAction,
    removeSessionAction,
    removeSessionMessage,
    setLoginDetailData,
} = loginSlice.actions
export default loginSlice.reducer
