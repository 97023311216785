/* eslint-disable no-unused-vars */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
export function useDatePicker(_a) {
    var selectedRange = _a.selectedRange, selectedDate = _a.selectedDate;
    // state
    var _b = useState(false), showDatePicker = _b[0], setShowDatePicker = _b[1];
    var _c = useState(new Date()), date = _c[0], setDate = _c[1];
    var _d = useState({
        from: undefined,
        to: undefined,
    }), rangeTemporaryValue = _d[0], setRangeTemporaryValue = _d[1];
    // variable
    var dropdownRef = useRef(null);
    // fucntion event listener
    var handleClickOutside = function (event) {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target)) {
            setShowDatePicker(false);
        }
    };
    // fucntion event listener
    var handleEscapeKey = function (event) {
        if (event.key === 'Escape') {
            setShowDatePicker(false);
        }
    };
    // function set feature date
    function isFutureDate(date) {
        return moment(date).isAfter(moment());
    }
    // function set past date
    function isPastDate(date) {
        return moment(date).isBefore(moment());
    }
    // change date
    var changeDateHandler = function (date) {
        setDate(date);
    };
    // handle when range value date 0
    var adjustDateRange = function (range) {
        if (!range) {
            setRangeTemporaryValue({ from: undefined, to: undefined });
            return false;
        }
        if (!range.to) {
            setRangeTemporaryValue({ from: range.from, to: undefined });
            return false;
        }
        setRangeTemporaryValue({ from: range.from, to: range.to });
        return { from: range.from, to: range.to };
    };
    // useEffects
    useEffect(function () {
        // handle event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKey);
    }, []);
    // handle close selectedRange selected date
    useEffect(function () {
        if ((selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.to) && (selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from))
            setShowDatePicker(false);
        if (selectedDate)
            setShowDatePicker(false);
    }, [selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.to, selectedRange === null || selectedRange === void 0 ? void 0 : selectedRange.from, selectedDate]);
    useEffect(function () {
        setRangeTemporaryValue(selectedRange);
    }, [selectedRange]);
    return {
        isFutureDate: isFutureDate,
        isPastDate: isPastDate,
        setShowDatePicker: setShowDatePicker,
        changeDateHandler: changeDateHandler,
        showDatePicker: showDatePicker,
        dropdownRef: dropdownRef,
        rangeTemporaryValue: rangeTemporaryValue,
        date: date,
        setDate: setDate,
        adjustDateRange: adjustDateRange,
    };
}
