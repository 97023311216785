import { IResponseData } from 'common/common.interface'
import { initialResponseData } from 'common/common.static'
import { fetch } from 'common/common.service'
import containerTypeJson from './data-sources/container-type.json'
import { ICointainerTypeData } from './interface/loglinesMetaData.interface'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'

export const fetchLoglinesMetaData = async <T = {}, U = {}>(
    endpoint: string,
    field?: U,
) => {
    try {
        const response = await fetch<IResponseData<T>, null>({
            endpoint: endpoint,
            ...(field && { params: field }),
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<T> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const loadTransportModeDropdown = (containerModeCodeType: string) => {
    const containerMode: {
        value: string
        label: string
        transportModeCodeType: string[]
    }[] = [
        {
            value: 'all',
            label: 'All Modes',
            transportModeCodeType: ['all'],
        },
        {
            value: 'AIR',
            label: 'AIR - Air Freight',
            transportModeCodeType: ['all', 'lse'],
        },
        {
            value: 'SEA',
            label: 'SEA - Sea Freight',
            transportModeCodeType: ['all', 'fcl', 'lcl'],
        },
        {
            value: 'ROA',
            label: 'ROA - Road Freight',
            transportModeCodeType: ['all', 'ftl,', 'fcl', 'lcl'],
        },
        {
            value: 'RAI',
            label: 'RAI - Rail Freight',
            transportModeCodeType: ['all', 'fcl', 'lcl'],
        },
        {
            value: 'COU',
            label: 'COU - Courier',
            transportModeCodeType: ['all', 'obc'],
        },
    ]

    if (!containerModeCodeType) {
        return containerMode.map((item) => ({
            value: item.value,
            label: item.label,
        }))
    }

    return containerMode
        .filter((item) =>
            item.transportModeCodeType.some((code) =>
                code
                    .split(',')
                    .includes(containerModeCodeType?.toLocaleLowerCase()),
            ),
        )
        ?.map((item) => ({
            value: item.value,
            label: item.label,
        }))
}

export const loadContainerModeDropdown = (transportModeCodeType: string) => {
    const containerMode: {
        value: string
        label: string
        transportModeCodeType: string[]
    }[] = [
        {
            value: 'all',
            label: 'All Modes',
            transportModeCodeType: ['all'],
        },
        {
            value: 'LSE',
            label: 'LSE - Loose',
            transportModeCodeType: ['all', 'air'],
        },
        {
            value: 'FTL',
            label: 'FTL - Full Truck Load',
            transportModeCodeType: ['all', 'roa'],
        },
        {
            value: 'FCL',
            label: 'FCL - Full Container Load',
            transportModeCodeType: ['all', 'sea', 'roa', 'rai'],
        },
        {
            value: 'LCL',
            label: 'LCL - Less Container Load',
            transportModeCodeType: ['all', 'sea', 'roa', 'rai'],
        },
        {
            value: 'OBC',
            label: 'OBC - On Board Courier',
            transportModeCodeType: ['all', 'cou'],
        },
    ]
    if (!transportModeCodeType) {
        return containerMode.map((item) => ({
            value: item.value,
            label: item.label,
        }))
    }

    return containerMode
        ?.filter((item) =>
            item.transportModeCodeType.some((code) =>
                code
                    .split(',')
                    .includes(transportModeCodeType?.toLocaleLowerCase()),
            ),
        )
        ?.map((item) => ({
            value: item.value,
            label: item.label,
        }))
}

export const loadContainerTypeDropdown = (
    transprotModeCode: string[],
): IDropdownItem<ICointainerTypeData>[] => {
    const containerTypeDataJson =
        containerTypeJson as unknown[] as ICointainerTypeData[]
    let filterContainerData

    if (!transprotModeCode) {
        filterContainerData = containerTypeDataJson
    } else {
        filterContainerData = containerTypeDataJson.filter((item) =>
            transprotModeCode
                .map((mode) => mode.toUpperCase())
                .includes(item.mode.toUpperCase()),
        )
    }

    const containerDropdown: IDropdownItem<ICointainerTypeData>[] =
        filterContainerData.map((data: ICointainerTypeData) => {
            const dropdown: IDropdownItem<ICointainerTypeData> = {
                label: data.code + ' - ' + data.description,
                value: data.code,
                additionalData: {
                    ...data,
                },
            }
            return dropdown
        })

    return containerDropdown
}
