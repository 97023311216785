import FormDatePicker from '@components/form-date-picker/form-date-picker.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { useTranslation } from 'react-i18next'
import { FormikContextType } from 'formik'
import { bookingsDataDropdownSelector } from '../bookings.slice'
import { useSelector } from 'react-redux'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ICreateBookingValidation } from 'client/ifb/form-validation/create-booking.validation'

const OriginForm = ({
    formik,
    loadOptions,
}: {
    formik?: FormikContextType<ICreateBookingValidation>
    loadOptions?: (...args: any[]) => void
}) => {
    const { t } = useTranslation()
    const dropdownData = useSelector(bookingsDataDropdownSelector)

    return (
        <div>
            <div className="themes-text-gray-v9">{t('bookings.origin')}</div>
            <div className="flex flex-col gap-3 pt-3">
                <FormDropdown
                    isSearchable={true}
                    isClearable={true}
                    label={t('bookings.origin_port')}
                    name={`originPortCode`}
                    placeholder="Select here"
                    // placeholder={t('action.enter', {
                    //     value: t('bookings.origin_port'),
                    // })}
                    defaultValue={dropdownData.origins}
                    options={dropdownData.origins}
                    parentDivClassName="!mb-0"
                    isAsync={true}
                    loadOptions={(e: string) =>
                        // loadOptions && loadOptions({ originsItem: e })
                        loadOptions && loadOptions({ originsItem: e })
                    }
                    additionalOnClick={(data) => {
                        if (!formik) return
                        const _data = data as IDropdownItem
                        const label = _data?.label as string
                        const value = label?.split('-')[1].trim()
                        formik?.setFieldValue(`originPortName`, value)
                    }}
                    useUppercaseLabel
                />
                <div className="relative">
                    <FormDatePicker
                        name={'pickupRequiredBy'}
                        isRange={false}
                        label="Pickup Required By"
                        placeholder="Select here"
                        useUppercaseLabel
                    />
                </div>
                <div className="relative">
                    <FormDatePicker
                        name={'estimatedPickup'}
                        isRange={false}
                        label="Estimated Pickup"
                        placeholder="Select here"
                        useUppercaseLabel
                    />
                </div>
                <FormDropdown
                    isSearchable
                    label={'Pickup Equipment Needed'}
                    name={`pickupEquipmentNeededCode`}
                    placeholder="Select here"
                    // placeholder={t('action.enter', {
                    //     value: 'Pickup Equipment Needed',
                    // })}
                    options={dropdownData.pickupEquipmentNeeded}
                    parentDivClassName="!mb-0"
                    additionalOnClick={(data) => {
                        if (!formik) return
                        const _data = data as IDropdownItem
                        const label = _data?.label as string
                        const value = label?.split('-')[1].trim()
                        formik?.setFieldValue(
                            `pickupEquipmentNeededDescription`,
                            value,
                        )
                    }}
                    isClearable
                    useUppercaseLabel
                />
            </div>
        </div>
    )
}

export default OriginForm
