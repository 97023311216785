import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useModal } from '@components//modal/modal.service'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
    getManageUserID,
    getManageUserIDLog,
    getUserDefaultData,
} from 'client/ifb/repository/manage-user.repository'
import {
    manageUserDataLogSelector,
    manageUserDetailSelector,
    manageUserLogFilterSelector,
    manageUserLogMetaSelector,
    setLogPageNumber,
    setManageUserDataDetail,
    setManageUserDataLog,
    userDefaultSelector,
} from '../manage-users.slice'
import { Toast } from '@components/toast/toast.component'
import { changeUserStatus } from 'client/common/repository/user.repository'

const useManageUserDetails = () => {
    // initial
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    // state
    const manageUserDataLog = useSelector(manageUserDataLogSelector)
    const manageUserDetail = useSelector(manageUserDetailSelector)
    const manageUserDetailMeta = useSelector(manageUserLogMetaSelector)
    const manageUserDataLogFilter = useSelector(manageUserLogFilterSelector)
    const userDefault = useSelector(userDefaultSelector);
    const [loading, setLoading] = useState<boolean>(false)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    // variable
    const { pageNumber, pageSize, search } = manageUserDataLogFilter

    // modal service
    const modalConfirmationService = useModal()

    function openEmailClient() {
        var emailAddress = 'test@gmail.com' // Replace with the desired email address
        var subject = 'Subject'
        var body = '--- Body ---'

        var mailtoUrl =
            'mailto:' +
            encodeURIComponent(emailAddress) +
            '?subject=' +
            encodeURIComponent(subject) +
            '&body=' +
            encodeURIComponent(body)

        window.open(mailtoUrl)
    }

    useEffect(() => {
        if (!isInitialized) loadData()
        loadLogData()
    }, [pageNumber, pageSize, search])

    // Get Data Function
    const loadData = async () => {
        try {
            setLoading(true)
            const actionResultDetail = await getManageUserID({ id })
            dispatch(setManageUserDataDetail(actionResultDetail.data))
            setLoading(false)
            setIsInitialized(true)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const loadLogData = async () => {
        try {
            setLoading(true)
            const actionResult = await getManageUserIDLog({
                id,
                ...manageUserDataLogFilter,
            })
            dispatch(setManageUserDataLog(actionResult))
            dispatch(getUserDefaultData({ staffId: id as string }))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            setErrorMessage(errorMessage)
        }
    }

    const setPageData = (pageNumber: number) => {
        dispatch(setLogPageNumber(pageNumber))
    }

    async function changeStatus() {
        try {
            await changeUserStatus(
                manageUserDetail?.id ?? '',
                !manageUserDetail.isActive,
            )
            loadData()
            const message = manageUserDetail.isActive
                ? 'User has Successfully Suspended!'
                : 'User has Successfully Activated!'
            Toast({
                header: message,
                message: '',
                type: 'success',
            })
        } catch (error: any) {
            const errorMessage =
                error.title ?? error.message ?? 'Failed to suspend user'
            Toast({
                header: 'Failed!',
                message: errorMessage,
                type: 'error',
            })
        }
    }

    return {
        id,
        loading,
        errorMessage,
        manageUserDataLog,
        manageUserDetailMeta,
        manageUserDetail,
        modalConfirmationService,
        navigate,
        openEmailClient,
        changeStatus,
        setPageData,
        userDefault,
        loadUserDetailsData: loadData
    }
}

export default useManageUserDetails
