import Button from '@components/button/button.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { FormikContextType, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import XLSX from 'xlsx-js-style'
import { Toast } from '@components/toast/toast.component'
import moment from 'moment'

export interface IGenerateReportHeaders<T> {
    label: string
    accessor: keyof T
    customValue?: (rowData: T) => string
    widthCol: number
}

const GenerateReportModal = <T, U>({
    modalService,
    formik,
    filterFormComponent,
    module,
    loadGenerateReportData,
    headers,
    updateFormFilter,
}: {
    loadGenerateReportData: () => Promise<T[]>
    headers: IGenerateReportHeaders<T>[]
    modalService: IUseModal
    formik: FormikContextType<U>
    filterFormComponent: JSX.Element
    module: string
    updateFormFilter: () => void
}) => {
    const { t } = useTranslation()

    const exportXlxs = async () => {
        const data = await loadGenerateReportData()

        if (!data || !data.length) {
            Toast({
                header: 'Failed',
                message: 'Filtered data is empty',
                type: 'error',
            })
            return
        }

        const wb = XLSX.utils.book_new()

        // Add the column headers
        const headerReport = headers.map((header) => ({
            v: header.label,
            t: 's',
            s: {
                fill: {
                    fgColor: {
                        rgb: 'd8e7ff',
                    },
                },
                font: { bold: true, color: { rgb: '000000' } },
                alignment: { vertical: 'center', horizontal: 'center' },
            },
        }))

        // Map the data to the corresponding columns
        const mapData = data.map((item, index) =>
            headers.map((header) => {
                let value = header.customValue
                    ? header.customValue(item)
                    : item[header.accessor]

                if (!value) {
                    value = '-'
                }

                return {
                    v: value,
                    t: typeof value === 'number' ? 'n' : 's',
                }
            }),
        )

        // Combine report info and data into rows
        const rows = [headerReport, ...mapData]

        // Create a worksheet from the rows
        const ws = XLSX.utils.aoa_to_sheet(rows)

        // Set column widths
        ws['!cols'] = headers.map((header) => ({ wch: header.widthCol }))

        // Set row height for header row (set to 50px for the header)
        ws['!rows'] = [
            { hpx: 30 }, // Header row height in pixels
            ...new Array(rows.length - 1).fill({ hpx: 20 }), // Default height for other rows
        ]

        // Append worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Goods Receive Report')

        // Write the Excel file
        XLSX.writeFile(
            wb,
            `${module}_${moment().format('DD-MM-YYYY_HH-mm-ss')}.xlsx`,
        )

        // Notify success
        Toast({
            header: 'Success',
            message: 'The report was generated successfully',
            type: 'success',
        })
    }

    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3 !p-0">
            <FormikProvider value={formik}>
                <div className="">
                    <div className="border-b flex justify-between items-center p-4">
                        <div className="font-bold text-size-M">
                            Generate Report {module}
                        </div>
                        <Button
                            className="!border-0 flex items-center !h-[24px]"
                            icon="ri-close-fill font-bold"
                            onClick={() => {
                                modalService.closeModalHandling()
                                updateFormFilter()
                            }}
                        />
                    </div>
                    {filterFormComponent}
                    <div className="p-4 flex gap-4 border-t">
                        <Button
                            onClick={async () => {
                                formik.resetForm()
                            }}
                            label={t('action.reset')}
                            useUpperCase={true}
                            className="w-1/4"
                            variant="red-inverse"
                        />
                        <Button
                            onClick={() => {
                                exportXlxs()
                                modalService.closeModalHandling()
                                updateFormFilter()
                            }}
                            label={'Generate Report'}
                            useUpperCase={true}
                            className="w-3/4"
                            variant="brand-v2-inverse"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default GenerateReportModal
