var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ZAxis, } from 'recharts';
import BarLoading from '../loading/bar-loading.component';
import { numberWithCommas } from '@services/common.service';
var JointLineScatterChart = function (_a) {
    var data = _a.data, tickItems = _a.tickItems, isLoading = _a.isLoading;
    var keyItems = data.key;
    var _b = useState({
        tick: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        domain: [1, 12],
    }), XAxisProps = _b[0], setXAxisProps = _b[1];
    var monthFormatter = function (tick) {
        var months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return tickItems ? tickItems[tick - 1] : months[tick - 1];
    };
    var renderThick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _tick, domain;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(tickItems === null || tickItems === void 0 ? void 0 : tickItems.length))
                        return [2 /*return*/];
                    return [4 /*yield*/, Array.from({ length: tickItems === null || tickItems === void 0 ? void 0 : tickItems.length }, function (_, idx) { return idx + 1; })];
                case 1:
                    _tick = _a.sent();
                    domain = [1, tickItems === null || tickItems === void 0 ? void 0 : tickItems.length];
                    setXAxisProps({
                        tick: _tick,
                        domain: domain,
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var CustomTick = function (_a) {
        var x = _a.x, y = _a.y, payload = _a.payload;
        var matchingData = data.line1.find(function (item) { return item.x === payload.value; }) ||
            data.line2.find(function (item) { return item.x === payload.value; });
        return (_jsxs("g", __assign({ transform: "translate(".concat(x, ",").concat(y, ")") }, { children: [_jsx("text", __assign({ x: 0, y: 0, dy: 16, textAnchor: "middle", fill: "#20384E", fontSize: "12px" }, { children: monthFormatter(payload.value) })), _jsx("text", __assign({ x: 0, y: 0, dy: 30, textAnchor: "middle", fill: "#C0C7CD", fontSize: "12px" }, { children: matchingData === null || matchingData === void 0 ? void 0 : matchingData.secName }))] })));
    };
    var CustomTooltip = function (_a) {
        var active = _a.active, payload = _a.payload;
        // find value same y
        var findSameY = function (arr) {
            var countMap = {};
            arr.forEach(function (line) {
                countMap[line.y] = (countMap[line.y] || 0) + 1;
            });
            return arr.filter(function (line) { return countMap[line.y] > 1; });
        };
        if (active && payload && payload.length) {
            var findkeyItems = keyItems.find(function (item) { return item.id === payload[0].payload.keyId; });
            var line1 = data.line1[payload[0].payload.x - 1];
            var line2 = data.line2[payload[0].payload.x - 1];
            var line3 = data.line3 && data.line3[payload[0].payload.x - 1];
            // merge line and filter when line data is undifinedw
            var linesData = [line1, line2, line3].filter(function (line) { return line !== undefined; });
            var sameData = findSameY(linesData);
            return (_jsx("div", __assign({ className: "custom-tooltip p-2 rounded-md themes-bg-gray-v9 opacity-80 themes-text-white flex flex-col gap-2 max-w-[200px] " }, { children: sameData.length ? (sameData.map(function (item) {
                    var findkeyItems2 = keyItems.find(function (item2) { return item2.id === item.keyId; });
                    return (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", { className: "min-w-[20px] max-w-[20px] w-[20px] h-[20px] bg-white rounded-md mr-2", style: {
                                    backgroundColor: findkeyItems2 === null || findkeyItems2 === void 0 ? void 0 : findkeyItems2.color,
                                } }), _jsx("p", __assign({ className: "label 2xl:text-size-S text-size-XS" }, { children: "".concat(findkeyItems2 === null || findkeyItems2 === void 0 ? void 0 : findkeyItems2.label, ": ").concat(numberWithCommas(item.y.toString())) }))] })));
                })) : (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", { className: "min-w-[20px] max-w-[20px] w-[20px] h-[20px] bg-white rounded-md mr-2", style: { backgroundColor: findkeyItems === null || findkeyItems === void 0 ? void 0 : findkeyItems.color } }), _jsx("p", __assign({ className: "label 2xl:text-size-S text-size-XS" }, { children: "".concat(findkeyItems === null || findkeyItems === void 0 ? void 0 : findkeyItems.label, ": ").concat(numberWithCommas(payload[0].payload.y)) }))] }))) })));
        }
        return null;
    };
    useEffect(function () {
        renderThick();
    }, [tickItems]);
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(BarLoading, {})) : (_jsx(ResponsiveContainer, __assign({ width: "100%", height: "100%" }, { children: _jsxs(ScatterChart, __assign({ margin: {
                    top: 20,
                    right: 15,
                    left: -26,
                    bottom: -15,
                }, className: "text-size-XS" }, { children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { type: "number", dataKey: "x", domain: XAxisProps.domain, height: 60, tickFormatter: monthFormatter, ticks: XAxisProps.tick, tickLine: false, tick: _jsx(CustomTick, {}), interval: 0 }), _jsx(YAxis, { type: "number", dataKey: "y", name: "value1" }), _jsx(ZAxis, { type: "number", range: [180] }), _jsx(Tooltip, { content: _jsx(CustomTooltip, {}) }), keyItems.map(function (item, idx) { return (_jsx(Scatter, { name: item.id, data: data[item.id], fill: item.color, line: true }, "data-chart-item-".concat(idx))); })] })) }))) }));
};
export default JointLineScatterChart;
