import { ITabItem } from '@components/tab/tab.interface'
import { initialMeta } from 'common/common.static'
import { IShipmentDetailsInitialValueSlice } from './shipment-details.interface'

export const tabDetailHeaders: ITabItem[] = [
    {
        label: 'Details',
        labelTranslationCode: 'tab_details.details',
        key: 'status',
        value: 'details',
        className: 'brand',
    },
    {
        label: 'Related Parties',
        key: 'status',
        value: 'relatedParties',
        className: 'brand',
    },
    {
        label: 'Pack Lines',
        labelTranslationCode: 'tab_details.pack_lines',
        key: 'status',
        value: 'packLines',
        className: 'brand',
    },
    // {
    //     label: 'Orders',
    //     labelTranslationCode: 'tab_details.order',
    //     key: 'status',
    //     value: 'orders',
    //     className: 'brand',
    // },
    {
        label: 'Tracking',
        labelTranslationCode: 'tab_details.tracking',
        key: 'status',
        value: 'tracking',
        className: 'brand',
    },
    // {
    //     label: 'eDocs',
    //     labelTranslationCode: 'tab_details.edocs',
    //     key: 'status',
    //     value: 'eDocs',
    //     className: 'brand',
    // },
    {
        label: 'Milestones',
        labelTranslationCode: 'tab_details.milestones',
        key: 'status',
        value: 'milestones',
        className: 'brand',
    },
    // {
    //     label: 'Events',
    //     labelTranslationCode: 'tab_details.events',
    //     key: 'status',
    //     value: 'events',
    //     className: 'brand',
    // },
]

export const shipmentDetailsInitialValueSlice: IShipmentDetailsInitialValueSlice =
    {
        details: {
            houseBill: '',
            shippersRef: '',
            orderRef: '',
            containerMode: '',
            transportMode: '',
            serviceLevelDescription: '',
            outerPacksUnitCode: '',
            outerPacksUnitDescription: '',
            totalWeight: 0,
            totalWeightUnit: '',
            totalVolume: 0,
            totalVolumeUnit: '',
            origin: {
                portName: '',
                etdShipment: '',
                estimatedPickup: '',
                picRequiredBy: '',
                pickupTrnAdvised: '',
                actualPickup: '',
            },
            destination: {
                portName: '',
                etaShipment: '',
                estimatedDelivery: '',
                dlvRequiredBy: '',
                deliveryPortTransportAdvised: '',
                deliveryDate: '',
            },
            charges: {
                incoTerm: '',
                goodsValue: 0,
                goodsValueCurrencyCode: '',
                goodsValueCurrencyDescription: '',
                additionalTerms: '',
                insuranceValue: 0,
                insuranceValueCurrencyCode: '',
                insuranceValueCurrencyDescription: '',
                chargesApply: '',
            },
            notes: {
                specialInstructions: '',
                detailedGoodsDesc: '',
                marksAndNumbers: '',
            },
            packs: 0,
        },
        relatedParties: {
            consignorPickupAddress: {},
            consigneePickupAddress: {},
            consignorDocumentaryAddress: {},
            consigneeDocumentaryAddress: {},
        },
        tracking: {
            data: [],
            meta: initialMeta,
            pageNumber: 0,
        },
        packLine: {
            data: [],
            meta: initialMeta,
            pageNumber: 0,
        },
        milestones: [],
    }
