var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTableExpandableRow } from './table-expandable-row.services';
import '../table-expandable.style.css';
import { Fragment, useEffect, useRef } from 'react';
import Button from '@components/button/button.component';
import RowOptionOverlay from '@components/table/component/row-option-overlay.component';
export function TableExpandableRow(_a) {
    var props = __rest(_a, []);
    var _b = useTableExpandableRow(props), data = _b.data, isExpand = _b.isExpand, childData = _b.childData, setIsExpand = _b.setIsExpand, printValue = _b.printValue;
    var iconExpand = isExpand
        ? 'ri-arrow-up-s-line '
        : 'ri-arrow-down-s-line ';
    var rowBgColor = !isExpand || props.highlightOnExpand === false
        ? 'themes-bg-white'
        : 'themes-bg-brand-v1';
    var additionalClass = !isExpand ? 'hide' : 'show';
    var contentRef = useRef(null);
    var rowClickCursor = props.onParentClick ? 'cursor-pointer' : '';
    useEffect(function () {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = !isExpand
                ? '0px'
                : "".concat(contentRef.current.scrollHeight, "px");
        }
    }, [isExpand, childData]);
    return (_jsxs(Fragment, { children: [_jsxs("tr", __assign({ className: "tr-expandable border-b  ".concat(rowBgColor, " ").concat(additionalClass) }, { children: [_jsx("td", __assign({ className: "th-expandable !w-[50px] cursor-pointer", onClick: function () {
                            setIsExpand(!isExpand);
                        } }, { children: _jsx("div", __assign({ className: "".concat(isExpand ? 'font-bold themes-text-brand-v5' : '', " w-[42px] flex items-center justify-center") }, { children: _jsx("i", { className: "".concat(iconExpand) }) }), "div-expandable-".concat(props.index)) }), "td-expand-".concat(props.index)), props.headerParent.map(function (col, idx) {
                        var accessor = col.accessor;
                        var accessorArray = accessor.split('.');
                        // skip icon button
                        if (accessor === 'expandable-button-icon') {
                            return _jsx(_Fragment, {});
                        }
                        // get the value
                        var value = null;
                        value = printValue(data, accessorArray, 0);
                        var width = col.width ? col.width + 'px' : '';
                        var minWidth = col.minWidth ? col.minWidth + 'px' : '';
                        var height = col.height ? col.height + 'px' : '';
                        var val = !value || value === '' ? (_jsx("div", __assign({ className: "text-center w-full" }, { children: "-" }))) : (value);
                        var ellipsis = col.ellipsis
                            ? ' text-ellipsis overflow-hidden'
                            : '';
                        var inline = col.customBuild
                            ? ''
                            : '!inline-block align-middle';
                        // return
                        return (_jsx("td", __assign({ className: "td-expandable ".concat(rowClickCursor), style: {
                                width: width,
                                minWidth: minWidth,
                            }, onClick: function () {
                                props.onParentClick && props.onParentClick(data);
                            } }, { children: _jsx("div", __assign({ style: {
                                    height: height,
                                    width: col.ellipsis ? width : '',
                                    minWidth: minWidth,
                                }, className: "".concat((col === null || col === void 0 ? void 0 : col.className) || '', "  \n                                    ").concat(inline, " \n                                    ").concat(ellipsis, "\n                                    ").concat(isExpand ? 'themes-text-brand-v5' : '', "\n                                ") }, { children: !col.customBuild
                                    ? val
                                    : col.customBuild(val, data) })) }), 'col-' + idx));
                    }), (props === null || props === void 0 ? void 0 : props.rowOptionComponent) && (_jsx("td", __assign({ className: "\n                            sticky right-0 flex justify-end themes-bg-white\n                            ".concat(isExpand ? 'themes-text-brand-v5 themes-bg-brand-v1' : '', "\n                        ") }, { children: _jsx(RowOptionOverlay, { data: data, rowOptionComponent: props.rowOptionComponent }) }), "rowOption-".concat(props.index)))] }), 'tr-' + props.index), !props.parentChildSameHeader ? (_jsx(RowContent, { headerParent: props.headerParent, headerChild: props.headerChild, contentRef: contentRef, childData: childData, addChildRowHandling: props.addChildRowHandling, index: props.index, addItemHandling: props.addItemHandling })) : (_jsx(RowContent2, { headerParent: props.headerParent, headerChild: props.headerChild, contentRef: contentRef, childData: childData, addChildRowHandling: props.addChildRowHandling, index: props.index, addItemHandling: props.addItemHandling }))] }, 'key-' + props.index));
}
export function RowContent(_a) {
    var headerParent = _a.headerParent, contentRef = _a.contentRef, headerChild = _a.headerChild, childData = _a.childData, addChildRowHandling = _a.addChildRowHandling, addItemHandling = _a.addItemHandling, index = _a.index;
    var rowHeaderColspan = function () {
        if (!addItemHandling) {
            return headerParent.length;
        }
        return headerParent.length + 1;
    };
    return (_jsx("tbody", { children: _jsx("tr", __assign({ className: "tr-child hide" }, { children: _jsx("td", __assign({ colSpan: rowHeaderColspan() }, { children: _jsx("div", __assign({ className: "extendable w-full", ref: contentRef }, { children: _jsx("div", __assign({ className: "m-2 extendable rounded border themes-border-color themes-bg-gray-v2" }, { children: _jsxs("table", __assign({ className: "table-child" }, { children: [_jsxs("thead", __assign({ className: "child-thead-expandable" }, { children: [_jsx("th", { className: "w-[42px]" }), headerChild.map(function (data, idx) {
                                            var showLabel = data.showLabel === undefined
                                                ? true
                                                : data.showLabel;
                                            var width = data.width
                                                ? data.width + 'px'
                                                : '';
                                            var minWidth = data.minWidth
                                                ? data.minWidth + 'px'
                                                : '';
                                            return (_jsx("th", __assign({ style: {
                                                    width: width,
                                                    minWidth: minWidth,
                                                }, className: "th-expandable" }, { children: _jsx("div", __assign({ className: "".concat((data === null || data === void 0 ? void 0 : data.headerClassName) || '') }, { children: !showLabel
                                                        ? null
                                                        : data.label }), data.accessor) }), 'header-' + idx));
                                        })] })), _jsxs("tbody", { children: [childData.length > 0 ? (_jsx(_Fragment, {})) : (_jsxs("tr", { children: [_jsx("td", __assign({ colSpan: headerChild.length - 1, className: "themes-text-gray-v6 pl-2" }, { children: "Please add Line Item(s)" })), _jsx("td", __assign({ className: "td-expandable text-right h-[40px]" }, { children: _jsx(Button, { onClick: function () {
                                                            addChildRowHandling &&
                                                                addChildRowHandling();
                                                        }, style: { height: '100%' }, iconClassName: "text-logistical-blue", className: "!p-0 !h-[30px] !w-[50px]", variant: "brand-inverse", icon: "ri-add-line ri-1x" }) }))] })), childData.map(function (data, rowIndex) {
                                            return (_jsxs("tr", { children: [_jsx("td", { className: "w-[42px]" }), headerChild.map(function (col, idx) {
                                                        var _a;
                                                        var accessor = col.accessor;
                                                        // get the value
                                                        var value = data[accessor];
                                                        if (accessor.includes('.')) {
                                                            var accessorArray = accessor.split('.');
                                                            for (var _i = 0, accessorArray_1 = accessorArray; _i < accessorArray_1.length; _i++) {
                                                                var key = accessorArray_1[_i];
                                                                value =
                                                                    (_a = data === null || data === void 0 ? void 0 : data[accessorArray[0]]) === null || _a === void 0 ? void 0 : _a[key];
                                                            }
                                                        }
                                                        var ellipsis = col.ellipsis
                                                            ? ' text-ellipsis overflow-hidden'
                                                            : '';
                                                        var width = col.width
                                                            ? col.width +
                                                                'px'
                                                            : '';
                                                        var height = col.height
                                                            ? col.height +
                                                                'px'
                                                            : '30px';
                                                        var minWidth = col.minWidth
                                                            ? col.minWidth +
                                                                'px'
                                                            : '';
                                                        // return
                                                        var val = !value ||
                                                            value === '' ? (_jsx("div", __assign({ className: "text-center w-full" }, { children: "-" }))) : (value);
                                                        var inline = col.customBuild
                                                            ? ''
                                                            : '!inline-block align-middle';
                                                        return (_jsx("td", __assign({ style: {
                                                                width: width,
                                                                height: height,
                                                                minWidth: minWidth,
                                                            }, className: "td-expandable" }, { children: _jsx("div", __assign({ style: {
                                                                    width: col.ellipsis
                                                                        ? width
                                                                        : '',
                                                                }, className: "".concat((col === null || col === void 0 ? void 0 : col.className) || '', " ").concat(inline, " ").concat(ellipsis) }, { children: !col.customBuild
                                                                    ? val
                                                                    : col.customBuild(val, data, index, rowIndex) })) }), 'col-' +
                                                            idx));
                                                    })] }, 'childData-' + rowIndex));
                                        })] })] })) })) })) })) })) }));
}
// If parent and child have a same header
export function RowContent2(_a) {
    var headerParent = _a.headerParent, contentRef = _a.contentRef, headerChild = _a.headerChild, childData = _a.childData, addChildRowHandling = _a.addChildRowHandling, addItemHandling = _a.addItemHandling, index = _a.index;
    var rowHeaderColspan = function () {
        if (!addItemHandling) {
            return headerParent.length;
        }
        return headerParent.length + 1;
    };
    return childData.map(function (data, rowIndex) {
        return (_jsx("tr", __assign({ className: "border-b" }, { children: _jsx("td", __assign({ colSpan: rowHeaderColspan() }, { children: _jsx("div", __assign({ ref: contentRef, className: "w-full extendable" }, { children: _jsx("table", __assign({ className: "table-child" }, { children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsx("td", { className: "w-[58px]" }), headerChild.map(function (col, idx) {
                                        var _a;
                                        var accessor = col.accessor;
                                        // get the value
                                        var value = data[accessor];
                                        if (accessor.includes('.')) {
                                            var accessorArray = accessor.split('.');
                                            for (var _i = 0, accessorArray_2 = accessorArray; _i < accessorArray_2.length; _i++) {
                                                var key = accessorArray_2[_i];
                                                value =
                                                    (_a = data === null || data === void 0 ? void 0 : data[accessorArray[0]]) === null || _a === void 0 ? void 0 : _a[key];
                                            }
                                        }
                                        var ellipsis = col.ellipsis
                                            ? ' text-ellipsis overflow-hidden'
                                            : '';
                                        var width = col.width
                                            ? col.width + 'px'
                                            : '';
                                        var height = col.height
                                            ? col.height + 'px'
                                            : '30px';
                                        var minWidth = col.minWidth
                                            ? col.minWidth + 'px'
                                            : '';
                                        // return
                                        var val = !value || value === '' ? (_jsx("div", __assign({ className: "text-center w-full" }, { children: "-" }))) : (value);
                                        var inline = col.customBuild
                                            ? ''
                                            : '!inline-block align-middle';
                                        return (_jsx("td", __assign({ style: {
                                                width: width,
                                                height: height,
                                                minWidth: minWidth,
                                            }, className: "td-expandable" }, { children: _jsx("div", __assign({ style: {
                                                    width: col.ellipsis
                                                        ? width
                                                        : '',
                                                }, className: "".concat((col === null || col === void 0 ? void 0 : col.className) || '', " ").concat(inline, " ").concat(ellipsis) }, { children: !col.customBuild
                                                    ? val
                                                    : col.customBuild(val, data, index, rowIndex) })) }), 'col-' + idx));
                                    })] }) }) })) })) })) }), "childData2-".concat(rowIndex)));
    });
}
