import { IResponseData } from 'common/common.interface'
import { fetch, rangeDate } from 'common/common.service'
import { ifbEndpoints } from '../endpoints/ifb.endpoints'
import { IFilterPerformance } from './interface/performance.interface'
import { IFilterCO } from '../form-validation/control-tower.validation'
import {
    IRSmallTable,
    IRelatedKeyFiguresResponse,
} from './interface/control-tower.interface'
import { initialResponseData } from 'common/common.static'
import { IResponseDropdownVolumeDashboard } from '../pages/volumes/volumes.interface'

const setUpdatedFilter = <T = {}>(filter: IFilterCO, customField?: T) => {
    const { StartDate, EndDate } = filter

    const loadRangeDate = rangeDate(StartDate, EndDate)

    const convertToParams = <T extends object>(data: T, prefix: string) => {
        const params: Record<string, T[keyof T]> = {}

        ;(Object.keys(data) as Array<keyof T>).forEach((key) => {
            params[`${prefix}.${key as string}`] = data[key]
        })

        return params
    }

    const { DataCategory, ShipmentEvent, ...props } = filter

    const updatedFilter = {
        ...props,
        ...convertToParams(filter.DataCategory, 'DataCategory'),
        ...convertToParams(filter.ShipmentEvent, 'ShipmentEvent'),
        StartDate: loadRangeDate.formattedStartMonth,
        EndDate: loadRangeDate.formattedEndMonth,
        Company: filter.Company === 'all' ? '' : filter.Company,
        TransportMode:
            filter.TransportMode === 'all' ? '' : filter.TransportMode,
        ContainerMode:
            filter.ContainerMode === 'all' ? '' : filter.ContainerMode,
        ...(customField && {
            ...customField,
        }),
    }

    return updatedFilter
}

export const fetchRelatedKeyFigures = async (filter: IFilterCO) => {
    const updatedFilter = setUpdatedFilter(filter)
    try {
        const response = await fetch<
            IResponseData<IRelatedKeyFiguresResponse>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.control_tower_RelatedKeyFigure,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const err = error as IResponseData<IRelatedKeyFiguresResponse>
        console.error(err)
        err.isSuccess = false
        return err
    }
}
export const fetchOverviewTable2 = async (
    filter: IFilterCO,
    tabFilter: string,
) => {
    const customFieldPayload = {
        countryCode: 'PL', // change country code from user login if already exists
    }
    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    try {
        const response = await fetch<
            IResponseData<IRSmallTable[]>,
            IFilterPerformance
        >({
            endpoint: ifbEndpoints.control_tower_Overview + tabFilter,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<IRSmallTable[]> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const fetchControlTowerHistory = async <T = {}, U = {}>({
    filter,
    field,
    endpoint,
}: {
    filter: IFilterCO
    field: U
    endpoint: string
}) => {
    const customFieldPayload = {
        countryCode: 'PL', // change country code from user login if already exists
        ...field,
    }

    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    try {
        const response = await fetch<IResponseData<T>, IFilterPerformance>({
            endpoint,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<T> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const fetchTransportModeOptions = async () => {
    try {
        const endpoint = `${ifbEndpoints.bookingsGetMetadata}`
        const response = await fetch<IResponseDropdownVolumeDashboard, string>({
            endpoint: endpoint.toString(),
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseDropdownVolumeDashboard = {
            transportMode: [],
            volumeMeasure: [],
            weightMeasure: [],
        }
        console.error(error)
        return errResponse
    }
}

export const fetchOverviewTable = async (
    filter: IFilterCO,
    endpoints: string,
) => {
    const customFieldPayload = {
        countryCode: 'PL', // change country code from user login if already exists
    }
    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    try {
        const response = await fetch<
            IResponseData<IRSmallTable[]>,
            IFilterPerformance
        >({
            endpoint: endpoints,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<IRSmallTable[]> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}

export const fetchControlTowerData = async <T, U = {}>(
    filter: IFilterCO,
    endpoint: string,
    customField?: U,
) => {
    const customFieldPayload = {
        ...(customField && {
            ...customField,
        }),
    }
    const updatedFilter = setUpdatedFilter(filter, customFieldPayload)
    try {
        const response = await fetch<IResponseData<T>, IFilterPerformance>({
            endpoint: endpoint,
            params: updatedFilter,
        })
        return response
    } catch (error: any) {
        const errResponse: IResponseData<T> = initialResponseData
        errResponse.message = error.message
        console.error(error)
        return errResponse
    }
}
