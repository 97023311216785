import {
    createUserInitialValue,
    createUserValidation,
    ICreateUserValidation,
} from 'client/ifb/form-validation/create-user.validation'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    createUser,
    getAccessModule,
    getCurrentUser,
    getManageUserID,
    getUserDefaultData,
    updateUser,
} from 'client/ifb/repository/manage-user.repository'
import { useSelector } from 'react-redux'
import {
    chooseModuleSelector,
    currentUserSelector,
    filterModuleTemplateSelector,
    manageUserDetailSelector,
    moduleTemplatesSelector,
    setChooseModule,
    setCurrentUser,
    setFilterModule,
    setManageUserDataDetail,
    setModuleTemplate,
    userDefaultSelector,
} from '../manage-users.slice'
import {
    ICreateUser,
    IFilterModuleTemplates,
    IModuleTemplate,
    IUserDefaultData,
} from '../manage-users.interface'
import { Toast } from '@components/toast/toast.component'
import {
    IUpdateUserValidation,
    updateUserInitialValue,
    updateUserValidation,
} from 'client/ifb/form-validation/update-user.validation'
import { tabFilterFormUser } from './manage-user-form.static'
import { ITabItem } from '@components/tab/tab.interface'
import {
    ILoadingModule,
    IPayloadCreateUser,
    IUseManageUserForm,
} from './manage-user-form.interface'
import { getCountryDropdown } from 'client/common/repository/country.repository'
import { userDataSelector } from 'client/common/pages/login/login.slice'
import { useAppDispatch } from 'store'
import { AxiosError } from 'axios'
import { IResponseData } from 'common/common.interface'
import { ICreateUserErrorResponse } from 'client/ifb/repository/interface/manage-user.repository'
import useManageUserDetails from '../manage-user-details/manage-user-details.service'

const useManageUserForm = (
    isNew: boolean,
    isProfilePage: boolean,
): IUseManageUserForm => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { id } = useParams()

    // selectors
    const currentUser = useSelector(currentUserSelector)
    const manageUserDetail = useSelector(manageUserDetailSelector)
    const loginUserData = useSelector(userDataSelector)
    const userDefault = useSelector(userDefaultSelector)

    // selectors configurations
    const filterModuleTemplate = useSelector(filterModuleTemplateSelector)
    const moduleTemplates = useSelector(moduleTemplatesSelector)
    const chooseModule = useSelector(chooseModuleSelector)

    // states
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingModule, setLoadingModule] = useState<ILoadingModule>({
        modulePages: false,
        moduleFeaturesAccess: false,
    })
    const [tabFilter, setTabFilter] = useState<ITabItem>(tabFilterFormUser[0])

    // state details
    const [countryList] = useState(getCountryDropdown())

    const generateCountryFormat = (countryFormValue: string) => {
        const selectedCountry = countryList.find(list => list.value === countryFormValue)?.label as string
        const countryListParts = selectedCountry?.split(" - ");
        const countryFormat = {
            code: countryListParts?.[0],
            name: countryListParts?.[1],
        }

        return countryFormat
    }

    // formik
    const formikCreate = useFormik<ICreateUserValidation>({
        validationSchema: createUserValidation,
        initialValues: createUserInitialValue,
        onSubmit: async (values) => {
            // set field
            const setDataModule = moduleTemplates.map((item) => {
                const initialAccess = {
                    canAccessModule: false,
                    canViewDetails: false,
                    canCreate: false,
                    canUpdate: false,
                    canDelete: false,
                }
                // Mengubah `loadActionList` menjadi objek akses
                const moduleAccess = item.loadActionList.reduce(
                    (access, action) => {
                        switch (action.value) {
                            case 'accessModule':
                                access.canAccessModule = action.isActive
                                break
                            case 'viewDetails':
                                access.canViewDetails = action.isActive
                                break
                            case 'create':
                                access.canCreate = action.isActive
                                break
                            case 'update':
                                access.canUpdate = action.isActive
                                break
                            case 'delete':
                                access.canDelete = action.isActive
                                break
                            default:
                                break
                        }
                        return access
                    },
                    { ...initialAccess },
                )
                return {
                    moduleTemplateId: item.id,
                    access: moduleAccess,
                }
            })

            values.moduleList = setDataModule

            // need adjust payload with new design and api
            const payloadCreateuser: IPayloadCreateUser = {
                fullName: values.fullName,
                userName: values.userName,
                email: values.email,
                jobTitle: values.jobTitle,
                jobCategory: values.jobCategory,
                organizationName: userDefault.data?.branch as string,
                country: generateCountryFormat(values.country as string),
                province: values.province,
                city: values.city,
                addressDetails: values.addressDetails,
                address2: values.address2,
                mobilePhoneNumber: values?.mobilePhoneNumber?.toString(),
                homePhoneNumber: values?.homePhoneNumber?.toString(),
                contactName: values.contactName,
                phoneNumber: values?.phoneNumber?.toString(),
                password: values.password,
                moduleList: values.moduleList,
                postCode: values.postCode,
            }
            try {
                const response = await createUser(payloadCreateuser)
                if (response.isSuccess) {
                    navigate('/manage-users')
                    const header = isNew
                        ? 'New User Succesfully Created!'
                        : 'User Details Successfully Edited!'
                    Toast({
                        header: header,
                        type: 'success',
                    })
                } else {
                    const errRes = response as ICreateUserErrorResponse
                    Toast({
                        header: 'Failed',
                        message: errRes.response?.data?.message || response?.message,
                        type: 'error',
                    })
                }
            } catch (error) {
                console.error(error)
                Toast({
                    header: 'Failed',
                    message: 'Failed to create user',
                    type: 'error',
                })
            }
        },
    })
    const formikUpdate = useFormik<IUpdateUserValidation>({
        validationSchema: updateUserValidation,
        initialValues: updateUserInitialValue(
            isProfilePage ? currentUser : manageUserDetail,
        ),
        onSubmit: async (values) => {
            try {
                const response = await updateUser({
                    ...values,
                    country: generateCountryFormat(values.country as string)
                })
                if (response.isSuccess) {
                    navigate('/manage-users')
                    const message = isNew
                        ? 'New User Successfully Created!'
                        : 'User Details Successfully Edited!'
                    Toast({
                        header: message,
                        message: message,
                        type: 'success',
                    })
                } else {
                    Toast({
                        header: 'Failed',
                        message: response?.message,
                        type: 'error',
                    })
                }
            } catch (error) {
                console.error(error)
                Toast({
                    header: 'Failed',
                    message: 'Failed to create user',
                    type: 'error',
                })
            }
        },
    })

    // function global
    const submitForm = () => {
        const { fullName } = isNew ? formikCreate.values : formikUpdate.values;
        if (isNew) {
            formikCreate.setFieldValue("contactName", fullName)
            formikCreate.submitForm()
        } else {
            formikUpdate.setFieldValue("contactName", fullName)
            formikUpdate.submitForm()
        }
        const err = isNew ? formikCreate.errors : formikUpdate.errors
        if (Object.keys(err).length > 0) {
            Toast({
                header: 'Unable to Proceed!',
                message:
                    'There is some mandatory fields required to not leave blank',
                type: 'error',
            })
        }
    }

    const loadUserDetailsData = async () => {
        try {
            setLoading(true)
            const actionResultDetail = await getManageUserID({ id })
            dispatch(setManageUserDataDetail({
                ...actionResultDetail.data,
                id: id as string
            }))
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e)
        }
    }

    const loadCurrentUser = async () => {
        try {
            setLoading(true)
            const getUser = await getCurrentUser()
            if (getUser.isSuccess) {
                dispatch(setCurrentUser(getUser))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    // function configurations
    const loadModule = async () => {
        const setFilter = {
            industry:
                filterModuleTemplate.industry === 'all'
                    ? ''
                    : filterModuleTemplate.industry,
            portalAcess: filterModuleTemplate.portalAcess,
        }
        try {
            setLoadingModule({
                modulePages: true,
                moduleFeaturesAccess: true,
            })
            const getModuleTemplates = await getAccessModule(setFilter)
            dispatch(setModuleTemplate(getModuleTemplates))
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingModule({
                modulePages: false,
                moduleFeaturesAccess: false,
            })
        }
    }
    const setFilterLoadModule = (filter: IFilterModuleTemplates) => {
        const setFilter = {
            industry: filter.industry || filterModuleTemplate.industry,
            portalAcess: filter.portalAcess || filterModuleTemplate.portalAcess,
        }
        dispatch(setFilterModule(setFilter))
    }
    const onClickModule = (item: IModuleTemplate) => {
        setLoadingModule((prevState) => ({
            ...prevState,
            moduleFeaturesAccess: true,
        }))
        dispatch(setChooseModule(item))
        setTimeout(
            () =>
                setLoadingModule((prevState) => ({
                    ...prevState,
                    moduleFeaturesAccess: false,
                })),
            500,
        )
    }
    const handleToggle = (value: string) => {
        if (!chooseModule) return
        const updatedActionList = chooseModule?.loadActionList?.map((item) =>
            item.value === value ? { ...item, isActive: !item.isActive } : item,
        )
        const data = {
            ...chooseModule,
            loadActionList: updatedActionList,
        }
        dispatch(setChooseModule(data))
    }

    // useEffects
    useEffect(() => {
        if (isNew || isProfilePage) loadCurrentUser()
    }, [isNew, isProfilePage])
    // useEffect configurations
    useEffect(() => {
        if (!currentUser.id) return
        loadModule()
    }, [
        currentUser,
        filterModuleTemplate.industry,
        filterModuleTemplate.portalAcess,
    ])

    useEffect(() => {
        if (isNew) {
            formikCreate.setFieldValue("city", userDefault.data?.city);
            formikCreate.setFieldValue("state", userDefault.data?.state);
            formikCreate.setFieldValue("country", userDefault.data?.country.code);
            formikUpdate.setFieldValue("currencyDecimalPoint", '0.00');
            formikCreate.setFieldValue("defaultCurrency", `${userDefault.data?.currency.code}, ${userDefault.data?.currency.description}`);
        } else {
            formikUpdate.setFieldValue("currencyDecimalPoint", '0.00')
            formikUpdate.setFieldValue("defaultCurrency", `${userDefault.data?.currency.code}, ${userDefault.data?.currency.description}`);
        }
    }, [isNew])

    useEffect(() => {
        if (!isNew && !manageUserDetail.id) {
            loadUserDetailsData()
        }
    }, [isNew, manageUserDetail])

    useEffect(() => {
        if (!isNew && !formikUpdate.values.id && manageUserDetail.id) {
            formikUpdate.setValues(updateUserInitialValue(
                isProfilePage ? currentUser : manageUserDetail,
            ))
            formikUpdate.setFieldValue("currencyDecimalPoint", '0.00')
            formikUpdate.setFieldValue("defaultCurrency", `${userDefault.data?.currency.code}, ${userDefault.data?.currency.description}`);
        }

    }, [isNew, formikUpdate, manageUserDetail])

    return {
        tabFilter,
        setTabFilter,
        formikCreate: formikCreate,
        navigate,
        loading,
        submitForm,
        formikUpdate,

        // details
        countryList,

        // configurations
        setFilterLoadModule,
        chooseModule,
        onClickModule,
        handleToggle,
        loadingModule,
        moduleTemplates,

        userDefault,
    }
}

export default useManageUserForm
