import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, combineReducers, Store } from 'redux'
import { persistReducer } from 'redux-persist'
import { persistStore } from 'redux-persist'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import commonReducer from 'common/common.slice'
import configReducer from '../config/config.slice'
import loginReducer, {
    initialState as initialStateLogin,
} from '../client/common/pages/login/login.slice'
import userReducer, {
    initialState as initialStateUser,
} from '../client/common/pages/user-acces/user-access.slice'

// jpl
import poReducer, {
    initialState as initialStatePo,
} from '../client/jpl/pages/purchase-order/purchase-order.slice'
import shipmentsReducer, {
    initialState as initialStateShipment,
} from '../client/jpl/pages/shipments/shipments.slice'
import attachPoReducer, {
    initialState as initialStateAttachPo,
} from '../client/jpl/pages/shipments/attach-po/attach-po.slice'

// ifb
import invoicesReducerIFB, {
    initialState as initialStateInvoiceIFB,
} from 'client/ifb/pages/invoices/invoices.slice'
import dashboardReducerIFB, {
    initialState as initialStateDashboardIFB,
} from 'client/ifb/pages/dashboard/dashboard.slice'
import shipmentsReducerIFB, {
    initialState as initialStateShipmentIFB,
} from 'client/ifb/pages/shipments/shipments.slice'
import manageUserReducerIFB, {
    initialStateManageUserDefault as initialStateManageUserDefaultIFB,
    initialState as initialStateManageUserIFB,
    manageUserDefaultReducer as manageUserDefaultReducerIFB,
} from 'client/ifb/pages/manage-users/manage-users.slice'
import trackingReducerIFB, {
    initialState as initialStateTrackingsIFB,
} from 'client/ifb/pages/trackings/trackings.slice'
import bookingsReducerIFB, {
    initialState as initialStateBookingsIFB,
} from 'client/ifb/pages/bookings/bookings.slice'
import volumeReducerIFB, {
    initialState as initialVolumeIFB,
} from 'client/ifb/pages/volumes/volumes.slice'
import performancesReducerIFB, {
    initialState as initialPerformancesIFB,
} from 'client/ifb/pages/performances/performances.slice'
import controlTowerReducerIFB, {
    initialState as initialControlTowerIFB,
} from 'client/ifb/pages/control-tower/control-tower.slice'

const appReducer = combineReducers({
    // common
    common: commonReducer,
    login: loginReducer,
    user: userReducer,
    config: configReducer,

    // jpl stores
    purchaseOrder: poReducer,
    shipment: shipmentsReducer,
    attachPo: attachPoReducer,

    // ifb stores
    invoiceIFB: invoicesReducerIFB,
    dashboardIFB: dashboardReducerIFB,
    shipmentIFB: shipmentsReducerIFB,
    trackingIFB: trackingReducerIFB,
    manageUserIFB: manageUserReducerIFB,
    manageUserDefaultIFB: manageUserDefaultReducerIFB,
    bookingsIFB: bookingsReducerIFB,
    volumeIFB: volumeReducerIFB,
    performancesIFB: performancesReducerIFB,
    controlTowerIFB: controlTowerReducerIFB,
})

export const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction,
): ReturnType<typeof appReducer> => {
    if (action.type === 'RESET_STORE') {
        if (state) {
            state = {
                ...state,
                login: initialStateLogin,
                user: initialStateUser,
                // JPL
                purchaseOrder: initialStatePo,
                shipment: initialStateShipment,
                attachPo: initialStateAttachPo,
                // IFB
                invoiceIFB: initialStateInvoiceIFB,
                dashboardIFB: initialStateDashboardIFB,
                shipmentIFB: initialStateShipmentIFB,
                trackingIFB: initialStateTrackingsIFB,
                manageUserIFB: initialStateManageUserIFB,
                manageUserDefaultIFB: initialStateManageUserDefaultIFB,
                bookingsIFB: initialStateBookingsIFB,
                volumeIFB: initialVolumeIFB,
                performancesIFB: initialPerformancesIFB,
                controlTowerIFB: initialControlTowerIFB,
            }
        }
    }
    return appReducer(state, action)
}
const key = 'logisticaldatastore'

const persistConfig = {
    key,
    storage,
    whitelist: ['config', 'login', 'common', 'manageUserDefaultIFB'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devTools = process.env.NODE_ENV !== 'production'
export const store = configureStore({
    reducer: persistedReducer,
    devTools,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: true,
            },
        }).concat(thunk),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type AppStore = Omit<Store<RootState, AnyAction>, 'dispatch'> & {
    dispatch: AppThunkDispatch
}

export type AppDispatch = ThunkDispatch<string, any, AnyAction>

// Create the typed versions of the useDispatch and useSelector Hooks
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// reset store
export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })
